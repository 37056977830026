<template>
  <v-app>
    <v-container fluid>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Filtrar/Buscar categorias"
          single-line
          hide-details
        />
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on"
              >Agregar Lugares a {{ $route.params.title }}</v-btn
            >
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Servicio</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form
                  ref="form"
                  v-model="valid"
                  id="check-login-form"
                  lazy-validation
                >
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-select
                        :items="empresas"
                        @change="getLugares"
                        name="empresa"
                        :rules="empresaRules"
                        append-icon="search"
                        v-model="empresa"
                        item-text="nombre"
                        item-value="id"
                        label="Empresa"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-select
                        :items="tipoServicios"
                        @change="getLugares"
                        :rules="tipoServicioRules"
                        name="tipoServicio"
                        append-icon="search"
                        v-model="tipoServicio"
                        item-text="nombre"
                        item-value="id"
                        label="Tipo Servicio"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-autocomplete
                        :items="lugaresBusqueda"
                        name="lugar"
                        :rules="servicioRules"
                        append-icon="search"
                        v-model="editedItem.lugar"
                        item-text="nombre"
                        item-value="id"
                        label="Lugar"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <div class="flex-grow-1"></div>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="save">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-spacer />
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        :options.sync="options"
        :sort-by="'name'"
        :loading="loading"
        :footer-props="{
          itemsPerPageOptions: rows_per_page_items, // -> Add this example
          itemsPerPageText: rows_per_page_text,
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'keyboard_arrow_left',
          nextIcon: 'keyboard_arrow_right',
        }"
        light
        item-key="id"
      >
        <v-progress-linear slot="progress" color="blue" indeterminate />
        <template v-slot:item.action="{ item }">
          <v-icon small @click="deleteItem(item)"> delete </v-icon>
        </template>
      </v-data-table>
    </v-container>
  </v-app>
</template>
<script>
export default {
  data: () => ({
    urlPrefix: '',//"https://dev.checkinseguridad.com",
    competencia: null,
    valid: false,
    agrega: null,
    dialog: false,
    servicio: true,
    editedItem: [],
    empresas: [],
    lugar: null,
    servicios: [],
    servicio_id: null,
    tipoServicios: [],
    tipoServicio: null,
    lugaresBusqueda: [],
    usuarios: [],
    empresa: null,
    headers: [
      { text: "Servicio", value: "lugar.nombre", align: "center" },
      { text: "Empresa", value: "lugar.empresa.nombre", align: "center" },
      { text: "Acciones", value: "action", align: "center", sortable: false },
    ],
    empresaRules: [(v) => !!v || "Empresa requerida"],
    tipoServicioRules: [(v) => !!v || "Tipo de Servicio requerido"],
    servicioRules: [(v) => !!v || "Servicio requerido"],
    filters: [{ text: "Filtrar por nombre", value: "nombre" }],
    filterBy: {
      text: "Filtrar por nombre",
      value: "nombre",
    },
    orderBy: "ASC",
    orderColor: "teal",
    items: [],
    total_items: 0,
    options: {
      descending: false,
      rowsPerPage: 50,
      page: 1,
    },
    search: "",
    rows_per_page_items: [5, 10, 25, 50],
    rows_per_page_text: "Filas por pagina",
    meta: {
      current_page: 1,
      last_page: 1,
      per_page: 50,
    },
    page: 1,
    loading: false,
  }),
  watch: {
    page: {
      handler() {
        this.fetchUsers();
      },
    },
  },
  computed: {
    sortIcon() {
      if (this.orderBy === "ASC") {
        return "keyboard_arrow_up";
      }
      return "keyboard_arrow_down";
    },
  },
  mounted() {
    let self = this;
    self.fetchServiciosPorCliente();
    self.fetchEmpresas();
    self.fetchTipoServicios();
  },
  methods: {
    fetchTipoServicios() {
      let self = this;
      self.loading = true;
      let url = `${this.urlPrefix}/api/tipoServicio/all`;
      if (self.page > 1) {
        url = `${url}&page=${self.page}`;
      }
      this.$http
        .get(url)
        .then((response) => {
          this.tipoServicios = response.data.data;

          self.meta = {
            current_page: response.data.current_page,
            last_page: response.data.last_page,
            per_page: response.data.per_page,
          };
          (self.total_items = response.data.total), (self.loading = false);
        })
        .catch((errors) => {
          console.log(errors);
          self.loading = false;
        });
    },

    getLugares: function name() {
      let self = this;
      self.loading = true;
      let url = `${this.urlPrefix}/api/lugares/byEmpresa/${this.empresa}/${this.tipoServicio}`;
      if (self.page > 1) {
        url = `${url}&page=${self.page}`;
      }
      this.$http
        .get(url)
        .then((response) => {
          self.lugaresBusqueda = response.data.data;

          self.meta = {
            current_page: response.data.current_page,
            last_page: response.data.last_page,
            per_page: response.data.per_page,
          };
          (self.total_items = response.data.total), (self.loading = false);
        })
        .catch((errors) => {
          console.log(errors);
          self.loading = false;
        });
    },

    fetchEmpresas() {
      let self = this;
      self.loading = true;
      self.items = [];
      let url = `${this.urlPrefix}/api/empresas/all`;
      if (self.page > 1) {
        url = `${url}&page=${self.page}`;
      }
      this.$axios
        .get(url)
        .then((response) => {
          self.empresas = response.data.data;
          self.empresasAgregar = response.data.data;

          self.meta = {
            current_page: response.data.current_page,
            last_page: response.data.last_page,
            per_page: response.data.per_page,
          };
          self.total_items = response.data.total;
          self.loading = false;
        })
        .catch((errors) => {
          console.log(errors);
          self.loading = false;
        });
    },

    fetchUsers() {
      let url = `${this.urlPrefix}/api/users/all`;
      this.$http
        .get(url)
        .then((response) => {
          this.usuarios = response.data;
          this.dialog = false;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    fetchCategoriasPorservicios(competencia) {
      let url = `${this.urlPrefix}/api/categoriasPorCompetencia/${competencia}`;
      this.$http
        .get(url)
        .then((response) => {
          this.categorias = response.data;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    checkIfExists(servicio) {
      let band = true;
      this.items.forEach((element) => {
        if (element.lugar_id == servicio) {
          this.agrega = false;
          band = false;
        }
      });
      if (band) {
        this.agrega = true;
      }
    },

    save() {
      if (!this.$refs.form.validate()) {
        return;
      }
      let self = this;
      self.loading = true;
      this.checkIfExists(this.editedItem.lugar_id);
      console.log("agrega " + this.agrega);
      if (this.agrega == true) {
        this.agregarServicio();
      } else {
        let url = `${this.urlPrefix}/api/clienteUsuario/${this.editedItem.cliente_id}/${this.servicio_id}`;
        this.$http
          .put(url, { user_id: this.editedItem.user_id })
          .then(() => {
            this.fetchServiciosPorCliente();
            this.dialog = false;
          })
          .catch((errors) => {
            alert(errors.response.data.message);
            console.log(errors);
          });
      }
      this.editedItem = [];
      this.categorias = [];
      this.servicio = true;
      this.agrega = null;
      self.loading = false;
    },

    editItem(item) {
      this.servicio = false;
      this.editedItem = Object.assign({}, item);
      this.servicio_id = item.servicio_id;
      this.dialog = true;
    },

    deleteItem(item) {
      let band = confirm(`Deseas borrar el servicio`);
      if (!band) {
        return false;
      }
      let self = this;
      self.loading = true;
      let url = `${this.urlPrefix}/api/chatHasLugar/${item.lugar_id}/${this.$route.params.id}`;
      this.$http
        .delete(url, {
          persona_id: this.$route.params.id,
          categoria_id: item.categoria,
        })
        .then(() => {
          this.fetchServiciosPorCliente();
        })
        .catch((errors) => {
          console.log(errors);
        });
      self.loading = false;
    },

    agregarServicio() {
      let url = `${this.urlPrefix}/api/chatHasLugar`;
      let self = this;
      self.loading = true;
      this.$http
        .post(url, {
          chat_id: this.$route.params.id,
          lugar_id: this.editedItem.lugar,
        })
        .then(() => {
          this.fetchServiciosPorCliente();
          this.dialog = false;
        })
        .catch((errors) => {
          alert(errors.response.data.message);
          console.log(errors);
        });
      self.loading = false;
    },

    toggleOrderBy() {
      if (this.orderBy === "ASC") {
        this.orderBy = "DESC";
        this.orderColor = "orange";
      } else {
        this.orderBy = "ASC";
        this.orderColor = "teal";
      }
    },

    close() {
      this.editedItem = [];
      this.servicio = true;
      this.agrega = null;
      this.dialog = false;
    },

    fetchServiciosPorCliente() {
      let self = this;
      self.loading = true;
      self.items = [];
      let url = `${this.urlPrefix}/api/chatHasLugar/${this.$route.params.id}`;
      if (self.page > 1) {
        url = `${url}&page=${self.page}`;
      }
      this.$http
        .get(url)
        .then((response) => {
          self.items = response.data.data;

          self.meta = {
            current_page: response.data.current_page,
            last_page: response.data.last_page,
            per_page: response.data.per_page,
          };
          self.total_items = response.data.total;
          self.loading = false;
        })
        .catch((errors) => {
          console.log(errors);
          self.loading = false;
        });
    },

    toProperCase(key) {
      let newStr = key.replace(/_/g, " ");
      return newStr.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },

    changeSort(column) {
      if (this.options.sortBy === column) {
        this.options.descending = !this.options.descending;
      } else {
        this.options.sortBy = column;
        this.options.descending = false;
      }
    },
  },
};
</script>