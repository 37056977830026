<template>
  <v-app>
    <v-container fluid>
      <v-dialog v-model="dialog" max-width="500px" persistent="">
        <v-card justify="center">
          <v-card-title>Escriba su ID</v-card-title>
          <v-card-text>
            <v-form>
              <v-text-field
                v-model="empleado_id"
                label="empleado ID"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn color="blue darken-1" text to="/">Cancelar</v-btn>
            <v-btn color="blue darken-1" text @click="enviarID()">Enviar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-row justify="center" v-if="dialog == false">
        <v-card>
          <v-card-title>
            <v-col cols="12" sm="6" md="4">
              <v-dialog
                ref="dialog3"
                v-model="modal3"
                :return-value.sync="fechaInicio"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="fechaInicio"
                    label="Fecha de inicio de búsqueda"
                    prepend-icon="event"
                    name="fecha_inicio"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaInicio"
                  :value="fechaInicio"
                  scrollable
                >
                  <div class="flex-grow-1"></div>
                  <v-btn text color="primary" @click="cerrarFechaInicio()"
                    >Cancel</v-btn
                  >
                  <v-btn text color="primary" @click="saveFechaInicio"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-dialog
                ref="dialog4"
                v-model="modal4"
                :return-value.sync="fechaFin"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="fechaFin"
                    label="Fecha de fin de búsqueda"
                    prepend-icon="event"
                    name="fecha_fin"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="fechaFin" :value="fechaFin" scrollable>
                  <div class="flex-grow-1"></div>
                  <v-btn text color="primary" @click="cerrarFechaFin()"
                    >Cancel</v-btn
                  >
                  <v-btn text color="primary" @click="saveFechaFin">OK</v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-autocomplete
                :items="lugaresBusqueda"
                name="lugar"
                append-icon="search"
                @change="fetchCoberturas()"
                v-model="lugar"
                item-text="nombre"
                item-value="id"
                label="Lugar"
              ></v-autocomplete>
            </v-col>
            <v-spacer />
            <v-btn color="teal" dark @click="exportar"
              >Exportar
              <v-icon right>import_export</v-icon>
            </v-btn>
            <v-spacer />
            <v-btn color="blue" dark @click="reset"
              >Limpiar Filtros
              <v-icon right>filter_list</v-icon>
            </v-btn>
            <v-spacer />
            <v-dialog v-model="dialog2" max-width="500px">
              <template v-slot:activator="{ on }">
                <v-btn color="primary" dark class="mb-2" v-on="on"
                  >Agregar Cobertura</v-btn
                >
              </template>
              <v-card>
                <v-card-title> Agregar Cobertura </v-card-title>
                <v-card-text>
                  <v-form
                    ref="form"
                    v-model="valid"
                    id="check-login-form"
                    lazy-validation
                  >
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-autocomplete
                          :items="lugaresBusqueda"
                          name="lugar"
                          append-icon="search"
                          v-model="editedItem.lugar_id"
                          :rules="lugar_idRules"
                          item-text="nombre"
                          item-value="id"
                          label="Lugar"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-autocomplete
                          :items="[
                            'Matutino',
                            'Vespertino',
                            'Nocturno',
                            '12x12',
                            '24x24',
                          ]"
                          name="lugar"
                          :rules="turnoRules"
                          v-model="editedItem.turno"
                          label="Turno"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          :rules="notaRules"
                          v-model="editedItem.nota"
                          label="Nota"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-select
                          :rules="estado_fuerzaRules"
                          v-model="editedItem.estado_fuerza"
                          :items="['COMPLETO', 'INCOMPLETO']"
                          label="Estado de Fuerz"
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          type="number"
                          :rules="presentesRules"
                          min="0"
                          v-model="editedItem.presentes"
                          label="Presentes"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          type="number"
                          :rules="faltasRules"
                          min="0"
                          v-model="editedItem.faltas"
                          label="Faltas"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        v-if="editedItem.faltas > 0 && bandEdit == false"
                      >
                        <v-text-field
                          :rules="nombresFaltantesRules"
                          v-model="editedItem.nombresFaltantes"
                          label="Nombres de Faltantes"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          type="number"
                          :rules="incapacidadRules"
                          min="0"
                          v-model="editedItem.incapacidad"
                          label="Incapacidad"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          type="number"
                          min="0"
                          :value="editedItem.descanso"
                          :rules="descansosRules"
                          v-model="editedItem.descanso"
                          label="Descansos"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          type="number"
                          min="0"
                          :rules="permisosRules"
                          v-model="editedItem.permiso"
                          label="Permisos"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        v-if="editedItem.incapacidad > 0 && bandEdit == false"
                      >
                        <v-text-field
                          :rules="nombresIncapacidadRules"
                          v-model="editedItem.nombresIncapacidad"
                          label="Nombres con Incapacidad"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        v-if="editedItem.descansos > 0 && bandEdit == false"
                      >
                        <v-text-field
                          :rules="nombresDescansosRules"
                          v-model="editedItem.nombresDescansos"
                          label="Nombres Descansos"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        v-if="editedItem.permisos > 0 && bandEdit == false"
                      >
                        <v-text-field
                          :rules="nombresPermisosRules"
                          v-model="editedItem.nombresPermisos"
                          label="Nombres Permisos"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-textarea
                          :rules="inmuebleRules"
                          v-model="editedItem.inmueble"
                          label="Inmueble"
                        >
                        </v-textarea>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <div class="flex-grow-1"></div>
                  <v-btn color="blue darken-1" text @click="close()"
                    >Cancelar</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="save()"
                    >Enviar</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="items"
              :search="lugar"
              :options.sync="options"
              :sort-by="'name'"
              :loading="loading"
              :footer-props="{
                itemsPerPageOptions: [10, 15, 50, 100], // -> Add this example
                itemsPerPageText: 'Items por página:',
                showFirstLastPage: true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'keyboard_arrow_left',
                nextIcon: 'keyboard_arrow_right',
              }"
              light
              item-key="id"
            >
              <template v-slot:item.action="{ item }">
                <v-icon small class="mr-2" @click="editItem(item)">
                  edit
                </v-icon>
                <v-icon small @click="deleteItem(item)"> delete </v-icon>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-row>
    </v-container>
  </v-app>
</template>
<script>
export default {
  data: () => ({
    urlPrefix: '',//"https://dev.checkinseguridad.com",
    headers: [
      { text: "Servicio", value: "lugar.nombre", align: "center" },
      { text: "Turno", value: "turno", align: "center" },
      { text: "Nota", value: "nota", align: "center" },
      { text: "Fecha", value: "fecha", align: "center" },
      { text: "Estado de Fuerza", value: "estado_fuerza", align: "center" },
      { text: "Presentes", value: "presentes", align: "center" },
      { text: "Faltas", value: "faltas", align: "center" },
      { text: "Incapacidad", value: "incapacidad", align: "center" },
      { text: "Descansos", value: "descanso", align: "center" },
      { text: "Permisos", value: "permiso", align: "center" },
      { text: "Inmueble", value: "inmueble", align: "center" },
      { text: "Supervisor", value: "nombreCompleto", align: "center" },
      { text: "Acciones", value: "action", align: "center", sortable: false },
    ],
    options: {
      descending: false,
      rowsPerPage: 50,
      page: 1,
    },
    lugar_idRules: [(v) => !!v || "Lugar Requerido"],
    turnoRules: [(v) => !!v || "Turno Requerido"],
    notaRules: [(v) => !!v || "Nota Requerida"],
    estado_fuerzaRules: [(v) => !!v || "Estado de Fuerza Requerido"],
    presentesRules: [(v) => !!v || "Presentes Requerido"],
    faltasRules: [(v) => !!v || "Faltas Requerido"],
    incapacidadRules: [(v) => !!v || "Incapacidad Requerido"],
    descansosRules: [(v) => !!v || "Descansos Requerido"],
    permisosRules: [(v) => !!v || "Permisos Requerido"],
    inmuebleRules: [(v) => !!v || "Inmueble Requerido"],
    nombresFaltantesRules: [(v) => !!v || "Nombres Requerido"],
    nombresIncapacidadRules: [(v) => !!v || "Nombres Requerido"],
    nombresDescansosRules: [(v) => !!v || "Nombres Requerido"],
    nombresPermisosRules: [(v) => !!v || "Nombres Requerido"],
    valid: false,
    bandEdit: false,
    dialog: false,
    dialog2: false,
    loading: false,
    empleado_id: null,
    items: [],
    empresa_id: null,
    lugaresBusqueda: [],
    id: null,
    lugar: null,
    editedItem: [],
    fechaInicio: null,
    fechaFin: null,
    modal3: false,
    modal4: false,
  }),
  computed: {},
  mounted() {
    this.dialog = true;
    this.fetchLugares();
  },
  methods: {
    enviarID() {
      let url = `${this.urlPrefix}/api/empleados/puesto/${this.empleado_id}`;
      this.items = [];
      this.$http
        .get(url)
        .then((response) => {
          this.empresa_id = response.data.data.lugar.empresa_id;
          this.id = response.data.data.id;
          this.dialog = false;
          this.fetchLugares();
          this.fetchCoberturas();
        })
        .catch((errors) => {
          alert(errors.response.data.message);
        });
    },

    saveFechaInicio() {
      this.$refs.dialog3.save(this.fechaInicio);
      this.fetchCoberturas();
    },

    saveFechaFin() {
      this.$refs.dialog4.save(this.fechaFin);
      this.fetchCoberturas();
    },

    cerrarFechaInicio() {
      this.fechaInicio = null;
      this.$refs.dialog3.save(this.fechaInicio);
      this.modal3 = false;
    },

    cerrarFechaFin() {
      this.fechaFin = null;
      this.$refs.dialog4.save(this.fechaFin);
      this.modal4 = false;
    },

    fetchCoberturas() {
      let url = `${this.urlPrefix}/api/coberturas/byEmpleado/${this.id}/${this.fechaInicio}/${this.fechaFin}/${this.lugar}`;
      this.$http
        .get(url)
        .then((response) => {
          this.items = response.data.data;
          this.items.forEach((element) => {
            element.nombreCompleto =
              element.empleado.nombre +
              " " +
              element.empleado.apellido_paterno +
              " " +
              element.empleado.apellido_materno;
          });
        })
        .catch((errors) => {
          alert(errors.response.data.message);
        });
    },

    fetchLugares() {
      let url = `${this.urlPrefix}/api/lugares/byEmpresa/${this.empresa_id}/null`;
      this.$http
        .get(url)
        .then((response) => {
          this.lugaresBusqueda = response.data.data;
        })
        .catch((errors) => {
          console.log(errors);
          self.loading = false;
        });
    },

    reset() {
      this.fechaInicio = null;
      this.fechaFin = null;
      this.lugar = null;
    },

    close() {
      this.editedItem = [];
      this.dialog2 = false;
      this.bandEdit = false;
    },

    save() {
      if (!this.$refs.form.validate()) {
        return;
      }
      if (this.editedItem.id == undefined) {
        this.agregarCobertura();
      } else {
        this.editarCobertura();
      }
    },

    exportar() {},

    agregarCobertura() {
      let url = `${this.urlPrefix}/api/cobertura`;
      if (this.editedItem.faltas > 0) {
        this.editedItem.inmueble +=
          " Nombres Faltantes: " + this.editedItem.nombresFaltantes;
      }
      if (this.editedItem.incapacidad > 0) {
        this.editedItem.inmueble +=
          " Nombres Incapacidad: " + this.editedItem.nombresIncapacidad;
      }
      if (this.editedItem.descanso > 0) {
        this.editedItem.inmueble +=
          " Nombres Descanso: " + this.editedItem.nombresDescansos;
      }
      if (this.editedItem.permisos > 0) {
        this.editedItem.inmueble +=
          " Nombres Permiso: " + this.editedItem.nombresPermisos;
      }
      this.$http
        .post(url, {
          lugar_id: this.editedItem.lugar_id,
          turno: this.editedItem.turno,
          nota: this.editedItem.nota,
          estado_fuerza: this.editedItem.estado_fuerza,
          presentes: this.editedItem.presentes,
          faltas: this.editedItem.faltas,
          incapacidad: this.editedItem.incapacidad,
          descanso: this.editedItem.descanso,
          permisos: this.editedItem.permiso,
          inmueble: this.editedItem.inmueble,
          fecha: new Date().toISOString().substr(0, 10),
          empleado_id: this.empleado_id,
        })
        .then((response) => {
          this.fetchCoberturas();
          this.dialog2 = false;
          this.editedItem = [];
          if (response.data.code == 218) {
            alert(response.data.message);
          }
        })
        .catch((errors) => {
          alert(errors.response.data.message);
          console.log(errors);
        });
    },

    editarCobertura() {
      let url = `${this.urlPrefix}/api/coberturas/${this.editedItem.id}`;
      this.$http
        .put(url, {
          lugar_id: this.editedItem.lugar_id,
          turno: this.editedItem.turno,
          nota: this.editedItem.nota,
          estado_fuerza: this.editedItem.estado_fuerza,
          presentes: this.editedItem.presentes,
          faltas: this.editedItem.faltas,
          incapacidad: this.editedItem.incapacidad,
          descanso: this.editedItem.descanso,
          permisos: this.editedItem.permiso,
          inmueble: this.editedItem.inmueble,
          fecha: this.editedItem.fecha,
        })
        .then((response) => {
          this.fetchCoberturas();
          this.dialog2 = false;
          this.editedItem = [];
          if (response.data.code == 218) {
            alert(response.data.message);
          }
        })
        .catch((errors) => {
          alert(errors.response.data.message);
          console.log(errors);
        });
    },

    deleteItem(item) {
      let band = confirm(`¿Deseas borrar la cobertura ?`);
      if (!band) {
        return false;
      }
      this.loading = true;
      let url = `${this.urlPrefix}/api/cobertura/${item.id}`;
      this.$http
        .delete(url)
        .then(() => {
          this.fetchCoberturas();
          this.loading = false;
        })
        .catch((errors) => {
          alert(errors.response.data.message);
          this.loading = false;
        });
    },

    editItem(item) {
      this.editedItem = Object.assign({}, item);
      console.log(this.editedItem);
      this.editedItem.faltas = `${item.faltas}`;
      this.editedItem.incapacidad = `${item.incapacidad}`;
      this.editedItem.descanso = `${item.descanso}`;
      this.editedItem.permiso = `${item.permiso}`;
      this.bandEdit = true;
      this.dialog2 = true;
    },
  },
};
</script>