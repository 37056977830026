<template>
  <v-app>
    <v-container fluid>
      <v-card-title>
        <v-col cols="12" sm="6" md="4">
          <v-dialog
            ref="dialog3"
            v-model="modal3"
            :return-value.sync="fechaInicio"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="fechaInicio"
                label="Fecha de inicio de búsqueda"
                prepend-icon="event"
                name="fecha_inicio"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="fechaInicio"
              :value="fechaInicio"
              scrollable
            >
              <div class="flex-grow-1"></div>
              <v-btn text color="primary" @click="cerrarFechaInicio()"
                >Cancel</v-btn
              >
              <v-btn text color="primary" @click="saveFechaInicio">OK</v-btn>
            </v-date-picker>
          </v-dialog>
          <v-col cols="12" sm="6" md="4">
            <v-autocomplete
              :items="horas"
              name="horaInicio"
              v-model="horaInicio"
              label="Hora de Inicio"
            ></v-autocomplete>
          </v-col>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-dialog
            ref="dialog4"
            v-model="modal4"
            :return-value.sync="fechaFin"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="fechaFin"
                label="Fecha de fin de búsqueda"
                prepend-icon="event"
                name="fecha_fin"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="fechaFin" :value="fechaFin" scrollable>
              <div class="flex-grow-1"></div>
              <v-btn text color="primary" @click="cerrarFechaFin()"
                >Cancel</v-btn
              >
              <v-btn text color="primary" @click="saveFechaFin">OK</v-btn>
            </v-date-picker>
          </v-dialog>
          <v-col cols="12" sm="6" md="4">
            <v-autocomplete
              :items="horas"
              name="horaFin"
              v-model="horaFin"
              label="Hora de Fin"
            ></v-autocomplete>
          </v-col>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
          v-if="$store.getters.currentEmpresa == null"
        >
          <v-select
            :items="empresas"
            @change="getLugares"
            name="empresa"
            append-icon="search"
            v-model="empresa"
            item-text="nombre"
            item-value="id"
            label="Empresa"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-select
            :items="tipoServicios"
            @change="getLugares('tipoServicio')"
            name="tipoServicio"
            append-icon="search"
            v-model="tipoServicio"
            item-text="nombre"
            item-value="id"
            label="Tipo de Servicio"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-autocomplete
            :items="lugaresBusqueda"
            name="lugar"
            append-icon="search"
            v-model="lugar"
            item-text="nombre"
            item-value="id"
            label="Lugar"
          ></v-autocomplete>
        </v-col>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Filtrar/Buscar Empleados"
          single-line
          hide-details
        />
        <v-spacer />
        <v-col>
          <v-btn color="pink" dark @click="buscar()"
            >Buscar
            <v-icon right>search</v-icon>
          </v-btn>
        </v-col>
        <v-btn color="teal" dark @click="exportar"
          >Exportar
          <v-icon right>import_export</v-icon>
        </v-btn>
        <v-spacer />
        <v-btn color="blue" dark @click="reset"
          >Limpiar Filtros
          <v-icon right>filter_list</v-icon>
        </v-btn>
        <v-spacer />
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        :options.sync="options"
        :sort-by="'name'"
        :loading="loading"
        :footer-props="{
          itemsPerPageOptions: rows_per_page_items, // -> Add this example
          itemsPerPageText: rows_per_page_text,
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'keyboard_arrow_left',
          nextIcon: 'keyboard_arrow_right',
        }"
        light
        item-key="id"
      >
        <v-progress-linear slot="progress" color="blue" indeterminate />
        <template v-slot:item.foto="{ item }">
          <v-img max-height="100px" max-width="100px" :src="item.foto"></v-img>
        </template>
        <template v-slot:item.action="{ item }">
          <v-icon small @click="verFatiga(item)"> edit </v-icon>
        </template>
      </v-data-table>
      <v-dialog
        ref="dialog5"
        v-model="modal5"
        :return-value.sync="fechaInicio"
        persistent
        fullscreen
      >
        <v-card class="mx-auto" outlined>
          <v-card-title> Desglosado </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="items"
              :search="search"
              :options.sync="options"
              :sort-by="'name'"
              :loading="loading"
              :footer-props="{
                itemsPerPageOptions: rows_per_page_items, // -> Add this example
                itemsPerPageText: rows_per_page_text,
                showFirstLastPage: true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'keyboard_arrow_left',
                nextIcon: 'keyboard_arrow_right',
              }"
              light
              item-key="id"
            >
              <v-progress-linear slot="progress" color="blue" indeterminate />
              <template v-slot:item.foto="{ item }">
                <span v-if="$vuetify.breakpoint.smAndDown">
                  <v-img
                    max-height="40px"
                    max-width="40px"
                    :src="item.foto"
                  ></v-img>
                </span>
                <span v-if="$vuetify.breakpoint.mdAndUp">
                  <v-img
                    max-height="100px"
                    max-width="100px"
                    :src="item.foto"
                  ></v-img>
                </span>
              </template>
              <template v-slot:item.action="{ item }">
                <v-icon small @click="verFatiga(item)"> edit </v-icon>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn color="blue darken-1" text @click="modal5 = false"
              >Cerrar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-app>
</template>
<script>
export default {
  data: () => ({
    urlPrefix: '',//"https://dev.checkinseguridad.com",
    horas: [
      "00",
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
    ],
    horaInicio: "00",
    horaFin: "00",
    dialog: false,
    editedItem: [],
    tipoServicios: [],
    tipoServicio: null,
    modal: false,
    modal5: false,
    bancos: [],
    bancoSelected: {},
    empleado: [],
    pago_id: [],
    modal2: false,
    modal3: false,
    modal4: false,
    date: null,
    tipoFechas: ["fecha_alta", "fecha_baja"],
    tipoFecha: null,
    date2: null,
    seguro_id: [],
    fechaInicio: null,
    fechaFin: null,
    empresas: [],
    lugaresBusqueda: [],
    empresa: null,
    lugar: null,
    headers: [
      { text: "Foto", value: "foto", align: "center" },
      { text: "Nombre", value: "nombreCompleto", align: "center" },
      { text: "Lugar", value: "empleado.lugar.nombre", align: "center" },
      { text: "Status", value: "empleado.status", align: "center" },
      { text: "Tipo", value: "tipo", align: "center" },
      { text: "Fecha y hora", value: "timestamp", align: "center" },
      { text: "Ubicacion", value: "ubicacion", align: "center" },
      { text: "Invertir", value: "invert", align: "center", sortable: false },
      { text: "Agregar", value: "ent", align: "center", sortable: false },
    ],
    filters: [{ text: "Filtrar por nombre", value: "nombre" }],
    filterBy: {
      text: "Filtrar por nombre",
      value: "nombre",
    },
    orderBy: "ASC",
    orderColor: "teal",
    items: [],
    total_items: 0,
    options: {
      descending: false,
      rowsPerPage: 50,
      page: 1,
    },
    search: "",
    rows_per_page_items: [5, 10, 25, 50],
    rows_per_page_text: "Filas por pagina",
    meta: {
      current_page: 1,
      last_page: 1,
      per_page: 50,
    },
    page: 1,
    loading: false,
  }),
  watch: {
    page: {
      /*handler() {
        this.fetchUsers();
      }*/
    },
  },
  computed: {
    sortIcon() {
      if (this.orderBy === "ASC") {
        return "keyboard_arrow_up";
      }
      return "keyboard_arrow_down";
    },
  },
  mounted() {
    this.fetchEmpresas();
    this.fetchTipoServicios();
  },
  methods: {
    fetchTipoServicios() {
      let self = this;
      self.loading = true;
      let url = `${this.urlPrefix}/api/tipoServicio/all`;
      if (self.page > 1) {
        url = `${url}&page=${self.page}`;
      }
      this.$http
        .get(url)
        .then((response) => {
          this.tipoServicios = response.data.data;

          self.meta = {
            current_page: response.data.current_page,
            last_page: response.data.last_page,
            per_page: response.data.per_page,
          };
          self.total_items = response.data.total;
          self.loading = false;
        })
        .catch((errors) => {
          console.log(errors);
          self.loading = false;
        });
    },

    reset() {
      this.empresa = null;
      this.cerrarFechaInicio();
      this.cerrarFechaFin();
      this.lugar = null;
      this.lugaresBusqueda = [];
      this.search = null;
      this.fetchCobertura();
      this.tipoFecha = null;
      this.tipoServicio = null;
    },

    fetchEmpresas() {
      let self = this;
      self.loading = true;
      self.items = [];
      let url = `${this.urlPrefix}/api/empresas/all`;
      if (self.page > 1) {
        url = `${url}&page=${self.page}`;
      }
      this.$axios
        .get(url)
        .then((response) => {
          self.empresas = response.data.data;
          self.meta = {
            current_page: response.data.current_page,
            last_page: response.data.last_page,
            per_page: response.data.per_page,
          };
          self.total_items = response.data.total;
          self.loading = false;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    fetchCobertura() {
      let url = "";
      if (this.$store.getters.currentEmpresa !== null) {
        this.empresa = this.$store.getters.currentEmpresa;
      }
      if (
        this.empresa == null &&
        this.lugar == null &&
        this.fechaFin == null &&
        this.fechaInicio == null
      ) {
        url = `${this.urlPrefix}/api/cobertura/all`;
      } else if (this.lugar != null) {
        if (this.fechaInicio != null) {
          this.fechaInicio = `${this.fechaInicio} ${this.horaInicio}:00:00`;
        }
        if (this.fechaFin != null) {
          this.fechaFin = `${this.fechaFin} ${this.horaFin}:00:00`;
        }
        url = `${this.urlPrefix}/api/cobertura/${this.fechaInicio}/${this.fechaFin}/${this.lugar}`;
        if (this.fechaInicio != null) {
          this.fechaInicio = this.fechaInicio.split(" ")[0];
        }
        if (this.fechaFin != null) {
          this.fechaFin = this.fechaFin.split(" ")[0];
        }
      } else {
        if (this.fechaInicio != null) {
          this.fechaInicio = `${this.fechaInicio} ${this.horaInicio}:00:00`;
        }
        if (this.fechaFin != null) {
          this.fechaFin = `${this.fechaFin} ${this.horaFin}:00:00`;
        }
        url = `${this.urlPrefix}/api/cobertura/empresa/${this.fechaInicio}/${this.fechaFin}/${this.empresa}`;
        if (this.fechaInicio != null) {
          this.fechaInicio = this.fechaInicio.split(" ")[0];
        }
        if (this.fechaFin != null) {
          this.fechaFin = this.fechaFin.split(" ")[0];
        }
      }

      let self = this;
      self.loading = true;
      self.items = [];
      this.$axios
        .get(url)
        .then((response) => {
          self.items = response.data.data;
          self.items.forEach((element) => {
            element.foto = "storage/" + element.foto;
            element.nombreCompleto =
              element.empleado.nombre +
              " " +
              element.empleado.apellido_paterno +
              " " +
              element.empleado.apellido_materno;
          });

          self.meta = {
            current_page: response.data.current_page,
            last_page: response.data.last_page,
            per_page: response.data.per_page,
          };
          self.total_items = response.data.total;
          self.loading = false;
        })
        .catch((errors) => {
          console.log(errors);
          self.loading = false;
        });
    },

    getLugares: function name(event) {
      let self = this;
      self.loading = true;
      let url = `${this.urlPrefix}/api/lugares/byEmpresa/${this.empresa}/${this.tipoServicio}`;
      if (self.page > 1) {
        url = `${url}&page=${self.page}`;
      }
      this.$axios
        .get(url)
        .then((response) => {
          self.lugaresBusqueda = response.data.data;

          self.meta = {
            current_page: response.data.current_page,
            last_page: response.data.last_page,
            per_page: response.data.per_page,
          };
          self.total_items = response.data.total;
          self.loading = false;
        })
        .catch((errors) => {
          console.log(errors);
          self.loading = false;
        });
      if (event == "empresa") {
        this.fetchSeguro();
      } else {
        this.items = [];
        this.lugar = null;
      }
    },

    saveFechaInicio() {
      this.$refs.dialog3.save(this.fechaInicio);
    },

    saveFechaFin() {
      this.$refs.dialog4.save(this.fechaFin);
    },

    cerrarFecha2() {
      this.date2 = null;
      this.$refs.dialog2.save(this.date2);
      this.modal2 = false;
    },

    cerrarFechaInicio() {
      this.fechaInicio = null;
      this.$refs.dialog3.save(this.fechaInicio);
      this.modal3 = false;
    },

    cerrarFechaFin() {
      this.fechaFin = null;
      this.$refs.dialog4.save(this.fechaFin);
      this.modal4 = false;
    },

    asignarDate() {
      this.date2 = new Date().toISOString().substr(0, 10);
    },

    sleep(time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    },

    toggleOrderBy() {
      if (this.orderBy === "ASC") {
        this.orderBy = "DESC";
        this.orderColor = "orange";
      } else {
        this.orderBy = "ASC";
        this.orderColor = "teal";
      }
    },

    toProperCase(key) {
      let newStr = key.replace(/_/g, " ");
      return newStr.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },

    changeSort(column) {
      if (this.options.sortBy === column) {
        this.options.descending = !this.options.descending;
      } else {
        this.options.sortBy = column;
        this.options.descending = false;
      }
    },

    exportar() {
      let url = "";
      if (this.lugar != null) {
        if (this.fechaInicio != null) {
          this.fechaInicio = `${this.fechaInicio} ${this.horaInicio}:00:00`;
        }
        if (this.fechaFin != null) {
          this.fechaFin = `${this.fechaFin} ${this.horaFin}:00:00`;
        }
        url = `${this.urlPrefix}/api/cobertura/exportar/lugar/${this.fechaInicio}/${this.fechaFin}/${this.lugar}`;
        if (this.fechaInicio != null) {
          this.fechaInicio = this.fechaInicio.split(" ")[0];
        }
        if (this.fechaFin != null) {
          this.fechaFin = this.fechaFin.split(" ")[0];
        }
      } else {
        if (this.fechaInicio != null) {
          this.fechaInicio = `${this.fechaInicio} ${this.horaInicio}:00:00`;
        }
        if (this.fechaFin != null) {
          this.fechaFin = `${this.fechaFin} ${this.horaFin}:00:00`;
        }
        url = `${this.urlPrefix}/api/cobertura/exportar/empresa/${this.fechaInicio}/${this.fechaFin}/${this.empresa}`;
        if (this.fechaInicio != null) {
          this.fechaInicio = this.fechaInicio.split(" ")[0];
        }
        if (this.fechaFin != null) {
          this.fechaFin = this.fechaFin.split(" ")[0];
        }
      }
      this.$axios
        .get(url)
        .then((response) => {
          const blob = new Blob([response.data], {
            type: "text/csv;charset=utf-8;",
          });
          if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(blob, "cobertura.csv");
          } else {
            const link = document.createElement("a");
            if (link.download !== undefined) {
              const url1 = URL.createObjectURL(blob);
              link.setAttribute("href", url1);
              link.setAttribute("download", "cobertura.csv");
              link.style.visibility = "hidden";
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
          }
        })
        .catch((errors) => {
          console.log(errors);
          self.loading = false;
        });
    },

    buscar() {
      this.fetchCobertura();
    },

    verFatiga() {
      this.modal5 = true;
    },
  },
};
</script>