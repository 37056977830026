<template>
    <v-app>
            <v-container fluid>
                <v-card-title>
                    <v-text-field
                        v-model="search"
                        append-icon="search"
                        label="Filtrar/Buscar Sueldo"
                        single-line
                        hide-details
                    />
                      <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ }">
            <v-btn color="primary" dark class="mb-2" @click='editItem'>Agregar Asistencia a {{$route.params.nombre }}</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Pago</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field type="number"  v-model="editedItem.sueldo_prometido" label="Sueldo Prometido"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field type="number"  v-model="editedItem.sueldo_diario" label="Sueldo Diario"></v-text-field>
                  </v-col>
                   <v-col cols="12" sm="6" md="4">
                    <v-text-field   v-model="editedItem.no_cuenta" label="NO. de Cuenta"></v-text-field>
                  </v-col>
                 <v-col cols="12" sm="6" md="4">
                              <v-select
                            :items="bancos"
                            name="banco"
                             
                            v-model="editedItem.bancoId"
                            item-text="nombre"
                            item-value="id"
                            label="Banco"
                              ></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <div class="flex-grow-1"></div>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="save">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
                   
                     <v-spacer/>
                    <v-btn 
                        color="teal" 
                        dark @click="exportar"
                        >Exportar
                        <v-icon right>import_export</v-icon>
                    </v-btn>  
                </v-card-title>
                <v-data-table
                    :headers="headers"
                    :items="items"
                    :search="search"
                    :options.sync="options"
                    :sort-by="'name'"
                    :loading="loading"
                    :footer-props="{
                        itemsPerPageOptions:rows_per_page_items, // -> Add this example
                        itemsPerPageText:rows_per_page_text,
                        showFirstLastPage: true,
                        firstIcon: 'mdi-arrow-collapse-left',
                        lastIcon: 'mdi-arrow-collapse-right',
                        prevIcon: 'keyboard_arrow_left',
                        nextIcon: 'keyboard_arrow_right'
                      }"
                    light
                    item-key="id"
                    
                >
                    <v-progress-linear 
                    slot="progress" 
                    color="blue" 
                    indeterminate/>
                    <template v-slot:item.action="{ item }">
                        <v-icon
                          small
                          @click="deleteItem(item)"
                        >
                          delete
                        </v-icon>
                    </template>
                     <template v-slot:item.comp="{ item }">
                        <v-icon
                          small
                          class="mr-2"
                          @click="agregarPagos(item)"
                        >
                          edit
                        </v-icon>
                    </template>
                    <template v-slot:item.asistencias="{ item }">
                        <v-icon
                          small
                          class="mr-2"
                          @click="verAsistencias(item)"
                        >
                          edit
                        </v-icon>
                    </template>
                </v-data-table>
            </v-container>
    </v-app>
</template>
<script>
export default {
  data: () => ({
    dialog:false,
    editedItem:[],
     modal: false,
     bancos:[],
     bancoSelected:{},
     empleado:[],
     pago_id:[],
    headers: [
      { text: "ID", value: "id", align: "center" },
      { text: "Ubicacion", value: "ubicacion", align: "center" },
      { text: "Fecha", value: "timestamp", align: "center" },
      { text: "Foto", value: "foto", align: "center" },
      { text: "Acciones", value: "action", align: "center", sortable: false }
    ],
    filters: [
      { text: "Filtrar por nombre", value: "nombre" }
    ],
    filterBy: {
      text: "Filtrar por nombre",
      value: "nombre"
    },
    orderBy: "ASC",
    orderColor: "teal",
    items: [],
    total_items: 0,
    options: {
      descending: false,
      rowsPerPage: 50,
      page: 1
    },
    search: "",
    rows_per_page_items: [5, 10, 25, 50],
    rows_per_page_text: "Filas por pagina",
    meta: {
      current_page: 1,
      last_page: 1,
      per_page: 50
    },
    page: 1,
    loading: false
  }),
  watch: {
    page: {
      handler() {
        this.fetchUsers();
      }
    }

  },
  computed: {
    sortIcon() {
      if (this.orderBy === "ASC") {
        return "keyboard_arrow_up";
      }
      return "keyboard_arrow_down";
    },

  },
  created () {
  },
  mounted () {
      let self = this;
      self.fetchBancos();
      self.setPagoId(this.$route.params.pago_id);
      self.fetchPago();
     

  },
  methods: {
    setPagoId(value){
        this.pago_id=value;
    },
    asignarDate(){
      this.date2=new Date().toISOString().substr(0, 10);
    },
    exportar(){
      console.log("exportar");
      let url = `/api/personasCsv`;
        this.$axios
          .get(url)
          .then(response => {
            let blob = new Blob([response.data], { type: 'application/csv' });
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'competidores.csv';
            link.click();
          })
          .catch(errors => {
            console.log(errors);
            self.loading = false;
          });
      
    },
     save(){
        let self = this;
        self.loading = true;
        //agregar
        if(this.pago_id==undefined){
          console.log("nuevo");
          this.agregarPago();
        }else{//editar
          console.log(this.editedItem);
          
          let url = `/api/pagos/${this.$route.params.pago_id}`;
          this.$axios
          .put(url, {sueldo_prometido:this.editedItem.sueldo_prometido, sueldo_diario: this.editedItem.sueldo_diario,
                      no_cuenta:this.editedItem.no_cuenta, banco_id:this.editedItem.bancoId,
          })
          .then(response => {
              console.log(response.data);
              this.fetchPago();
              this.dialog=false;
          })
          .catch(errors => {
            console.log(errors);
            
          });
        }
        this.editedItem=[];
        this.date="";
        self.loading=false;
     },
     editItem () {
       //console.log(item);
       
      //this.editedItem = Object.assign({}, this.items)
      //console.log(this.items[0].banco_id);
      if(this.items[0]!=null){
        this.editedItem.bancoId=this.items[0].banco_id;
        this.editedItem.sueldo_prometido=this.items[0].sueldo_prometido;
        this.editedItem.sueldo_diario=this.items[0].sueldo_diario;
        this.editedItem.no_cuenta=this.items[0].no_cuenta;
      }
      this.bancos=this.fetchBancos();
      this.dialog=true;
      },
      fetchBancos() {
        let url = `/api/bancos/all`;
        this.$axios
          .get(url)
          .then(response => {
            this.bancos=[];
            this.bancos=response.data.data;
            this.bancoSelected.selected_id=this.editedItem.bancoId;
            response.data.data.forEach(element => {
               this.bancos.push({nombre:element.nombre, id:element.id});
            });
            //console.log(this.bancos);
            
          })
          .catch(errors => {
            console.log(errors);
            self.loading = false;
          });
     },
      deleteItem(){
        let band=confirm(`Deseas borrar el registro de pago`);
        if(band==false){
          return false;
        } 
        this.updatePagoFromUser(null);
         let self = this;
         self.loading = true;
         let url = `/api/pagos/${this.$route.params.pago_id}`;
             this.$axios
        .delete(url)
        .then(response => {
          console.log(response.data);
          this.pago_id=null;
          this.fetchPago();
        })
        .catch(errors => {
          console.log(errors);
        });
        self.loading=false;
    },
    updatePagoFromUser(value){         
         let url = `/api/empleados/${this.$route.params.id}`;
          this.$axios
          .put(url, {nombre:this.$route.params.nombre, CURP: this.$route.params.CURP,
                      NSS:this.$route.params.NSS, RFC:this.$route.params.RFC, fecha_alta:this.$route.params.fecha_alta,
                      status:this.$route.params.status, lugar_id:this.$route.params.lugarId, puesto_id: this.$route.params.puestoId,
                      pago_id:value
          })
          .then(response => {
              console.log(response.data);
              //this.fetchEmpleados();
              this.dialog=false;
          })
          .catch(errors => {
            console.log(errors);
            
          });
    },
    agregarPago(){
       let url = `/api/pagos`;
       let self = this;
       self.loading = true;
       //console.log(this.editedItem);
        this.$axios
        .post(url, {sueldo_prometido:this.editedItem.sueldo_prometido, sueldo_diario: this.editedItem.sueldo_diario,
                      no_cuenta:this.editedItem.no_cuenta, banco_id:this.editedItem.bancoId, 
        })
        .then(response => {
            console.log("id "+response.data.data.id);
             this.updatePagoFromUser(response.data.data.id)
            this.sleep(1000).then(() => {
              this.pago_id=response.data.data.id;
                this.fetchPago();
             });
            //this.$router.push({ name: 'competenciasPorCompetidor', params: { id: response.data.id, nombre:response.data.nombre } });
        })
        .catch(errors => {
          console.log(errors);
          
        });
        this.editedItem=[];
        console.log("pago_id "+this.pago_id);
        self.loading=false;
    },
     sleep (time) {
        return new Promise((resolve) => setTimeout(resolve, time));
    },
    toggleOrderBy() {
      if (this.orderBy === "ASC") {
        this.orderBy = "DESC";
        this.orderColor = "orange";
      } else {
        this.orderBy = "ASC";
        this.orderColor = "teal";
      }
    },  
    close(){
      this.editedItem=[];
      this.date=new Date().toISOString().substr(0, 10);
      this.date2=null;
      this.dialog=false;
    },
    fetchPago() {
      console.log(this.pago_id);
      
      let self = this;
      self.loading = true;
      self.items = [];
      let url = `/api/pagos/${this.pago_id}`;
      this.$axios
        .get(url)
        .then(response => {
          self.items = response.data.data; 
          //console.log(self.items[0].dojo.nombre);
          //console.log(response.data.total);
          
          self.meta = {
            current_page: response.data.current_page,
            last_page: response.data.last_page,
            per_page: response.data.per_page
          };
          self.total_items = response.data.total,
          self.loading = false;
          console.log(response.data.data);
          
        })
        .catch(errors => {
          console.log(errors);
          self.loading = false;
        });
    },
    toProperCase(key) {
      let newStr = key.replace(/_/g, " ");
      return newStr.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    changeSort(column) {
      if (this.options.sortBy === column) {
        this.options.descending = !this.options.descending;
      } else {
        this.options.sortBy = column;
        this.options.descending = false;
      }
    },
  },
};
</script>