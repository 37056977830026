import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import Public from './layouts/Public.vue';
import router from './router'
import 'vuetify/dist/vuetify.min.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import store from './store';
import Admin from './layouts/Admin.vue';
import VueAxios from './plugins/axios';
// import Ingenieria from './layouts/Ingenieria.vue';
// import Administracion from './layouts/Administracion.vue';
// import Supervisor from './layouts/Supervisor.vue';
// import Seguro from './layouts/Seguro.vue';
// import Tarjetas from './layouts/Tarjetas.vue';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import '@mdi/font/css/materialdesignicons.css';
import ApiService from './api/api.service';

Vue.use(vuetify)
Vue.use(VueAxios);

Vue.component('public-layout', Public);
Vue.component('admin-layout', Admin);
// Vue.component('ingenieria-layout', Ingenieria);
// Vue.component('administracion-layout', Administracion);
// Vue.component('supervisor-layout', Supervisor);
// Vue.component('seguro-layout', Seguro);
// Vue.component('tarjetas-layout', Tarjetas);

Vue.config.productionTip = false



ApiService.init();

window.Vue = require('vue');

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
// const opts = {}
// new Vue({
//   router,
//   store,
//   vuetify: new vuetify(opts),
//   components: { App },
//   render: h => h(App)
// }).$mount('#app');
