<template>
  <v-app>
    <v-container fluid>
      <v-card-title>
        <span class="headline">Historial de Actualizaciones</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-select
              :items="empresas"
              @change="getLugares"
              name="empresa"
              append-icon="search"
              v-model="empresa"
              item-text="nombre"
              item-value="id"
              label="Empresa"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-select
              :items="tipoServicios"
              @change="getLugares"
              name="tipoServicio"
              append-icon="search"
              v-model="tipoServicio"
              item-text="nombre"
              item-value="id"
              label="Tipo de Servicio"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-autocomplete
              :items="lugares"
              name="lugar"
              append-icon="search"
              @change="fetchHistorial"
              v-model="lugar"
              item-text="nombre"
              item-value="id"
              label="Lugar"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Filtrar/Buscar Empleado"
              single-line
              hide-details
            />
          </v-col>
          <v-spacer />
          <v-btn color="teal" dark @click="exportar"
            >Exportar
            <v-icon right>import_export</v-icon>
          </v-btn>
          <v-spacer />
          <v-btn color="blue" dark @click="reset"
            >Limpiar Filtros
            <v-icon right>filter_list</v-icon>
          </v-btn>
        </v-row>
      </v-card-text>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        :options.sync="options"
        :sort-by="'name'"
        :loading="loading"
        :footer-props="{
          itemsPerPageOptions: rows_per_page_items, // -> Add this example
          itemsPerPageText: rows_per_page_text,
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'keyboard_arrow_left',
          nextIcon: 'keyboard_arrow_right',
        }"
        light
        item-key="id"
      >
        <v-progress-linear slot="progress" color="blue" indeterminate />
        <template v-slot:item.action="{ item }">
          <v-icon small @click="deleteItem(item)"> delete </v-icon>
        </template>
      </v-data-table>
    </v-container>
  </v-app>
</template>
<script>
export default {
  data: () => ({
    urlPrefix: "", //"https://dev.checkinseguridad.com",
    dialog: false,
    empresas: [],
    empresa: null,
    lugares: [],
    lugar: null,
    tipoServicios: [],
    tipoServicio: null,
    headers: [
      { text: "Nombre", value: "nombreCompleto", align: "center" },
      { text: "Servicio", value: "empleado.lugar.nombre", align: "center" },
      { text: "Mensaje", value: "msg", align: "center" },
      { text: "Campo", value: "key", align: "center" },
      { text: "Nuevo Valor", value: "value", align: "center" },
      { text: "Fecha de modificación", value: "modify_at", align: "center" },
      { text: "Actualizado por", value: "user.name", align: "center" },
      { text: "Acciones", value: "action", align: "center", sortable: false },
    ],
    filters: [{ text: "Filtrar por nombre", value: "nombre" }],
    filterBy: {
      text: "Filtrar por nombre",
      value: "nombre",
    },
    orderBy: "ASC",
    orderColor: "teal",
    items: [],
    total_items: 0,
    options: {
      descending: false,
      rowsPerPage: 50,
      page: 1,
    },
    search: "",
    rows_per_page_items: [5, 10, 25, 50],
    rows_per_page_text: "Filas por pagina",
    meta: {
      current_page: 1,
      last_page: 1,
      per_page: 50,
    },
    page: 1,
    loading: false,
  }),
  watch: {
    page: {
      /*handler() {
        this.fetchUsers();
      }*/
    },
  },
  computed: {
    sortIcon() {
      if (this.orderBy === "ASC") {
        return "keyboard_arrow_up";
      }
      return "keyboard_arrow_down";
    },
  },
  created() {
    //
  },

  mounted() {
    let self = this;
    self.fetchEmpresas();
    self.fetchHistorial();
    self.fetchTipoServicios();
  },
  methods: {
    fetchTipoServicios() {
      let self = this;
      self.loading = true;
      let url = `${this.urlPrefix}/api/tipoServicio/all`;
      if (self.page > 1) {
        url = `${url}&page=${self.page}`;
      }
      this.$http
        .get(url)
        .then((response) => {
          this.tipoServicios = response.data.data;
          self.meta = {
            current_page: response.data.current_page,
            last_page: response.data.last_page,
            per_page: response.data.per_page,
          };
          (self.total_items = response.data.total), (self.loading = false);
        })
        .catch((errors) => {
          console.log(errors);
          alert(errors.response.data.message);
          self.loading = false;
        });
    },

    reset() {
      this.empresa = null;
      this.lugar = null;
      this.search = null;
      this.tipoServicio = null;
      this.fetchHistorial();
    },

    getLugares: function name() {
      this.fetchLugaresPorEmpresa();
      this.fetchHistorial();
    },

    fetchLugaresPorEmpresa() {
      let self = this;
      self.loading = true;
      self.items = [];
      let url = `${this.urlPrefix}/api/lugares/byEmpresa/${this.empresa}/${this.tipoServicio}`;
      if (self.page > 1) {
        url = `${url}&page=${self.page}`;
      }
      this.$axios
        .get(url)
        .then((response) => {
          self.lugares = response.data.data;

          self.meta = {
            current_page: response.data.current_page,
            last_page: response.data.last_page,
            per_page: response.data.per_page,
          };
          self.total_items = response.data.total;
          self.loading = false;
        })
        .catch((errors) => {
          console.log(errors);
          self.loading = false;
        });
    },

    fetchEmpresas() {
      let self = this;
      self.loading = true;
      self.items = [];
      let url = `${this.urlPrefix}/api/empresas/all`;
      if (self.page > 1) {
        url = `${url}&page=${self.page}`;
      }
      this.$axios
        .get(url)
        .then((response) => {
          self.empresas = response.data.data;

          self.meta = {
            current_page: response.data.current_page,
            last_page: response.data.last_page,
            per_page: response.data.per_page,
          };
          self.total_items = response.data.total;
          self.loading = false;
        })
        .catch((errors) => {
          console.log(errors);
          self.loading = false;
        });
    },

    fetchHistorial() {
      let url = `${this.urlPrefix}/api/historial/${this.empresa}/${this.tipoServicio}/${this.lugar}`;
      let self = this;
      self.loading = true;
      self.items = [];

      this.$axios
        .get(url)
        .then((response) => {
          self.items = response.data.data;
          this.items.forEach((element) => {
            if (element) {
              element.nombreCompleto =
                element.empleado.nombre +
                " " +
                element.empleado.apellido_paterno +
                " " +
                element.empleado.apellido_materno;
            }
          });

          self.meta = {
            current_page: response.data.current_page,
            last_page: response.data.last_page,
            per_page: response.data.per_page,
          };
          (self.total_items = response.data.total), (self.loading = false);
        })
        .catch((errors) => {
          console.log(errors);
          self.loading = false;
        });
    },

    getLugarbyId(id) {
      let url = `${this.urlPrefix}/api/lugares/${id}`;
      let self = this;
      self.loading = true;
      this.$axios
        .get(url)
        .then((response) => {
          let lugar = response.data.data.nombre;
          this.headers[5].value = lugar;
          self.meta = {
            current_page: response.data.current_page,
            last_page: response.data.last_page,
            per_page: response.data.per_page,
          };
          (self.total_items = response.data.total), (self.loading = false);
        })
        .catch((errors) => {
          console.log(errors);
          self.loading = false;
        });
    },

    toggleOrderBy() {
      if (this.orderBy === "ASC") {
        this.orderBy = "DESC";
        this.orderColor = "orange";
      } else {
        this.orderBy = "ASC";
        this.orderColor = "teal";
      }
    },

    toProperCase(key) {
      let newStr = key.replace(/_/g, " ");
      return newStr.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },

    changeSort(column) {
      if (this.options.sortBy === column) {
        this.options.descending = !this.options.descending;
      } else {
        this.options.sortBy = column;
        this.options.descending = false;
      }
    },

    deleteItem(item) {
      let band = confirm(
        `Deseas borrar este registro de ${item.empleado.nombre}`
      );
      if (!band) {
        return false;
      }

      let self = this;
      self.loading = true;
      let url = `${this.urlPrefix}/api/historial/${item.id}`;
      this.$axios
        .delete(url)
        .then(() => {
          this.fetchHistorial();
        })
        .catch((errors) => {
          console.log(errors);
        });
      self.loading = false;
    },
    exportar() {
      //
    },
  },
};
</script>