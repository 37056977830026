<template>
  <v-app-bar color="#0353a4" fixed dark app clipped-right>
    <v-toolbar-title
      ><router-link to="/dash" tag="span" :style="{ cursor: 'pointer' }"
        >Check in Admin</router-link
      ></v-toolbar-title
    >
    <v-spacer></v-spacer>
    <v-toolbar-items class="hidden-sm-and-down">
      <v-menu :offset-y="true">
        <template v-slot:activator="{ on }">
          <v-btn text v-on="on">Empleados</v-btn>
        </template>
        <v-list>
          <v-list-item v-for="item in menuEmpleados" :key="item.label">
            <v-list-item-title tag="a">
              <v-btn width="100%" text :to="item.route">{{ item.label }}</v-btn>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu :offset-y="true">
        <template v-slot:activator="{}">
          <v-btn text to="/asistencias">Asistencias</v-btn>
        </template>
      </v-menu>
      <v-menu :offset-y="true">
        <template v-slot:activator="{ on }">
          <v-btn text v-on="on">Administrar</v-btn>
        </template>
        <v-list>
          <v-list-item v-for="item in menu" :key="item.label">
            <v-list-item-title tag="a">
              <v-btn width="100%" text :to="item.route">{{ item.label }}</v-btn>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu :offset-y="true">
        <template v-slot:activator="{ on }">
          <v-btn text v-on="on">Reportes</v-btn>
        </template>
        <v-list>
          <v-list-item v-for="item in menuReportes" :key="item.label">
            <v-list-item-title tag="a">
              <v-btn width="100%" text :to="item.route">{{ item.label }}</v-btn>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu :offset-y="true">
        <template v-slot:activator="{}">
          <v-btn text @click="logout">[ {{ salir }} ]</v-btn>
        </template>
      </v-menu>
    </v-toolbar-items>
    <v-menu left max-height="350px">
      <template v-slot:activator="{ on }">
        <v-btn dark icon v-on="on" class="hidden-md-and-up">
          <v-icon>more_vert</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item-group>
          <v-list-item>
            <v-list-item-title tag="a">
              <v-btn width="100%" text to="/asistencias">Asistencias</v-btn>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title tag="a">
              <v-btn width="100%" text to="/pagos">Tarjetas</v-btn>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title tag="a">
              <v-btn width="100%" text to="/seguro">IMSS</v-btn>
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-for="item in menu" :key="item.label">
            <v-list-item-title tag="a">
              <v-btn width="100%" text :to="item.route">{{ item.label }}</v-btn>
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>
<script>
import { LOGOUT } from "../store/actions.type";
export default {
  data: () => ({
    login: "Acceder",
    sorteo: "Ver Sorteos",
    salir: "Cerrar Sesión",
    historial: "Historial",
    empleados: "empleados",
    fatigas: "Fatigas",
    usuarios: "Usuarios",
    empresas: "Empresas",
    lugares: "Servicios",
    menu: [
      { label: "Coberturas", route: "/coberturas" },
      { label: "Agregar Cobertura", route: "/agregarCobertura" },
      { label: "Usuarios", route: "/usuarios" },
      { label: "Comodines y Fallidas", route: "/failedAsistencias" },
      { label: "Empresas", route: "/empresa" },
      { label: "Tipo de Servicios", route: "/tipoServicio" },
      { label: "Servicios", route: "/lugar" },
      { label: "Telegram", route: "/chat" },
      { label: "Agregar Servicios Masivamente", route: "/seedServicios" },
      { label: "Agregar Empleados Masivamente", route: "/seed" },
    ],
    menuEmpleados: [
      { label: "Agregar Empleado", route: "/NewEmployee" },
      { label: "Empleados Activos", route: "/empleados" },
      { label: "Modificar Datos Bancarios", route: "/pagos" },
      { label: "Modificar Datos Seguridad Social", route: "/seguro" },
    ],
    menuReportes: [
      { label: "Historial de actualizaciones", route: "/historial" },
      { label: "Mostrar Fatigas", route: "/fatiga" },
    ],
  }),
  methods: {
    logout() {
      // get the redirect object
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "home" }))
        .catch((errors) => {
          console.log(errors);
        });
    },
  },
};
</script>
