<template>
  <div class="container">
    <component :is="layout">
      <router-view></router-view>
    </component>
  </div>
</template>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>

<script>
//import { log } from 'util';
import store from "./store";
export default {
  computed: {
    layout() {
      if (store.getters.currentUser != undefined) {
        //console.log(user);

        if (store.getters.currentRole == "admin") {
          //console.log("admin layout");
          return "admin-layout";
        }
        if (store.getters.currentRole == "ingenieria") {
          //console.log("admin layout");
          return "ingenieria-layout";
        }
        if (store.getters.currentRole == "administracion") {
          //console.log("admin layout");
          return "administracion-layout";
        }
        if (store.getters.currentRole == "supervisor") {
          //console.log("admin layout");
          return "supervisor-layout";
        }
        if (store.getters.currentRole == "trabajador") {
          //console.log("admin layout");
          return "public-layout";
        }
        if (store.getters.currentRole == "seguro") {
          //console.log("admin layout");
          return "seguro-layout";
        }
        if (store.getters.currentRole == "tarjetas") {
          //console.log("admin layout");
          return "tarjetas-layout";
        }
      } else {
        return "public-layout";
      }
      return "public-layout";
    },
  },
};
</script>