<template>
  <v-app>
    <v-container fluid>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Filtrar/Buscar Usuarios"
          single-line
          hide-details
        />
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on"
              >Agregar Usuario</v-btn
            >
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Usuario</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form
                  ref="form"
                  v-model="valid"
                  id="check-login-form"
                  lazy-validation
                >
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.nombre"
                        label="Nombre"
                        :rules="nombreRules"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        type="email"
                        v-model="editedItem.email"
                        label="Correo"
                        :rules="emailRules"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-if="update"
                        type="password"
                        :rules="passwordRules"
                        v-model="editedItem.password"
                        label="Contraseña"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        type="password"
                        v-if="update"
                        :rules="confirmationRules"
                        v-model="editedItem.password_confirmation"
                        label="Confirmar Contraseña"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-select
                        :items="roles"
                        name="roles"
                        :rules="roleRules"
                        v-model="editedItem.roleId"
                        @change="check"
                        item-text="nombre"
                        item-value="nombre"
                        label="Role"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-select
                        :items="empresas"
                        v-if="empresaBand"
                        name="empresa"
                        :rules="empresaRules"
                        v-model="editedItem.empresaId"
                        item-text="nombre"
                        item-value="id"
                        label="Empresa"
                      ></v-select>
                    </v-col>
                    <v-btn
                      color="red"
                      v-if="!update"
                      text
                      @click="cambiarPassword"
                      >Cambiar Contraseña</v-btn
                    >
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          :rules="confirmationRules"
                          type="password"
                          v-if="cambiarPasswordBand"
                          v-model="editedItem.newPassword"
                          label="Nueva Contraseña"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          :rules="confirmationRules"
                          type="password"
                          v-if="cambiarPasswordBand"
                          v-model="editedItem.confirmNewPassword"
                          label="Confirmar Contraseña"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <div class="flex-grow-1"></div>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="save">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-spacer />
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        :options.sync="options"
        :sort-by="'name'"
        :loading="loading"
        :footer-props="{
          itemsPerPageOptions: rows_per_page_items, // -> Add this example
          itemsPerPageText: rows_per_page_text,
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'keyboard_arrow_left',
          nextIcon: 'keyboard_arrow_right',
        }"
        light
        item-key="id"
      >
        <v-progress-linear slot="progress" color="blue" indeterminate />
        <template v-slot:item.action="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)"> edit </v-icon>
          <v-icon small @click="deleteItem(item)"> delete </v-icon>
        </template>
        <template v-slot:item.comp="{ item }">
          <v-icon small class="mr-2" @click="agregarPagos(item)"> edit </v-icon>
        </template>
        <template v-slot:item.verifica="{ item }">
          <v-btn @click="verificar(item)"> Verificar </v-btn>
        </template>
        <template v-slot:item.desverifica="{ item }">
          <v-btn color="red" @click="desverificar(item)"> Desverificar </v-btn>
        </template>
      </v-data-table>
    </v-container>
  </v-app>
</template>
<script>
export default {
  data: () => ({
    urlPrefix: '',//"https://dev.checkinseguridad.com",
    valid: false,
    cambiarPasswordBand: false,
    dialog: false,
    update: true,
    editedItem: [],
    empresaBand: false,
    empresas: [],
    status: ["Activo", "Inactivo"],
    sexo: ["Masculino", "Femenino"],
    modal: false,
    roles: [],
    roleSelected: {},
    headers: [
      { text: "ID", value: "id", align: "center" },
      { text: "Nombre", value: "name", align: "center" },
      { text: "correo", value: "email", align: "center" },
      { text: "role", value: "roles[0].name", align: "center" },
      { text: "verificado", value: "email_verified_at", align: "center" },
      {
        text: "Verificaciones",
        value: "verifica",
        align: "center",
        sortable: false,
      },
      {
        text: "Verificaciones",
        value: "desverifica",
        align: "center",
        sortable: false,
      },
      { text: "Acciones", value: "action", align: "center", sortable: false },
    ],
    nombreRules: [(v) => !!v || "Nombre requerido"],
    emailRules: [
      (v) => !!v || "Correo requerido",
      (v) => /.+@.+\..+/.test(v) || "Correo debe ser válido",
    ],
    passwordRules: [(v) => !!v || "Contraseña requerida"],
    confirmationRules: [(v) => !!v || "Confirmación requerido"],
    roleRules: [(v) => !!v || "Role requerido"],
    empresaRules: [(v) => !!v || "Empresa requerida"],
    filters: [{ text: "Filtrar por nombre", value: "nombre" }],
    filterBy: {
      text: "Filtrar por nombre",
      value: "nombre",
    },
    orderBy: "ASC",
    orderColor: "teal",
    items: [],
    total_items: 0,
    options: {
      descending: false,
      rowsPerPage: 50,
      page: 1,
    },
    search: "",
    rows_per_page_items: [5, 10, 25, 50],
    rows_per_page_text: "Filas por pagina",
    meta: {
      current_page: 1,
      last_page: 1,
      per_page: 50,
    },
    page: 1,
    loading: false,
  }),
  watch: {
    page: {},
  },
  computed: {
    sortIcon() {
      if (this.orderBy === "ASC") {
        return "keyboard_arrow_up";
      }
      return "keyboard_arrow_down";
    },
  },
  mounted() {
    let self = this;
    self.fetchRoles();
    self.fetchEmpresas();
    self.fetchUsers();
  },
  methods: {
    fetchEmpresas() {
      let self = this;
      this.empresas = [];
      let url = `${this.urlPrefix}/api/empresas/all`;
      if (self.page > 1) {
        url = `${url}&page=${self.page}`;
      }
      this.$http
        .get(url)
        .then((response) => {
          self.empresas = response.data.data;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    exportar() {
      let url = `${this.urlPrefix}/api/personasCsv`;
      this.axios
        .get(url)
        .then((response) => {
          let blob = new Blob([response.data], { type: "application/csv" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "competidores.csv";
          link.click();
        })
        .catch((errors) => {
          console.log(errors);
          self.loading = false;
        });
    },

    save() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.empresaBand = false;
      let self = this;
      self.loading = true;
      //agregar
      if (this.editedItem.id == undefined) {
        this.agregarUsuario();
      } else {
        //editar
        let url = `${this.urlPrefix}/api/users/${this.editedItem.id}`;
        if (this.editedItem.roleId !== "administracion") {
          this.editedItem.empresaId = null;
        }
        this.$http
          .put(url, {
            name: this.editedItem.nombre,
            email: this.editedItem.email,
            role: this.editedItem.roleId,
            password: this.editedItem.newPassword,
            password_confirmation: this.editedItem.confirmNewPassword,
            empresa_id: this.editedItem.empresaId,
          })
          .then(() => {
            this.dialog = false;
            this.fetchUsers();
            self.loading = false;
            this.update = true;
            this.editedItem = [];
            this.date = "";
            this.cambiarPasswordBand = false;
          })
          .catch((errors) => {
            alert(errors.response.data.message);
            console.log(errors);
            this.dialog = false;
            this.update = true;
            this.cambiarPasswordBand = false;
          });
        self.loading = false;
      }
    },

    editItem(item) {
      this.editedItem = Object.assign({}, item);
      this.editedItem.nombre = item.name;
      this.editedItem.roleId = item.roles[0].name;
      if (this.editedItem.roleId == "administracion") {
        this.empresaBand = true;
        this.editedItem.empresaId = item.empresa_id;
      }
      this.update = false;
      this.roles = this.fetchRoles();
      this.dialog = true;
    },
    deleteItem(item) {
      let band = confirm(`Deseas borrar el usuario ${item.name}`);
      if (!band) {
        return false;
      }
      let self = this;
      self.loading = true;
      let url = `${this.urlPrefix}/api/users/${item.id}`;
      this.$http
        .delete(url)
        .then(() => {
          this.fetchUsers();
        })
        .catch((errors) => {
          console.log(errors);
        });
      self.loading = false;
    },

    fetchRoles() {
      let url = `${this.urlPrefix}/api/roles`;
      this.$http
        .get(url)
        .then((response) => {
          this.roles = [];
          this.roleSelected.selected_id = this.editedItem.roleId;
          response.data.data.forEach((element) => {
            this.roles.push({ nombre: element.name, id: element.id });
          });
        })
        .catch((errors) => {
          console.log(errors);
        });
      self.loading = false;
    },

    agregarUsuario() {
      let url = `${this.urlPrefix}/api/users`;
      let self = this;
      self.loading = true;
      this.$http
        .post(url, {
          name: this.editedItem.nombre,
          email: this.editedItem.email,
          password: this.editedItem.password,
          password_confirmation: this.editedItem.password_confirmation,
          role: this.editedItem.roleId,
          empresa_id: this.editedItem.empresaId,
        })
        .then(() => {
          this.fetchUsers();
          this.editedItem = [];
          this.dialog = false;
        })
        .catch((errors) => {
          alert(errors.response.data.message);
          console.log(errors.response);
        });
      self.loading = false;
    },

    toggleOrderBy() {
      if (this.orderBy === "ASC") {
        this.orderBy = "DESC";
        this.orderColor = "orange";
      } else {
        this.orderBy = "ASC";
        this.orderColor = "teal";
      }
    },

    close() {
      this.editedItem = [];
      this.date = new Date().toISOString().substr(0, 10);
      this.date2 = null;
      this.update = true;
      this.empresaBand = false;
      this.cambiarPasswordBand = false;
      this.dialog = false;
    },

    fetchUsers() {
      this.loading = true;
      this.items = [];
      let url = `${this.urlPrefix}/api/users/all`;
      if (this.page > 1) {
        url = `${url}&page=${this.page}`;
      }
      this.$http
        .get(url)
        .then((response) => {
          this.items = response.data.data;

          this.meta = {
            current_page: response.data.current_page,
            last_page: response.data.last_page,
            per_page: response.data.per_page,
          };
          this.total_items = response.data.total;
          this.loading = false;
        })
        .catch((errors) => {
          console.log(errors);
          this.loading = false;
        });
    },

    toProperCase(key) {
      let newStr = key.replace(/_/g, " ");
      return newStr.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },

    changeSort(column) {
      if (this.options.sortBy === column) {
        this.options.descending = !this.options.descending;
      } else {
        this.options.sortBy = column;
        this.options.descending = false;
      }
    },

    verificar(item) {
      let url = `${this.urlPrefix}/api/users/verificar/${item.id}`;
      this.$http
        .put(url, { email_verified_at: "verificar" })
        .then(() => {
          this.dialog = false;
          this.fetchUsers();
          self.loading = false;
          this.update = true;
          this.editedItem = [];
          this.date = "";
        })
        .catch((errors) => {
          alert(errors.response.data.message);
          console.log(errors);
          this.dialog = false;
          this.update = true;
        });
    },

    desverificar(item) {
      let url = `${this.urlPrefix}/api/users/desverificar/${item.id}`;
      this.$http
        .put(url, { email_verified_at: "desverificar" })
        .then(() => {
          this.dialog = false;
          this.fetchUsers();
          this.loading = false;
          this.update = true;
          this.editedItem = [];
          this.date = "";
        })
        .catch((errors) => {
          alert(errors.response.data.message);
          console.log(errors);
          this.dialog = false;
          this.update = true;
        });
    },

    cambiarPassword() {
      this.editedItem.oldPassword = null;
      this.editedItem.newPassword = null;
      this.editedItem.confirmNewPassword = null;
      this.cambiarPasswordBand = !this.cambiarPasswordBand;
    },

    check() {
      console.log(this.editedItem.roleId);
      if (this.editedItem.roleId == "administracion") {
        this.empresaBand = true;
      } else {
        this.empresaBand = false;
      }
    },

  },
};
</script>