var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-container',{attrs:{"fluid":""}},[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"search","label":"Filtrar/Buscar Empresa","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},on),[_vm._v("Agregar Grupo")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Agregar Grupo")])]),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"form",attrs:{"id":"check-login-form","lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"rules":_vm.titleRules,"label":"Nombre"},model:{value:(_vm.editedItem.title),callback:function ($$v) {_vm.$set(_vm.editedItem, "title", $$v)},expression:"editedItem.title"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"rules":_vm.idRules,"label":"ID"},model:{value:(_vm.editedItem.id),callback:function ($$v) {_vm.$set(_vm.editedItem, "id", $$v)},expression:"editedItem.id"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('div',{staticClass:"flex-grow-1"}),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v("Save")])],1)],1)],1),_c('v-spacer'),_c('v-btn',{attrs:{"color":"teal","dark":""},on:{"click":_vm.exportar}},[_vm._v("Exportar "),_c('v-icon',{attrs:{"right":""}},[_vm._v("import_export")])],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"options":_vm.options,"sort-by":'name',"loading":_vm.loading,"footer-props":{
        itemsPerPageOptions: _vm.rows_per_page_items, // -> Add this example
        itemsPerPageText: _vm.rows_per_page_text,
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'keyboard_arrow_left',
        nextIcon: 'keyboard_arrow_right',
      },"light":"","item-key":"id"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.lugars",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.addLugares(item)}}},[_vm._v(" edit ")])]}},{key:"item.action",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" edit ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" delete ")])]}}])},[_c('v-progress-linear',{attrs:{"slot":"progress","color":"blue","indeterminate":""},slot:"progress"})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }