<template>
  <v-app>
    <v-container fluid fill-height>
      <v-layout justify-center align-center>
        <v-flex shrink>
          <div class="display-2">Check in Admin</div>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>
<script>
export default {
  mounted() {
    //
  },
  methods: {},
};
</script>