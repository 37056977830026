var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"color":"#0353a4","fixed":"","dark":"","app":"","clipped-right":""}},[_c('v-toolbar-title',[_c('router-link',{style:({ cursor: 'pointer' }),attrs:{"to":"/dash","tag":"span"}},[_vm._v("Check in Admin")])],1),_c('v-spacer'),_c('v-toolbar-items',{staticClass:"hidden-sm-and-down"},[_c('v-menu',{attrs:{"offset-y":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_vm._v("Empleados")])]}}])},[_c('v-list',_vm._l((_vm.menuEmpleados),function(item){return _c('v-list-item',{key:item.label},[_c('v-list-item-title',{attrs:{"tag":"a"}},[_c('v-btn',{attrs:{"width":"100%","text":"","to":item.route}},[_vm._v(_vm._s(item.label))])],1)],1)}),1)],1),_c('v-menu',{attrs:{"offset-y":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){return [_c('v-btn',{attrs:{"text":"","to":"/asistencias"}},[_vm._v("Asistencias")])]}}])}),_c('v-menu',{attrs:{"offset-y":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_vm._v("Administrar")])]}}])},[_c('v-list',_vm._l((_vm.menu),function(item){return _c('v-list-item',{key:item.label},[_c('v-list-item-title',{attrs:{"tag":"a"}},[_c('v-btn',{attrs:{"width":"100%","text":"","to":item.route}},[_vm._v(_vm._s(item.label))])],1)],1)}),1)],1),_c('v-menu',{attrs:{"offset-y":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_vm._v("Reportes")])]}}])},[_c('v-list',_vm._l((_vm.menuReportes),function(item){return _c('v-list-item',{key:item.label},[_c('v-list-item-title',{attrs:{"tag":"a"}},[_c('v-btn',{attrs:{"width":"100%","text":"","to":item.route}},[_vm._v(_vm._s(item.label))])],1)],1)}),1)],1),_c('v-menu',{attrs:{"offset-y":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){return [_c('v-btn',{attrs:{"text":""},on:{"click":_vm.logout}},[_vm._v("[ "+_vm._s(_vm.salir)+" ]")])]}}])})],1),_c('v-menu',{attrs:{"left":"","max-height":"350px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"hidden-md-and-up",attrs:{"dark":"","icon":""}},on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}])},[_c('v-list',[_c('v-list-item-group',[_c('v-list-item',[_c('v-list-item-title',{attrs:{"tag":"a"}},[_c('v-btn',{attrs:{"width":"100%","text":"","to":"/asistencias"}},[_vm._v("Asistencias")])],1)],1),_c('v-list-item',[_c('v-list-item-title',{attrs:{"tag":"a"}},[_c('v-btn',{attrs:{"width":"100%","text":"","to":"/pagos"}},[_vm._v("Tarjetas")])],1)],1),_c('v-list-item',[_c('v-list-item-title',{attrs:{"tag":"a"}},[_c('v-btn',{attrs:{"width":"100%","text":"","to":"/seguro"}},[_vm._v("IMSS")])],1)],1),_vm._l((_vm.menu),function(item){return _c('v-list-item',{key:item.label},[_c('v-list-item-title',{attrs:{"tag":"a"}},[_c('v-btn',{attrs:{"width":"100%","text":"","to":item.route}},[_vm._v(_vm._s(item.label))])],1)],1)})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }