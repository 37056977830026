<template>
  <v-app>
    <v-card>
      <v-form v-model="valid" ref="form" lazy-validation>
        <v-container fluid fill-height wrap>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-file-input
                chips
                show-size
                clearable
                ref="front"
                v-on:change="guardar($event)"
                label="CSV"
              ></v-file-input>
            </v-col>
            <v-col>
              <v-btn :disabled="!valid" @click="validate"> Enviar </v-btn>
            </v-col>
            <v-col>
              <v-alert
                v-model="errorSesion"
                :dismissible="true"
                type="error"
                class="mb-4 outlined"
              >
                {{ error }}
              </v-alert>
              <v-alert
                v-model="agregados"
                :dismissible="true"
                type="success"
                class="mb-4 outlined"
              >
                {{ agregadosMsg }}
              </v-alert>
            </v-col>
            <v-col>
              <v-progress-circular
                :indeterminate="indeterminate"
                v-show="cargando"
                :rotate="rotate"
                :dissmisible="true"
                :size="size"
                :value="value"
                :width="width"
                color="light-blue"
                >{{ value }}</v-progress-circular
              >
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </v-app>
</template>
<script>
export default {
  data: () => ({
    urlPrefix: '',//"https://dev.checkinseguridad.com",
    valid: false,
    errorSesion: false,
    cargando: false,
    json: null,
    error: "",
    value: "actualizando",
    agregados: false,
    agregadosMsg: "agregados exitósamente",

    indeterminate: true,
    rotate: 0,
    size: 90,
    width: 4,
  }),
  mounted() {
    //
  },
  methods: {
    guardar(e) {
      this.json = e;
    },

    validate() {
      if (this.json != null) {
        this.cargando = true;
        this.errorSesion = false;
        this.enviar();
      } else {
        this.error = "Se requiere archivo csv";
        this.errorSesion = true;
      }
    },

    enviar() {
      let url = `${this.urlPrefix}/api/lugares/seed`;
      let self = this;
      this.cargando = true;
      self.loading = true;
      let data = new FormData();
      data.append("json", this.json);
      this.$http
        .post(url, data, {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
            "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
          },
        })
        .then(() => {
          this.agregados = true;
          this.dialog = false;
          this.cargando = false;
          this.errorSesion = false;
        })
        .catch((errors) => {
          console.log(errors.response.data);
          this.error = errors.response.data;
          this.agregados = false;
          this.errorSesion = true;
          this.cargando = false;
        });
    },
  },
};
</script>