<template>
  <v-app>
    <v-container fluid>
      <v-card-title>
        <span class="headline">Modificar Datos Bancarios</span>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-select
              :items="tipoFechas"
              name="tipoFechas"
              append-icon="search"
              v-model="tipoFecha"
              @change="fetchPago()"
              label="Buscar por Fecha Ingreso/Baja"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-dialog
              ref="dialog3"
              v-model="modal3"
              :return-value.sync="fechaInicio"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="fechaInicio"
                  label="Fecha de inicio de búsqueda"
                  prepend-icon="event"
                  name="fecha_inicio"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fechaInicio"
                :value="fechaInicio"
                scrollable
              >
                <div class="flex-grow-1"></div>
                <v-btn text color="primary" @click="cerrarFechaInicio()"
                  >Cancel</v-btn
                >
                <v-btn text color="primary" @click="saveFechaInicio">OK</v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-dialog
              ref="dialog4"
              v-model="modal4"
              :return-value.sync="fechaFin"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="fechaFin"
                  label="Fecha de fin de búsqueda"
                  prepend-icon="event"
                  name="fecha_fin"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="fechaFin" :value="fechaFin" scrollable>
                <div class="flex-grow-1"></div>
                <v-btn text color="primary" @click="cerrarFechaFin()"
                  >Cancel</v-btn
                >
                <v-btn text color="primary" @click="saveFechaFin">OK</v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-select
              :items="empresas"
              @change="getLugares"
              name="empresa"
              append-icon="search"
              v-model="empresa"
              item-text="nombre"
              item-value="id"
              label="Empresa"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-select
              :items="tipoServicios"
              @change="getLugares('tipoServicio')"
              name="tipoServicio"
              append-icon="search"
              v-model="tipoServicio"
              item-text="nombre"
              item-value="id"
              label="Tipo de Servicio"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-autocomplete
              :items="lugaresBusqueda"
              name="lugar"
              append-icon="search"
              @change="fetchPago"
              v-model="lugar"
              item-text="nombre"
              item-value="id"
              label="Lugar"
            ></v-autocomplete>
          </v-col>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Filtrar/Buscar Empleados"
            single-line
            hide-details
          />
          <v-dialog v-model="dialog" max-width="500px">
            <v-card>
              <v-card-title>
                <span class="headline">Pago</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-select
                        :items="bancos"
                        name="banco"
                        v-model="editedItem.bancoId"
                        item-text="nombre"
                        item-value="id"
                        label="Banco"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.no_cuenta"
                        label="NO. de Cuenta"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.no_tarjeta"
                        label="No. de Tarjeta"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.clabe"
                        label="CLABE Interbancaria"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <div class="flex-grow-1"></div>
                <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="save">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-spacer />
          <v-btn color="teal" dark @click="exportar"
            >Exportar
            <v-icon right>import_export</v-icon>
          </v-btn>
          <v-spacer />
          <v-btn color="blue" dark @click="reset"
            >Limpiar Filtros
            <v-icon right>filter_list</v-icon>
          </v-btn>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        :options.sync="options"
        :sort-by="'name'"
        :loading="loading"
        :footer-props="{
          itemsPerPageOptions: rows_per_page_items, // -> Add this example
          itemsPerPageText: rows_per_page_text,
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'keyboard_arrow_left',
          nextIcon: 'keyboard_arrow_right',
        }"
        light
        item-key="id"
      >
        <v-progress-linear slot="progress" color="blue" indeterminate />
        <template v-slot:item.action="{ item }">
          <v-icon small @click="editItem(item)"> edit </v-icon>
        </template>
      </v-data-table>
    </v-container>
  </v-app>
</template>
<script>
export default {
  data: () => ({
    urlPrefix: "", //"https://dev.checkinseguridad.com",
    dialog: false,
    editedItem: [],
    modal: false,
    bancos: [],
    tipoServicios: [],
    tipoServicio: null,

    modal3: false,
    modal4: false,
    tipoFechas: ["fecha_alta", "fecha_baja"],
    tipoFecha: null,
    fechaInicio: null,
    fechaFin: null,
    empresas: [],
    lugaresBusqueda: [],
    empresa: null,
    lugar: null,

    bancoSelected: {},
    empleado: [],
    pago_id: [],
    headers: [
      { text: "Unidad o Servicio", value: "lugar.nombre", align: "center" },
      { text: "Nombre", value: "nombreCompleto", align: "center" },
      {
        text: "Fecha de nacimiento",
        value: "fecha_nacimiento",
        align: "center",
      },
      { text: "CURP", value: "CURP", align: "center" },
      { text: "RFC", value: "RFC", align: "center" },
      { text: "NSS", value: "seguro.NSS", align: "center" },
      { text: "Calle", value: "calle", align: "center" },
      { text: "Número", value: "colonia", align: "center" },
      { text: "Estado", value: "estado", align: "center" },
      { text: "Municipio", value: "municipio", align: "center" },
      { text: "CP", value: "cp", align: "center" },
      { text: "Banco", value: "pago.banco.nombre", align: "center" },
      { text: "No. De Cuenta", value: "pago.no_cuenta", align: "center" },
      { text: "Acciones", value: "action", align: "center", sortable: false },
    ],
    filters: [{ text: "Filtrar por nombre", value: "nombre" }],
    filterBy: {
      text: "Filtrar por nombre",
      value: "nombre",
    },
    orderBy: "ASC",
    orderColor: "teal",
    items: [],
    total_items: 0,
    options: {
      descending: false,
      rowsPerPage: 50,
      page: 1,
    },
    search: "",
    rows_per_page_items: [5, 10, 25, 50],
    rows_per_page_text: "Filas por pagina",
    meta: {
      current_page: 1,
      last_page: 1,
      per_page: 50,
    },
    page: 1,
    loading: false,
  }),
  watch: {
    page: {
      handler() {
        this.fetchUsers();
      },
    },
  },
  computed: {
    sortIcon() {
      if (this.orderBy === "ASC") {
        return "keyboard_arrow_up";
      }
      return "keyboard_arrow_down";
    },
  },
  created() {},
  mounted() {
    let self = this;
    self.fetchBancos();
    self.setPagoId(this.$route.params.pago_id);
    self.fetchTipoServicios();
    self.fetchPago();
    self.fetchEmpresas();
  },
  methods: {
    fetchTipoServicios() {
      let self = this;
      self.loading = true;
      let url = `${this.urlPrefix}/api/tipoServicio/all`;
      if (self.page > 1) {
        url = `${url}&page=${self.page}`;
      }
      this.$http
        .get(url)
        .then((response) => {
          this.tipoServicios = response.data.data;

          self.meta = {
            current_page: response.data.current_page,
            last_page: response.data.last_page,
            per_page: response.data.per_page,
          };
          self.total_items = response.data.total;
          self.loading = false;
        })
        .catch((errors) => {
          alert(errors.response.data.message);
          self.loading = false;
        });
    },
    fetchEmpresas() {
      let self = this;
      self.loading = true;
      self.items = [];
      let url = `${this.urlPrefix}/api/empresas/all`;
      if (self.page > 1) {
        url = `${url}&page=${self.page}`;
      }
      this.$axios
        .get(url)
        .then((response) => {
          self.empresas = response.data.data;
          self.empresasAgregar = response.data.data;

          self.meta = {
            current_page: response.data.current_page,
            last_page: response.data.last_page,
            per_page: response.data.per_page,
          };
          self.total_items = response.data.total;
          self.loading = false;
        })
        .catch((errors) => {
          console.log(errors);
          self.loading = false;
        });
    },
    reset() {
      this.empresa = null;
      this.cerrarFechaInicio();
      this.cerrarFechaFin();
      this.lugar = null;
      this.lugaresBusqueda = [];
      this.search = null;
      this.fetchPago();
      this.tipoFecha = null;
    },
    cerrarFechaInicio() {
      this.fechaInicio = null;
      this.$refs.dialog3.save(this.fechaInicio);
      this.modal3 = false;
    },
    cerrarFechaFin() {
      this.fechaFin = null;
      this.$refs.dialog4.save(this.fechaFin);
      this.modal4 = false;
    },
    getLugares: function name(event) {
      let self = this;
      self.loading = true;
      let url = `${this.urlPrefix}/api/lugares/byEmpresa/${this.empresa}/${this.tipoServicio}`;
      if (self.page > 1) {
        url = `${url}&page=${self.page}`;
      }
      this.$http
        .get(url)
        .then((response) => {
          self.lugaresBusqueda = response.data.data;

          self.meta = {
            current_page: response.data.current_page,
            last_page: response.data.last_page,
            per_page: response.data.per_page,
          };
          self.total_items = response.data.total;
          self.loading = false;
        })
        .catch((errors) => {
          console.log(errors);
          self.loading = false;
        });
      if (event == "tipoServicio") {
        this.items = [];
        this.lugar = null;
      } else {
        this.fetchPago();
      }
    },
    saveFechaInicio() {
      this.$refs.dialog3.save(this.fechaInicio);
      this.fetchPago();
    },
    saveFechaFin() {
      this.$refs.dialog4.save(this.fechaFin);
      this.fetchPago();
    },
    setPagoId(value) {
      this.pago_id = value;
    },
    asignarDate() {
      this.date2 = new Date().toISOString().substr(0, 10);
    },
    exportar() {
      let url = "";
      if (
        this.fechaInicio == null &&
        this.fechaFin == null &&
        this.empresa == null &&
        this.tipoServicio == null
      ) {
        url = `${this.urlPrefix}/api/pagos/exportar/all`;
      } else {
        if (
          this.fechaInicio != null &&
          this.fechaFin == null &&
          this.empresa == null &&
          this.lugar == null
        ) {
          url = `${this.urlPrefix}/api/pagos/exportar/byFechaInicio/${this.fechaInicio}/${this.tipoFecha}`;
        } else {
          if (
            this.fechaInicio == null &&
            this.fechaFin != null &&
            this.empresa == null &&
            this.lugar == null
          ) {
            url = `${this.urlPrefix}/api/pagos/exportar/byFechaFin/${this.fechaFin}/${this.tipoFecha}`;
          } else {
            if (
              this.fechaInicio != null &&
              this.fechaFin != null &&
              this.empresa == null &&
              this.lugar == null
            ) {
              url = `${this.urlPrefix}/api/pagos/exportar/byFecha/${this.fechaInicio}/${this.fechaFin}/${this.tipoFecha}`;
            } else {
              if (this.empresa != null && this.lugar == null) {
                url = `${this.urlPrefix}/api/pagos/exportar/byEmpresa/${this.empresa}/${this.fechaInicio}/${this.fechaFin}/${this.tipoFecha}`;
              } else {
                if (
                  (this.empresa != null || this.tipoServicio != null) &&
                  this.lugar != null
                ) {
                  url = `${this.urlPrefix}/api/pagos/exportar/byLugar/${this.lugar}/${this.fechaInicio}/${this.fechaFin}/${this.tipoFecha}`;
                }
              }
            }
          }
        }
      }
      this.$axios
        .get(url)
        .then((response) => {
          const blob = new Blob([response.data], {
            type: "text/csv;charset=utf-8;",
          });
          if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(blob, "tarjetas.csv");
          } else {
            const link = document.createElement("a");
            if (link.download !== undefined) {
              // feature detection
              // Browsers that support HTML5 download attribute
              const url1 = URL.createObjectURL(blob);
              link.setAttribute("href", url1);
              link.setAttribute("download", "tarjetas.csv");
              link.style.visibility = "hidden";
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
          }
        })
        .catch((errors) => {
          console.log(errors);
          self.loading = false;
        });
    },

    save() {
      let self = this;
      self.loading = true;
      let url = `${this.urlPrefix}/api/pagos/${this.editedItem.pago_id}`;
      this.$axios
        .put(url, {
          no_cuenta: this.editedItem.no_cuenta,
          banco_id: this.editedItem.bancoId,
          clabe: this.editedItem.clabe,
          no_tarjeta: this.editedItem.no_tarjeta,
        })
        .then((response) => {
          console.log(response.data);
          this.fetchPago();
          this.dialog = false;
        })
        .catch((errors) => {
          console.log(errors);
        });
      this.editedItem = [];
      self.loading = false;
    },

    editItem(item) {
      this.editedItem.bancoId = item.pago.banco_id;
      this.editedItem.pago_id = item.pago_id;
      this.editedItem.sueldo_prometido = item.pago.sueldo_prometido;
      this.editedItem.sueldo_diario = item.pago.sueldo_diario;
      this.editedItem.no_cuenta = item.pago.no_cuenta;
      this.editedItem.no_tarjeta = item.pago.no_tarjeta;
      this.editedItem.clabe = item.pago.clabe;
      this.bancos = this.fetchBancos();
      this.dialog = true;
    },

    fetchBancos() {
      let url = `${this.urlPrefix}/api/bancos/all`;
      this.$axios
        .get(url)
        .then((response) => {
          this.bancos = [];
          this.bancos = response.data.data;
          this.bancoSelected.selected_id = this.editedItem.bancoId;
          response.data.data.forEach((element) => {
            this.bancos.push({ nombre: element.nombre, id: element.id });
          });
        })
        .catch((errors) => {
          console.log(errors);
          self.loading = false;
        });
    },

    deleteItem() {
      let band = confirm(`Deseas borrar el registro de pago`);
      if (!band) {
        return false;
      }
      this.updatePagoFromUser(null);
      let self = this;
      self.loading = true;
      let url = `${this.urlPrefix}/api/pagos/${this.$route.params.pago_id}`;
      this.$axios
        .delete(url)
        .then((response) => {
          console.log(response.data);
          this.pago_id = null;
          this.fetchPago();
        })
        .catch((errors) => {
          console.log(errors);
        });
      self.loading = false;
    },

    toggleOrderBy() {
      if (this.orderBy === "ASC") {
        this.orderBy = "DESC";
        this.orderColor = "orange";
      } else {
        this.orderBy = "ASC";
        this.orderColor = "teal";
      }
    },

    close() {
      this.editedItem = [];
      this.date = new Date().toISOString().substr(0, 10);
      this.date2 = null;
      this.dialog = false;
    },

    fetchPago() {
      console.log(this.seguro_id);

      let url = "";
      if (
        this.fechaInicio == null &&
        this.fechaFin == null &&
        this.empresa == null &&
        this.tipoServicio == null
      ) {
        url = `${this.urlPrefix}/api/empleados/all`;
      } else {
        if (
          this.fechaInicio != null &&
          this.fechaFin == null &&
          this.empresa == null &&
          this.lugar == null
        ) {
          url = `${this.urlPrefix}/api/empleados/byFechaInicio/${this.fechaInicio}/${this.tipoFecha}`;
        } else {
          if (
            this.fechaInicio == null &&
            this.fechaFin != null &&
            this.empresa == null &&
            this.lugar == null
          ) {
            url = `${this.urlPrefix}/api/empleados/byFechaFin/${this.fechaFin}/${this.tipoFecha}`;
          } else {
            if (
              this.fechaInicio != null &&
              this.fechaFin != null &&
              this.empresa == null &&
              this.lugar == null
            ) {
              url = `${this.urlPrefix}/api/empleados/byFecha/${this.fechaInicio}/${this.fechaFin}/${this.tipoFecha}`;
            } else {
              if (this.empresa != null && this.lugar == null) {
                url = `${this.urlPrefix}/api/empleados/byEmpresa/${this.empresa}/${this.fechaInicio}/${this.fechaFin}/${this.tipoFecha}`;
              } else {
                if (
                  (this.empresa != null || this.tipoServicio != null) &&
                  this.lugar != null
                ) {
                  url = `${this.urlPrefix}/api/empleados/byLugar/${this.lugar}/${this.fechaInicio}/${this.fechaFin}/${this.tipoFecha}`;
                }
              }
            }
          }
        }
      }

      let self = this;
      self.loading = true;
      self.items = [];
      this.$axios
        .get(url)
        .then((response) => {
          self.items = response.data.data;
          this.items.forEach((element) => {
            element.nombreCompleto =
              element.nombre +
              " " +
              element.apellido_paterno +
              " " +
              element.apellido_materno;
          });

          self.meta = {
            current_page: response.data.current_page,
            last_page: response.data.last_page,
            per_page: response.data.per_page,
          };
          self.total_items = response.data.total;
          self.loading = false;
        })
        .catch((errors) => {
          console.log(errors);
          self.loading = false;
        });
    },

    toProperCase(key) {
      let newStr = key.replace(/_/g, " ");
      return newStr.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },

    changeSort(column) {
      if (this.options.sortBy === column) {
        this.options.descending = !this.options.descending;
      } else {
        this.options.sortBy = column;
        this.options.descending = false;
      }
    },
  },
};
</script>