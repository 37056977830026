<template>
    <v-app>
        <v-content>
      <v-container justify-center>
          <v-row justify="center">
            <v-card>
              <v-card-title primary-title class="justify-center">Políticas de privacidad</v-card-title>
              <v-card-text align=center >
                 <b>Recogida y tratamiento de datos de carácter personal</b> <br><br>
                 Ocelotl Studio puede solicitar datos personales de usuarios al acceder a
                  aplicaciones de la empresa o de otras empresas afiliadas así como la
                  posibilidad de que entre estas empresas puedan compartir esos datos
                  para mejorar los productos y servicios ofrecidos. Si no se facilitan esos
                  datos personales, en muchos casos no podremos ofrecer los productos o
                  servicios solicitados.
                  <br><br>
                  <b>Propósito del tratamiento de datos de carácter personal</b> <br> <br>
                                      Ocelotl Studio podrá utilizar los datos personales recabados para: <br>
                    ● Los datos de carácter personal recopilados permiten mantenerle
                    informado acerca de los últimos productos, las actualizaciones
                    de software disponibles y los próximos eventos. <br>
                    ● También se utilizan los datos de carácter personal como ayuda
                    para elaborar, perfeccionar, gestionar,
                    proporcionar y mejorar los productos, servicios, contenidos y
                    publicidad, y con el propósito de evitar pérdidas y fraudes. <br>
                    ● Pueden utilizarse los datos de carácter personal para
                    comprobar la identidad, colaborar en la identificación de
                    usuarios y decidir los servicios apropiados. <br>
                    ● También se utilizan esos datos de carácter personal
                    con propósitos internos, incluyendo auditorías, análisis de datos
                    y sondeos, para mejorar los productos, servicios y
                    comunicaciones a clientes. <br><br>
                    <b>Recopilación y tratamiento de datos de carácter no personal</b> <br><br>
                    Ocelotl Studio también recopilará datos de un modo que, por sí
                    mismos, no pueden ser asociados directamente a una persona
                    determinada. Estos datos de carácter no personal se pueden recopilar,
                    tratar, transferir y publicar con cualquier intención. Estos son algunos
                    ejemplos de las clases de datos de carácter no personal que
                    Ocelotl Studio puede recopilar y los fines para los que se realiza su
                    tratamiento: <br>
                      Datos tales como profesión, idioma, código postal, identificador
                      único de dispositivo, etc. para comprender mejor la conducta de
                      nuestros clientes y mejorar nuestros productos, servicios y
                      anuncios publicitarios. <br>
                      ● Datos sobre cómo se usan determinados servicios, incluidas las
                      consultas de búsqueda. Esta información se puede utilizar para
                      incrementar la importancia de los resultados que aportan los
                      servicios ofrecidos. <br>
                      ● Datos sobre cómo usa su dispositivo y las aplicaciones para
                      facilitar a los desarrolladores la mejora de esas aplicaciones. <br>
                      <br>
                      Si juntamos datos de carácter no personal con datos personales, los
                      datos mezclados serán tratados como datos personales mientras sigan
                      estando combinados. <br><br>
                      <b>Divulgación a terceros</b> <br> <br>
                      Ocasionalmente OcelotlStudio puede facilitar determinados datos de
                        carácter personal a socios estratégicos que trabajen con nosotros para
                        proveer productos y servicios o nos ayudan en nuestras actividades de
                        marketing. No se compartirán los datos con ningún tercero para sus
                        propios fines de marketing. <br><br>
                      <b>Protección de datos de carácter personal</b> <br><br>
                          Ocelotl Studio garantizará la protección de los datos personales
                          mediante cifrado durante el tránsito y hasheado de contraseñas.
                          Al usar ciertos productos, servicios o aplicaciones o al publicar
                          opiniones en foros, salas de chat o redes sociales, el contenido y los
                          datos de carácter personal que se comparta serán visible para otros
                          usuarios, que tendrán la posibilidad de leerlos, compilarlos o usarlos.
                          Usted será responsable de los datos de carácter personal que
                          distribuya o proporcione en estos casos. <br> <br>
                        <b>Integridad y conservación de datos de carácter personal</b>
                         <br><br>
                          Ocelotl Studio garantizará la exactitud y la calidad de los datos
                          personales, se conservarán durante el tiempo necesario para cumplir los
                          fines para los que fueron recabados, salvo que la ley exija conservarlos
                          durante más tiempo. <br><br>
                        <b>Acceso a los datos de carácter personal</b> <br> <br>
                        Respecto a los datos de carácter personal que conservamos,
                          Ocelotl Studio le ofrece acceso a ellos para cualquier fin, incluyendo las
                          solicitudes de rectificación en caso de que sean incorrectos o de
                          eliminación en caso de no estar obligados a conservarlos por imperativo
                          legal o por razones legítimas de negocio. Nos reservamos el derecho a
                          no tramitar aquellas solicitudes que sean improcedentes o vejatorias,
                          que pongan en riesgo la privacidad de terceros, que resulten inviables o
                          para las que la legislación local no exija derecho de acceso. <br><br>
                          <b>Servicios de localización</b> <br><br>
                          Para prestar servicios de localización Ocelotl Studio podrá reunir,
                          utilizar y compartir datos exactos sobre ubicaciones, incluyendo la
                          situación geográfica en tiempo real de su ordenador o de su dispositivo. <br><br>
                          <b>Páginas web y servicios de terceros</b> <br> <br>
                          Las aplicaciones pueden contener enlaces a páginas web, productos y
                          servicios de terceros. También pueden utilizar u ofrecer productos o
                          servicios de terceros. La recogida de datos por parte de terceros,
                          sobre ubicaciones geográficas o datos de
                          contacto, se guiará por sus respectivas políticas de privacidad. Le
                          recomendamos consultar las políticas de privacidad de esos terceros
              </v-card-text>
            </v-card>
          </v-row>
      </v-container>
        </v-content>
    </v-app>
</template>
<script>

export default {
  data: () => ({
      img_src: '/img/karateca.png',
  }),
  computed: {
  },
  created () {
  },
  methods: {
  },
};
</script>