<template>
  <v-app id="inspire">
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="4">
          <v-card class="elevation-12">
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title>Iniciar Sesión</v-toolbar-title>
              <div class="flex-grow-1"></div>
            </v-toolbar>
            <v-card-text>
              <v-form
                ref="form"
                v-model="valid"
                id="check-login-form"
                lazy-validation
              >
                <v-text-field
                  label="Correo"
                  name="email"
                  v-model="email"
                  prepend-icon="person"
                  type="text"
                  required
                  :rules="emailRules"
                ></v-text-field>
                <v-text-field
                  id="password"
                  label="Contraseña"
                  name="password"
                  v-model="password"
                  prepend-icon="lock"
                  type="password"
                  required
                  :rules="passwordRules"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn
                @click="login"
                :loading="loading"
                :disabled="loading"
                color="primary"
                >Iniciar Sesión</v-btn
              >
              <v-btn to="/">Cancelar</v-btn>
            </v-card-actions>
            <v-alert
              dense
              outlined
              v-model="errorSesion"
              :dismissible="true"
              type="error"
              class="mb-4 outlined"
            >
              {{ error }}
            </v-alert>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>
<script>
import { LOGIN } from "../store/actions.type";

export default {
  data() {
    return {
      loading: false,
      email: null,
      password: null,
      has_error: false,
      errorSesion: false,
      error: null,
      valid: false,
      emailRules: [
        (v) => !!v || "Correo Requerido",
        (v) => /.+@.+\..+/.test(v) || "Correo inválido",
      ],
      passwordRules: [
        (v) => !!v || "Contraseña Requerida",
        (v) =>
          (v && v.length >= 4) ||
          "La contraseña tiene que tener al menos 4 caractéres",
      ],
    };
  },
  mounted() {
    //
  },
  methods: {
    login() {
      if (!this.$refs.form.validate()) {
        return;
      }
      // get the redirect object
      const l = this.loading;
      this[l] = !this[l];
      var app = this;
      var email = app.email;
      var password = app.password;
      this.$store
        .dispatch(LOGIN, { email, password })
        .then(() => {
          this[l] = false;
          this.$router.push({ name: "dash" }).catch((err) => {
            console.log(err); //Remove unsafe
          });
        })
        .catch((errors) => {
          console.log(errors); //Remove unsafe
          app.has_error = true;
          this.error = "correo o contraseña erróneo";
          this.errorSesion = true;
          this[l] = false;
        });
    },
  },
};
</script>