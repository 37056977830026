<template>
  <v-app>
    <v-container fluid>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Filtrar/Buscar Tipo de Servicio"
          single-line
          hide-details
        />
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on"
              >Agregar Tipo de Servicio</v-btn
            >
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Tipo Servicio</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form
                  ref="form"
                  v-model="valid"
                  id="check-login-form"
                  lazy-validation
                >
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        :rules="nombreRules"
                        v-model="editedItem.nombre"
                        label="Nombre"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <div class="flex-grow-1"></div>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="save">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-spacer />
        <v-btn color="teal" dark @click="exportar"
          >Exportar
          <v-icon right>import_export</v-icon>
        </v-btn>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        :options.sync="options"
        :sort-by="'name'"
        :loading="loading"
        :footer-props="{
          itemsPerPageOptions: rows_per_page_items, // -> Add this example
          itemsPerPageText: rows_per_page_text,
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'keyboard_arrow_left',
          nextIcon: 'keyboard_arrow_right',
        }"
        light
        item-key="id"
      >
        <v-progress-linear slot="progress" color="blue" indeterminate />
        <template v-slot:item.action="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)"> edit </v-icon>
          <v-icon small @click="deleteItem(item)"> delete </v-icon>
        </template>
      </v-data-table>
    </v-container>
  </v-app>
</template>
<script>
export default {
  data: () => ({
    urlPrefix: '',//"https://dev.checkinseguridad.com",
    valid: false,
    dialog: false,
    editedItem: [],
    headers: [
      { text: "ID", value: "id", align: "center" },
      { text: "Nombre", value: "nombre", align: "center" },
      { text: "Acciones", value: "action", align: "center", sortable: false },
    ],
    filters: [{ text: "Filtrar por nombre", value: "nombre" }],
    filterBy: {
      text: "Filtrar por nombre",
      value: "nombre",
    },
    nombreRules: [(v) => !!v || "Nombre requerido"],
    orderBy: "ASC",
    orderColor: "teal",
    items: [],
    total_items: 0,
    options: {
      descending: false,
      rowsPerPage: 50,
      page: 1,
    },
    search: "",
    rows_per_page_items: [5, 10, 25, 50],
    rows_per_page_text: "Filas por pagina",
    meta: {
      current_page: 1,
      last_page: 1,
      per_page: 50,
    },
    page: 1,
    loading: false,
  }),
  watch: {
    page: {
      handler() {},
    },
  },
  computed: {
    sortIcon() {
      if (this.orderBy === "ASC") {
        return "keyboard_arrow_up";
      }
      return "keyboard_arrow_down";
    },
  },
  mounted() {
    let self = this;
    self.fetchTipoServicios();
  },
  methods: {
    exportar() {
      let url = `${this.urlPrefix}/api/personasCsv`;
      this.$axios
        .get(url)
        .then((response) => {
          let blob = new Blob([response.data], { type: "application/csv" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "competidores.csv";
          link.click();
        })
        .catch((errors) => {
          console.log(errors);
          self.loading = false;
        });
    },

    save() {
      if (!this.$refs.form.validate()) {
        return;
      }
      let self = this;
      self.loading = true;
      //agregar
      if (this.editedItem.id == undefined) {
        this.agregarTipoServicio();
      } else {
        //editar
        this.update = true;
        let url = `${this.urlPrefix}/api/tipoServicio/${this.editedItem.id}`;
        this.$http
          .put(url, { nombre: this.editedItem.nombre })
          .then(() => {
            this.fetchTipoServicios();
            this.editedItem = [];
            this.dialog = false;
          })
          .catch((errors) => {
            alert(errors.response.data.message);
            console.log(errors);
          });
        this.update = true;
      }
      this.date = "";
      self.loading = false;
    },

    editItem(item) {
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      let band = confirm(`Deseas borrar la empresa ${item.nombre}`);
      if (!band) {
        return false;
      }
      let self = this;
      self.loading = true;
      let url = `${this.urlPrefix}/api/tipoServicio/${item.id}`;
      this.$http
        .delete(url)
        .then(() => {
          this.fetchTipoServicios();
        })
        .catch((errors) => {
          console.log(errors);
        });
      self.loading = false;
    },

    agregarTipoServicio() {
      let url = `${this.urlPrefix}/api/tipoServicio`;
      let self = this;
      self.loading = true;
      this.$http
        .post(url, { nombre: this.editedItem.nombre })
        .then(() => {
          this.fetchTipoServicios();
          this.dialog = false;
        })
        .catch((errors) => {
          alert(errors.response.data.message);
          console.log(errors);
        });
      this.editedItem = [];
      self.loading = false;
    },

    toggleOrderBy() {
      if (this.orderBy === "ASC") {
        this.orderBy = "DESC";
        this.orderColor = "orange";
      } else {
        this.orderBy = "ASC";
        this.orderColor = "teal";
      }
    },

    close() {
      this.editedItem = [];
      this.date = new Date().toISOString().substr(0, 10);
      this.date2 = null;
      this.update = true;
      this.dialog = false;
    },

    fetchTipoServicios() {
      let self = this;
      self.loading = true;
      self.items = [];
      let url = `${this.urlPrefix}/api/tipoServicio/all`;
      if (self.page > 1) {
        url = `${url}&page=${self.page}`;
      }
      this.$http
        .get(url)
        .then((response) => {
          self.items = response.data.data;
          self.meta = {
            current_page: response.data.current_page,
            last_page: response.data.last_page,
            per_page: response.data.per_page,
          };
          self.total_items = response.data.total;
          self.loading = false;
        })
        .catch((errors) => {
          console.log(errors);
          self.loading = false;
        });
    },

    toProperCase(key) {
      let newStr = key.replace(/_/g, " ");
      return newStr.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },

    changeSort(column) {
      if (this.options.sortBy === column) {
        this.options.descending = !this.options.descending;
      } else {
        this.options.sortBy = column;
        this.options.descending = false;
      }
    },

  },
};
</script>