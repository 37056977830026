<template>
  <v-app>
    <app-first />
    <v-content>
      <slot />
    </v-content>
    <app-footer />
  </v-app>
</template>

<script>
import AppFirst from "../components/Head";
import AppFooter from "../components/Footer";

export default {
  components: {
    AppFirst,
    AppFooter,
  },
  data: () => ({
    img_src: "/img/dfacture_logo.png",
  }),
  computed: {},
  created() {},
  methods: {},
};
</script>