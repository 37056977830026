<template>
  <v-app-bar color="#0353a4" text dark app clipped-right>
    <v-toolbar-title>
      <router-link
        to="/"
        :style="{ cursor: 'pointer' }"
        custom
        v-slot="{ navigate }"
      >
        <span @click="navigate" @keypress.enter="navigate" role="link"
          >{{ siteName }}</span
        >
      </router-link>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-toolbar-items class="hidden-sm-and-down">
      <span v-for="item in menu" :key="item.label">
        <v-btn text :to="item.route">{{ item.label }}</v-btn>
      </span>
    </v-toolbar-items>
    <v-menu left>
      <template v-slot:activator="{ on }">
        <v-btn dark icon v-on="on" class="hidden-md-and-up">
          <v-icon>more_vert</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item-group v-for="item in menu" :key="item.label">
          <v-list-item :to="item.route">
            <v-list-item-title>{{ item.label }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>
<script>
export default {
  data: () => ({
    login: "Acceder",
    sorteo: "Ver Sorteos",
    menu: [
      // {label:"Registrarse", route:"/register"},
      // {label:"Acceder", route:"/login"}
    ],
    siteName: "Checkin Admin",
  }),
  methods: {},
};
</script>
