<template>
  <v-app>
    <v-container fluid>
      <v-card-title>
        <span class="headline"><v-icon>mdi-account</v-icon>Nuevo Empleado</span>
        <v-col cols="2" sm="2" md="2">
          <v-btn color="#5c677d" class="ma-2 white--text" v-on:click="save">
            Guardar
            <v-icon right dark> mdi-account-plus </v-icon>
          </v-btn>
        </v-col>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form
            ref="form"
            v-model="valid"
            id="check-login-form"
            lazy-validation
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
                v-if="$store.getters.currentEmpresa == null"
              >
                <v-select
                  :items="empresas"
                  @change="fetchServicios"
                  v-model="employee.empresaId"
                  name="empresa"
                  :rules="requerido"
                  item-text="nombre"
                  item-value="id"
                  label="Empresa"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-select
                  :items="servicios"
                  :rules="requerido"
                  @change="fetchInmuebles"
                  v-model="employee.servicioId"
                  name="tipoServicio"
                  item-text="nombre"
                  item-value="id"
                  label="Servicio"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-autocomplete
                  :items="lugares"
                  name="lugar"
                  :rules="requerido"
                  v-model="employee.lugarId"
                  item-text="nombre"
                  item-value="id"
                  label="Inmueble"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  label="Nombre"
                  v-model="employee.nombre"
                  :rules="requerido"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  :rules="requerido"
                  v-model="employee.apellido_paterno"
                  label="Apellido Paterno"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  :rules="requerido"
                  v-model="employee.apellido_materno"
                  label="Apellido Materno"
                ></v-text-field>
              </v-col>
              <v-col cols="2" sm="2" md="2">
                <v-menu
                  v-model="menuFechaIngreso"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      label="Fecha ingreso"
                      prepend-icon="mdi-calendar"
                      name="fecha_alta"
                      :rules="requerido"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    @input="menuFechaIngreso = false"
                    :max="
                      new Date(
                        Date.now() - new Date().getTimezoneOffset() * 60000
                      )
                        .toISOString()
                        .substr(0, 10)
                    "
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="2" sm="2" md="2">
                <v-text-field
                  :rules="curpRules"
                  v-model="employee.CURP"
                  label="CURP"
                ></v-text-field>
              </v-col>
              <v-col cols="2" sm="2" md="2">
                <v-text-field
                  :rules="rfcRules"
                  v-model="employee.RFC"
                  label="RFC"
                ></v-text-field>
              </v-col>
              <v-col cols="2" sm="2" md="2">
                <v-select
                  :items="sexo"
                  name="sexo"
                  :rules="requerido"
                  v-model="employee.sexoId"
                  item-text="nombre"
                  item-value="id"
                  label="Sexo"
                ></v-select>
              </v-col>
              <v-col cols="2" sm="2" md="2">
                <v-text-field
                  v-if="employee.sexoId === 1"
                  :rules="requerido"
                  v-model="employee.numero_cartilla"
                  label="Número Cartilla"
                ></v-text-field>
              </v-col>
              <v-col cols="2" sm="2" md="2">
                <v-text-field
                  v-if="employee.sexoId === 1"
                  :rules="requerido"
                  v-model="employee.clase_cartilla"
                  label="Clase (cartilla)"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-select
                  :items="puestos"
                  name="puesto"
                  :rules="requerido"
                  v-model="employee.puestoId"
                  item-text="cargo"
                  item-value="id"
                  label="Puesto"
                ></v-select>
              </v-col>
              <v-col cols="2" sm="2" md="2  ">
                <v-text-field
                  :rules="requerido"
                  v-model="employee.tel"
                  label="Teléfono"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
          <v-card>
            <v-tabs
              v-model="tab"
              background-color="#001233"
              centered
              dark
              icons-and-text
            >
              <v-tabs-slider></v-tabs-slider>

              <v-tab href="#tab-1">
                Domicilio
                <v-icon>mdi-city-variant-outline</v-icon>
              </v-tab>

              <v-tab href="#tab-2">
                IMSS
                <v-icon>mdi-heart</v-icon>
              </v-tab>

              <v-tab href="#tab-3">
                Créditos
                <v-icon>mdi-cash-100</v-icon>
              </v-tab>

              <v-tab href="#tab-4">
                Datos Bancarios
                <v-icon>mdi-currency-usd</v-icon>
              </v-tab>

              <v-tab href="#tab-5">
                Datos Generales
                <v-icon>mdi-account-box</v-icon>
              </v-tab>

              <v-tab href="#tab-6">
                Nómina/Salarios/Sanciones
                <v-icon>mdi-currency-usd</v-icon>
              </v-tab>

              <v-tab href="#tab-7">
                Armamento
                <v-icon>mdi-pistol</v-icon>
              </v-tab>

              <v-tab href="#tab-8">
                Estatus de inscripción
                <v-icon>mdi-book-open-page-variant-outline</v-icon>
              </v-tab>

              <v-tab href="#tab-9">
                Datos de emergencia
                <v-icon>mdi-card-account-phone-outline</v-icon>
              </v-tab>

              <v-tab href="#tab-10">
                Documentos de contratación
                <v-icon>mdi-text-box-multiple-outline</v-icon>
              </v-tab>

              <v-tab href="#tab-11">
                Cursos
                <v-icon>mdi-school-outline</v-icon>
              </v-tab>

              <v-tab href="#tab-12">
                Equipamiento
                <v-icon>mdi-police-badge-outline</v-icon>
              </v-tab>

              <v-tab href="#tab-13">
                Uniforme
                <v-icon>mdi-tie</v-icon>
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item id="tab-1" eager>
                <v-card flat>
                  <v-card-text class="text-center">
                    <v-form ref="form1" lazy-validation>
                      <v-row>
                        <v-col cols="4" sm="4" md="4">
                          <v-text-field
                            :rules="requerido"
                            v-model="employee.calle"
                            label="Calle"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4" sm="4" md="4">
                          <v-text-field
                            :rules="requerido"
                            v-model="employee.numero"
                            label="Número"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4" sm="4" md="4">
                          <v-text-field
                            :rules="requerido"
                            v-model="employee.colonia"
                            label="Colonia"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4" sm="4" md="4">
                          <v-text-field
                            :rules="requerido"
                            v-model="employee.entre_calle"
                            label="Entre calle"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4" sm="4" md="4">
                          <v-text-field
                            :rules="requerido"
                            v-model="employee.y_calle"
                            label="Y calle"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4" sm="4" md="4">
                          <v-text-field
                            :rules="requerido"
                            v-model="employee.codigo_postal"
                            label="Código Postal"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-select
                            :items="estados"
                            @change="fetchCiudades"
                            name="estado"
                            :rules="requerido"
                            v-model="employee.estadoId"
                            item-text="name"
                            item-value="id"
                            label="Estado"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-select
                            :items="ciudades"
                            name="ciudad"
                            :rules="requerido"
                            v-model="employee.ciudad_id"
                            item-text="nombre"
                            item-value="id"
                            label="Ciudad / Municipio"
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item id="tab-2" eager>
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <v-col cols="2" sm="2" md="2">
                        <v-text-field
                          :rules="requerido"
                          v-model="employee.NSS"
                          label="NSS"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2" sm="2" md="2">
                        <v-menu
                          v-model="menuFechaAltaSeguro"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="dateImss"
                              label="Fecha Alta en el IMSS"
                              prepend-icon="mdi-calendar"
                              name="seguro_fecha_alta"
                              :rules="requerido"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="dateImss"
                            @input="menuFechaAltaSeguro = false"
                            :max="
                              new Date(
                                Date.now() -
                                  new Date().getTimezoneOffset() * 60000
                              )
                                .toISOString()
                                .substr(0, 10)
                            "
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-card>
                          <v-card-title>
                            INCAPACIDADES
                            <v-spacer></v-spacer>
                            <v-dialog v-model="dialog" max-width="800px">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="primary"
                                  dark
                                  class="mb-2"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  Agregar Incapacidad
                                </v-btn>
                              </template>
                              <v-card>
                                <v-card-title>
                                  <span class="text-h5"
                                    >Agregar Incapacidad</span
                                  >
                                </v-card-title>

                                <v-card-text>
                                  <v-container>
                                    <v-row>
                                      <v-col cols="12" sm="6" md="4">
                                        <v-select
                                          :items="tipoIncapacidad"
                                          name="selectTipoIncapacidad"
                                          :rules="requerido"
                                          v-model="incapacidad.tipoIncapacidad"
                                          item-text="nombre"
                                          item-value="id"
                                          label="Tipo de Incapacidad"
                                          return-object
                                        ></v-select>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="4">
                                        <v-menu
                                          v-model="menuIncapacidadFechaInicio"
                                          :close-on-content-click="false"
                                          :nudge-right="40"
                                          transition="scale-transition"
                                          offset-y
                                          min-width="auto"
                                        >
                                          <template
                                            v-slot:activator="{ on, attrs }"
                                          >
                                            <v-text-field
                                              v-model="dateInicioIncapacidad"
                                              label="Fecha inicio incapacidad"
                                              prepend-icon="mdi-calendar"
                                              name="fecha_inicio_incapacidad"
                                              :rules="requerido"
                                              readonly
                                              v-bind="attrs"
                                              v-on="on"
                                            ></v-text-field>
                                          </template>
                                          <v-date-picker
                                            v-model="dateInicioIncapacidad"
                                            @input="
                                              menuIncapacidadFechaInicio = false
                                            "
                                            :max="
                                              new Date(
                                                Date.now() -
                                                  new Date().getTimezoneOffset() *
                                                    60000
                                              )
                                                .toISOString()
                                                .substr(0, 10)
                                            "
                                          ></v-date-picker>
                                        </v-menu>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="4">
                                        <v-menu
                                          v-model="menuIncapacidadFechaFin"
                                          :close-on-content-click="false"
                                          :nudge-right="40"
                                          transition="scale-transition"
                                          offset-y
                                          min-width="auto"
                                        >
                                          <template
                                            v-slot:activator="{ on, attrs }"
                                          >
                                            <v-text-field
                                              v-model="dateFinIncapacidad"
                                              label="Fecha fin incapacidad"
                                              prepend-icon="mdi-calendar"
                                              name="fecha_fin_incapacidad"
                                              :rules="requerido"
                                              readonly
                                              v-bind="attrs"
                                              v-on="on"
                                            ></v-text-field>
                                          </template>
                                          <v-date-picker
                                            v-model="dateFinIncapacidad"
                                            @input="
                                              menuIncapacidadFechaFin = false
                                            "
                                          ></v-date-picker>
                                        </v-menu>
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col cols="12" sm="12" md="12">
                                        <v-textarea
                                          name="inputMotivoIncapacidad"
                                          label="Motivo incapacidad"
                                          auto-grow
                                          filled
                                          v-model="incapacidad.motivo"
                                        ></v-textarea>
                                      </v-col>
                                      <v-col cols="12" sm="12" md="12">
                                        <v-file-input
                                          accept="application/pdf"
                                          label="Archivo Incapacidad"
                                          v-model="incapacidad.fileIncapacidad"
                                          v-on:change="guardar($event)"
                                        ></v-file-input>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-card-text>

                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="close"
                                  >
                                    Cancelar
                                  </v-btn>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="saveIncapacidad('seguro')"
                                  >
                                    Aceptar
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                            <v-dialog v-model="dialogDelete" max-width="500px">
                              <v-card>
                                <v-card-title class="text-h5"
                                  >¿Borrar Incapacidad?</v-card-title
                                >
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="closeDelete"
                                    >Cancel</v-btn
                                  >
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="deleteItemConfirm"
                                    >OK</v-btn
                                  >
                                  <v-spacer></v-spacer>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </v-card-title>
                          <v-data-table
                            :headers="incapacidadHeaders"
                            :items="incapacidades"
                            hide-default-footer
                          >
                            <template v-slot:item.actions="{ item }">
                              <v-icon small @click="deleteItem(item)">
                                mdi-delete
                              </v-icon>
                            </template>
                          </v-data-table>
                          <v-snackbar
                            v-model="snack"
                            :timeout="3000"
                            :color="snackColor"
                          >
                            {{ snackText }}

                            <template v-slot:action="{ attrs }">
                              <v-btn v-bind="attrs" text @click="snack = false">
                                Cerrar
                              </v-btn>
                            </template>
                          </v-snackbar>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item id="tab-3" eager>
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <v-col cols="2" sm="2" md="2">
                        <v-text-field
                          :rules="requerido"
                          v-model="employee.numero_credito_infonavit"
                          label="Número de crédito INFONAVIT"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2" sm="2" md="2">
                        <v-text-field
                          prefix="$"
                          :rules="requerido"
                          v-model="employee.descuento_infonavit"
                          label="Descuento INFONAVIT"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="2" sm="2" md="2">
                        <v-text-field
                          :rules="requerido"
                          v-model="employee.numero_credito_fonacot"
                          label="Número de crédito FONACOT"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2" sm="2" md="2">
                        <v-text-field
                          prefix="$"
                          :rules="requerido"
                          v-model="employee.descuento_fonacot"
                          label="Descuento FONACOT"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item id="tab-4" eager>
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <v-col cols="2" sm="2" md="2">
                        <v-select
                          :items="bancos"
                          v-model="employee.bancoId"
                          name="banco"
                          :rules="requerido"
                          item-text="nombre"
                          item-value="id"
                          label="Banco"
                        ></v-select>
                      </v-col>
                      <v-col cols="2" sm="2" md="2">
                        <v-text-field
                          :rules="requerido"
                          v-model="employee.numero_cuenta"
                          label="Número de cuenta"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="2" sm="2" md="2">
                        <v-text-field
                          :rules="requerido"
                          v-model="employee.clabe"
                          label="CLABE interbancaria"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2" sm="2" md="2">
                        <v-text-field
                          :rules="requerido"
                          v-model="employee.numero_tarjeta"
                          label="Número de tarjeta"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item id="tab-5" eager>
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <v-col cols="2" sm="2" md="2">
                        <v-select
                          :items="estadoCivil"
                          name="estadoCivil"
                          :rules="requerido"
                          v-model="employee.estadoCivilId"
                          item-text="nombre"
                          item-value="id"
                          label="Estado Civil"
                        ></v-select>
                      </v-col>
                      <v-col cols="2" sm="2" md="2">
                        <v-select
                          :items="nacionalidad"
                          name="nacionalidad"
                          :rules="requerido"
                          v-model="employee.nacionalidadId"
                          item-text="nombre"
                          item-value="id"
                          label="Nacionalidad"
                        ></v-select>
                      </v-col>
                      <v-col cols="2" sm="2" md="2">
                        <v-select
                          :items="escolaridad"
                          name="escolaridad"
                          :rules="requerido"
                          v-model="employee.escolaridadId"
                          item-text="nombre"
                          item-value="id"
                          label="Escolaridad"
                        ></v-select>
                      </v-col>
                      <v-col cols="2" sm="2" md="2  ">
                        <v-text-field
                          :rules="requerido"
                          v-model="fechaNacimiento"
                          label="Fecha de Nacimiento (aaaa/mm/dd)"
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2" sm="2" md="2  ">
                        <v-text-field
                          :rules="requerido"
                          v-model="edad"
                          label="Edad"
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-select
                          :items="estados"
                          @change="fetchCiudadesNacimiento"
                          name="estado"
                          :rules="requerido"
                          v-model="employee.estadoNacimientoId"
                          item-text="name"
                          item-value="id"
                          label="Estado de Nacimiento"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-select
                          :items="ciudadesNacimiento"
                          name="ciudad"
                          :rules="requerido"
                          v-model="employee.ciudadNacimiento_id"
                          item-text="nombre"
                          item-value="id"
                          label="Ciudad / Municipio de Nacimiento"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row> </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item id="tab-6" eager>
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <v-col cols="2" sm="2" md="2">
                        <v-select
                          :items="diasPago"
                          name="diaPago"
                          :rules="requerido"
                          v-model="employee.diaPago"
                          item-text="nombre"
                          item-value="id"
                          label="Fecha de pago de nómina"
                        ></v-select>
                      </v-col>
                      <v-col cols="2" sm="2" md="2">
                        <v-text-field
                          :rules="requerido"
                          v-model="employee.sueldo_mensual"
                          label="Sueldo mensual"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2" sm="2" md="2">
                        <v-text-field
                          :rules="requerido"
                          v-model="employee.sueldo_diario"
                          label="Sueldo diario"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2" sm="2" md="2">
                        <v-text-field
                          :rules="requerido"
                          v-model="employee.dia_descanso"
                          label="Día de descanso"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2" sm="2" md="2">
                        <v-text-field
                          :rules="requerido"
                          v-model="employee.descuento_falta_injustificada"
                          label="Descuento por falta injustificada"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item id="tab-7" eager>
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <v-col cols="2" sm="2" md="2">
                        <v-menu
                          v-model="menuDateRecepcionServicio"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="dateRecepcionServicio"
                              label="Fecha cuando llegó el arma a servicio"
                              prepend-icon="mdi-calendar"
                              name="fecha_recepcion_servicio"
                              :rules="requerido"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="dateRecepcionServicio"
                            @input="menuDateRecepcionServicio = false"
                            :max="
                              new Date(
                                Date.now() -
                                  new Date().getTimezoneOffset() * 60000
                              )
                                .toISOString()
                                .substr(0, 10)
                            "
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="2" sm="2" md="2">
                        <v-select
                          :items="statusArmas"
                          name="estatusArma"
                          :rules="requerido"
                          v-model="employee.estatusArma"
                          item-text="nombre"
                          item-value="id"
                          label="Estatus Arma"
                        ></v-select>
                      </v-col>
                      <v-col cols="2" sm="2" md="2">
                        <v-select
                          :items="tipoArmas"
                          name="tipoArma"
                          :rules="requerido"
                          v-model="employee.tipoArma"
                          item-text="nombre"
                          item-value="id"
                          label="Tipo de Arma"
                        ></v-select>
                      </v-col>
                      <v-col cols="2" sm="2" md="2">
                        <v-select
                          :items="claseArmas"
                          name="claseArma"
                          :rules="requerido"
                          v-model="employee.claseArma"
                          item-text="nombre"
                          item-value="id"
                          label="Clase"
                        ></v-select>
                      </v-col>
                      <v-col cols="2" sm="2" md="2">
                        <v-select
                          :items="marcaArmas"
                          name="marcaArma"
                          :rules="requerido"
                          v-model="employee.marcaArma"
                          item-text="nombre"
                          item-value="id"
                          label="Marca"
                        ></v-select>
                      </v-col>
                      <v-col cols="2" sm="2" md="2">
                        <v-select
                          :items="calibreArmas"
                          name="marcaArma"
                          :rules="requerido"
                          v-model="employee.calibreArma"
                          item-text="nombre"
                          item-value="id"
                          label="Calibre"
                        ></v-select>
                      </v-col>
                      <v-col cols="2" sm="2" md="2">
                        <v-select
                          :items="modeloArmas"
                          name="modeloArma"
                          :rules="requerido"
                          v-model="employee.modeloArma"
                          item-text="nombre"
                          item-value="id"
                          label="Modelo"
                        ></v-select>
                      </v-col>
                      <v-col cols="2" sm="2" md="2">
                        <v-text-field
                          :rules="requerido"
                          v-model="employee.matriculaArma"
                          label="Matrícula"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="8" sm="8" md="8">
                        <v-file-input
                          accept="application/pdf"
                          label="Folio de Hoja Rosa"
                          v-model="employee.armaFolioHojaRosa"
                          v-on:change="guardar($event)"
                        ></v-file-input>
                        <v-btn
                          class="ma-2 white--text"
                          color="blue darken-1"
                          text
                          @click="saveDocumento('armamento')"
                        >
                          Guardar Documento
                        </v-btn>
                      </v-col>
                      <v-col cols="2" sm="2" md="2">
                        <v-text-field
                          :rules="requerido"
                          v-model="employee.armaNumeroDeOrden"
                          label="Número de Orden"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2" sm="2" md="2">
                        <v-text-field
                          :rules="requerido"
                          v-model="employee.armaResponsable"
                          label="Responsable / Usuario"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2" sm="2" md="2">
                        <v-text-field
                          :rules="requerido"
                          v-model="employee.armaNumeroOficioComision"
                          label="Número de oficio de Comisión"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2" sm="2" md="2">
                        <v-menu
                          v-model="menuDateComision"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="dateComision"
                              label="Fecha de comisión"
                              prepend-icon="mdi-calendar"
                              name="fecha_comision"
                              :rules="requerido"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="dateComision"
                            @input="menuDateComision = false"
                            :max="
                              new Date(
                                Date.now() -
                                  new Date().getTimezoneOffset() * 60000
                              )
                                .toISOString()
                                .substr(0, 10)
                            "
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="3" sm="3" md="3">
                        <v-checkbox
                          v-model="cuentaConCredencial"
                          label="¿Cuenta con credencial o portación?"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="2" sm="2" md="2">
                        <v-menu
                          v-model="menuDateInicioComoArmado"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="dateInicioComoArmado"
                              label="Fecha de inicio como elemento armado"
                              prepend-icon="mdi-calendar"
                              name="fecha_comision"
                              :rules="requerido"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="dateInicioComoArmado"
                            @input="menuDateInicioComoArmado = false"
                            :max="
                              new Date(
                                Date.now() -
                                  new Date().getTimezoneOffset() * 60000
                              )
                                .toISOString()
                                .substr(0, 10)
                            "
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="2" sm="2" md="2">
                        <v-menu
                          v-model="menuDateInicioVigencia"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="dateInicioVigencia"
                              label="Inicio de vigencia"
                              prepend-icon="mdi-calendar"
                              name="fecha_comision"
                              :rules="requerido"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="dateInicioVigencia"
                            @input="menuDateInicioVigencia = false"
                            :max="
                              new Date(
                                Date.now() -
                                  new Date().getTimezoneOffset() * 60000
                              )
                                .toISOString()
                                .substr(0, 10)
                            "
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="2" sm="2" md="2">
                        <v-menu
                          v-model="menuDateTerminoVigencia"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="dateTerminoVigencia"
                              label="Término de vigencia"
                              prepend-icon="mdi-calendar"
                              name="fecha_comision"
                              :rules="requerido"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="dateTerminoVigencia"
                            @input="menuDateTerminoVigencia = false"
                            :max="
                              new Date(
                                Date.now() -
                                  new Date().getTimezoneOffset() * 60000
                              )
                                .toISOString()
                                .substr(0, 10)
                            "
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="2" sm="2" md="2">
                        <v-select
                          :items="modalidadArmas"
                          name="modalidadArmas"
                          :rules="requerido"
                          v-model="employee.modalidadArmas"
                          item-text="nombre"
                          item-value="id"
                          label="Modalidad"
                        ></v-select>
                      </v-col>
                      <v-col cols="2" sm="2" md="2">
                        <v-select
                          :items="serviciosArmas"
                          name="armaServicio"
                          :rules="requerido"
                          @change="fetchInmueblesArmas"
                          v-model="employee.armaServicio"
                          item-text="nombre"
                          item-value="id"
                          label="Servicio"
                        ></v-select>
                      </v-col>
                      <v-col cols="2" sm="2" md="2">
                        <v-select
                          :items="lugaresArmas"
                          name="armaLugar"
                          :rules="requerido"
                          v-model="employee.armaLugar"
                          item-text="nombre"
                          item-value="id"
                          label="Edificio / Inmueble"
                        ></v-select>
                      </v-col>
                      <v-col cols="6" sm="6" md="6">
                        <v-text-field
                          :rules="requerido"
                          v-model="employee.armaDireccionResguardo"
                          label="Dirección de resguardo"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-snackbar
                        v-model="snack"
                        :timeout="3000"
                        :color="snackColor"
                      >
                        {{ snackText }}

                        <template v-slot:action="{ attrs }">
                          <v-btn v-bind="attrs" text @click="snack = false">
                            Cerrar
                          </v-btn>
                        </template>
                      </v-snackbar>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item id="tab-8" eager>
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <v-col cols="6" sm="6" md="6">
                        <v-menu
                          v-model="menuDateEnvioAntecedentesPoliciales"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="dateEnvioAntecedentesPoliciales"
                              label="Fecha de envio de archivo  de consulta de antecedentes policiales (sábados)"
                              prepend-icon="mdi-calendar"
                              name="fecha_envio_antecedentes_policiales"
                              :rules="requerido"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="dateEnvioAntecedentesPoliciales"
                            @input="menuDateEnvioAntecedentesPoliciales = false"
                            :max="
                              new Date(
                                Date.now() -
                                  new Date().getTimezoneOffset() * 60000
                              )
                                .toISOString()
                                .substr(0, 10)
                            "
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="6" sm="6" md="6">
                        <v-menu
                          v-model="menuDateAcuseAntecedentesPoliciales"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="dateAcuseAntecedentesPoliciales"
                              label="Fecha de acuse de consulta de antecedentes policiales (sábados)"
                              prepend-icon="mdi-calendar"
                              name="fecha_acuse_antecedentes_policiales"
                              :rules="requerido"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="dateAcuseAntecedentesPoliciales"
                            @input="menuDateAcuseAntecedentesPoliciales = false"
                            :max="
                              new Date(
                                Date.now() -
                                  new Date().getTimezoneOffset() * 60000
                              )
                                .toISOString()
                                .substr(0, 10)
                            "
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-file-input
                          accept="application/pdf"
                          label="Número volante de consulta de antecedentes policiales"
                          v-model="
                            employee.numero_volante_consulta_antecedentes_policiales
                          "
                          v-on:change="guardar($event)"
                        ></v-file-input>
                        <v-btn
                          class="ma-2 white--text"
                          color="blue darken-1"
                          text
                          @click="saveDocumento('inscripcion')"
                        >
                          Guardar Documento
                        </v-btn>
                      </v-col>
                      <v-col cols="6" sm="6" md="6">
                        <v-menu
                          v-model="menuDateInscripcion"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="dateInscripcion"
                              label="Fecha de inscripción"
                              prepend-icon="mdi-calendar"
                              name="date_inscripcion"
                              :rules="requerido"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="dateInscripcion"
                            @input="menuDateInscripcion = false"
                            :max="
                              new Date(
                                Date.now() -
                                  new Date().getTimezoneOffset() * 60000
                              )
                                .toISOString()
                                .substr(0, 10)
                            "
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-file-input
                          accept="application/pdf"
                          label="Número de volante de inscripción"
                          v-model="employee.numero_volante_inscripcion"
                          v-on:change="guardar($event)"
                        ></v-file-input>
                        <v-btn
                          class="ma-2 white--text"
                          color="blue darken-1"
                          text
                          @click="saveDocumento('inscripcion')"
                        >
                          Guardar Documento
                        </v-btn>
                      </v-col>
                      <v-col cols="2" sm="2" md="2">
                        <v-text-field
                          :rules="requerido"
                          v-model="employee.cuip"
                          label="CUIP"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-file-input
                          accept="application/pdf"
                          label="Folio de la CIP portable"
                          v-model="employee.folio_cip_portable"
                          v-on:change="guardar($event)"
                        ></v-file-input>
                        <v-btn
                          class="ma-2 white--text"
                          color="blue darken-1"
                          text
                          @click="saveDocumento('inscripcion')"
                        >
                          Guardar Documento
                        </v-btn>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-file-input
                          accept="application/pdf"
                          label="Se cuenta físicamente con la CIP"
                          v-model="employee.cip_fisica"
                          v-on:change="guardar($event)"
                        ></v-file-input>
                        <v-btn
                          class="ma-2 white--text"
                          color="blue darken-1"
                          text
                          @click="saveDocumento('inscripcion')"
                        >
                          Guardar Documento
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-snackbar
                      v-model="snack"
                      :timeout="3000"
                      :color="snackColor"
                    >
                      {{ snackText }}

                      <template v-slot:action="{ attrs }">
                        <v-btn v-bind="attrs" text @click="snack = false">
                          Cerrar
                        </v-btn>
                      </template>
                    </v-snackbar>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item id="tab-9" eager>
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <v-col cols="6" sm="6" md="6">
                        <v-text-field
                          :rules="requerido"
                          v-model="employee.nombre_contacto_emergencia"
                          label="Nombre del contacto de emergencia"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" sm="6" md="6">
                        <v-text-field
                          :rules="requerido"
                          v-model="employee.telefono_contacto_emergencia"
                          label="Teléfono del contacto de emergencia"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" sm="6" md="6">
                        <v-text-field
                          :rules="requerido"
                          v-model="employee.padecimiento_grave"
                          label="Padecimiento grave "
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2" sm="2" md="2">
                        <v-select
                          :items="tipoSangre"
                          name="tipoSangre"
                          :rules="requerido"
                          v-model="employee.tipoSangreId"
                          item-text="nombre"
                          item-value="id"
                          label="Tipo de Sangre"
                        ></v-select>
                      </v-col>
                      <v-col cols="2" sm="2" md="2">
                        <v-text-field
                          :rules="requerido"
                          v-model="employee.estatura"
                          label="Estatura (m)"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2" sm="2" md="2">
                        <v-text-field
                          :rules="requerido"
                          v-model="employee.peso"
                          label="Peso (Kg)"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item id="tab-10" eager>
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-card>
                          <v-card-title>
                            DOCUMENTOS DE CONTRATACIÓN
                            <v-spacer></v-spacer>
                            <v-dialog
                              v-model="dialogDocumentosContratacion"
                              max-width="800px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="primary"
                                  dark
                                  class="mb-2"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  Agregar Documento
                                </v-btn>
                              </template>
                              <v-card>
                                <v-card-title>
                                  <span class="text-h5">Agregar Documento</span>
                                </v-card-title>

                                <v-card-text>
                                  <v-container>
                                    <v-row>
                                      <v-col cols="12" sm="6" md="4">
                                        <v-select
                                          :items="tipoDocumentos"
                                          name="selectTipoDocumento"
                                          :rules="requerido"
                                          v-model="documento.tipoDocumento"
                                          item-text="nombre"
                                          item-value="id"
                                          label="Tipo de Documento"
                                          return-object
                                        ></v-select>
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col cols="12" sm="12" md="12">
                                        <v-textarea
                                          name="inputDocumentoComentarios"
                                          label="Comentarios"
                                          auto-grow
                                          filled
                                          v-model="documento.comentarios"
                                        ></v-textarea>
                                      </v-col>
                                      <v-col cols="12" sm="12" md="12">
                                        <v-file-input
                                          accept="application/pdf"
                                          label="Archivo"
                                          v-model="documento.file_url"
                                          v-on:change="guardar($event)"
                                        ></v-file-input>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-card-text>

                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="closeDocumento"
                                  >
                                    Cancelar
                                  </v-btn>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="saveDocumento('contratacion')"
                                  >
                                    Aceptar
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                            <v-dialog
                              v-model="dialogDeleteDocumento"
                              max-width="500px"
                            >
                              <v-card>
                                <v-card-title class="text-h5"
                                  >¿Borrar Documento?</v-card-title
                                >
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="closeDeleteDocumento"
                                    >Cancel</v-btn
                                  >
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="deleteItemConfirmDocumento"
                                    >OK</v-btn
                                  >
                                  <v-spacer></v-spacer>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </v-card-title>
                          <v-data-table
                            :headers="documentoHeaders"
                            :items="documentos"
                            hide-default-footer
                          >
                            <template v-slot:item.actions="{ item }">
                              <v-icon small @click="deleteItemDocumento(item)">
                                mdi-delete
                              </v-icon>
                            </template>
                          </v-data-table>
                          <v-snackbar
                            v-model="snack"
                            :timeout="3000"
                            :color="snackColor"
                          >
                            {{ snackText }}

                            <template v-slot:action="{ attrs }">
                              <v-btn v-bind="attrs" text @click="snack = false">
                                Cerrar
                              </v-btn>
                            </template>
                          </v-snackbar>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item id="tab-11" eager>
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-card>
                          <v-card-title>
                            CAPACITACION
                            <v-spacer></v-spacer>
                            <v-dialog
                              v-model="dialogCapacitacion"
                              max-width="800px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="primary"
                                  dark
                                  class="mb-2"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  Agregar Curso Capacitación
                                </v-btn>
                              </template>
                              <v-card>
                                <v-card-title>
                                  <span class="text-h5"
                                    >Agregar Curso Capacitación</span
                                  >
                                </v-card-title>

                                <v-card-text>
                                  <v-container>
                                    <v-row>
                                      <v-col cols="12" sm="6" md="4">
                                        <v-select
                                          :items="cursos"
                                          name="selectCurso"
                                          :rules="requerido"
                                          v-model="capacitacion.curso"
                                          item-text="nombre"
                                          item-value="id"
                                          label="Curso"
                                          return-object
                                        ></v-select>
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col cols="12" sm="6" md="4">
                                        <v-menu
                                          v-model="menuCursoFechaInicio"
                                          :close-on-content-click="false"
                                          :nudge-right="40"
                                          transition="scale-transition"
                                          offset-y
                                          min-width="auto"
                                        >
                                          <template
                                            v-slot:activator="{ on, attrs }"
                                          >
                                            <v-text-field
                                              v-model="dateInicioCurso"
                                              label="Fecha inicio curso"
                                              prepend-icon="mdi-calendar"
                                              name="fecha_inicio_curso"
                                              :rules="requerido"
                                              readonly
                                              v-bind="attrs"
                                              v-on="on"
                                            ></v-text-field>
                                          </template>
                                          <v-date-picker
                                            v-model="dateInicioCurso"
                                            @input="
                                              menuCursoFechaInicio = false
                                            "
                                            :max="
                                              new Date(
                                                Date.now() -
                                                  new Date().getTimezoneOffset() *
                                                    60000
                                              )
                                                .toISOString()
                                                .substr(0, 10)
                                            "
                                          ></v-date-picker>
                                        </v-menu>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="4">
                                        <v-menu
                                          v-model="menuCursoFechaFin"
                                          :close-on-content-click="false"
                                          :nudge-right="40"
                                          transition="scale-transition"
                                          offset-y
                                          min-width="auto"
                                        >
                                          <template
                                            v-slot:activator="{ on, attrs }"
                                          >
                                            <v-text-field
                                              v-model="dateFinCurso"
                                              label="Fecha fin curso"
                                              prepend-icon="mdi-calendar"
                                              name="fecha_fin_curso"
                                              :rules="requerido"
                                              readonly
                                              v-bind="attrs"
                                              v-on="on"
                                            ></v-text-field>
                                          </template>
                                          <v-date-picker
                                            v-model="dateFinCurso"
                                            @input="menuCursoFechaFin = false"
                                          ></v-date-picker>
                                        </v-menu>
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col cols="12" sm="12" md="12">
                                        <v-text-field
                                          name="inputCursoTotalHoras"
                                          label="Total Horas"
                                          v-model="capacitacion.total_horas"
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="12" sm="12" md="12">
                                        <v-text-field
                                          name="inputCursoNombreCapacitador"
                                          label="Nombre Capacitador"
                                          v-model="
                                            capacitacion.nombre_capacitador
                                          "
                                        ></v-text-field>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-card-text>

                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="closeCapacitacion"
                                  >
                                    Cancelar
                                  </v-btn>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="saveCapacitacion"
                                  >
                                    Aceptar
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                            <v-dialog
                              v-model="dialogDeleteCapacitacion"
                              max-width="500px"
                            >
                              <v-card>
                                <v-card-title class="text-h5"
                                  >¿Borrar Capacitacion?</v-card-title
                                >
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="closeDeleteCapacitacion"
                                    >Cancel</v-btn
                                  >
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="deleteItemConfirmCapacitacion"
                                    >OK</v-btn
                                  >
                                  <v-spacer></v-spacer>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </v-card-title>
                          <v-data-table
                            :headers="capacitacionHeaders"
                            :items="capacitaciones"
                            hide-default-footer
                          >
                            <template v-slot:item.actions="{ item }">
                              <v-icon
                                small
                                @click="deleteItemCapacitacion(item)"
                              >
                                mdi-delete
                              </v-icon>
                            </template>
                          </v-data-table>
                          <v-snackbar
                            v-model="snack"
                            :timeout="3000"
                            :color="snackColor"
                          >
                            {{ snackText }}

                            <template v-slot:action="{ attrs }">
                              <v-btn v-bind="attrs" text @click="snack = false">
                                Cerrar
                              </v-btn>
                            </template>
                          </v-snackbar>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item id="tab-12" eager>
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-card>
                          <v-card-title>
                            EQUIPAMIENTO
                            <v-spacer></v-spacer>
                            <v-dialog
                              v-model="dialogEquipamiento"
                              max-width="800px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="primary"
                                  dark
                                  class="mb-2"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  Agregar Equipamiento
                                </v-btn>
                              </template>
                              <v-card>
                                <v-card-title>
                                  <span class="text-h5"
                                    >Agregar Equipamiento</span
                                  >
                                </v-card-title>

                                <v-card-text>
                                  <v-container>
                                    <v-row>
                                      <v-col cols="12" sm="6" md="4">
                                        <v-select
                                          :items="tipos_equipamiento"
                                          name="selectTipoEquipamiento"
                                          :rules="requerido"
                                          v-model="equipamiento.tipo"
                                          item-text="nombre"
                                          item-value="id"
                                          label="Tipo Equipamiento"
                                          return-object
                                        ></v-select>
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col cols="12" sm="6" md="4">
                                        <v-menu
                                          v-model="menuEquipamientoFechaEntrega"
                                          :close-on-content-click="false"
                                          :nudge-right="40"
                                          transition="scale-transition"
                                          offset-y
                                          min-width="auto"
                                        >
                                          <template
                                            v-slot:activator="{ on, attrs }"
                                          >
                                            <v-text-field
                                              v-model="
                                                dateEquipamientoFechaEntrega
                                              "
                                              label="Fecha de entrega"
                                              prepend-icon="mdi-calendar"
                                              name="equipamiento_fecha_entrega"
                                              :rules="requerido"
                                              readonly
                                              v-bind="attrs"
                                              v-on="on"
                                            ></v-text-field>
                                          </template>
                                          <v-date-picker
                                            v-model="
                                              dateEquipamientoFechaEntrega
                                            "
                                            @input="
                                              menuEquipamientoFechaEntrega = false
                                            "
                                            :max="
                                              new Date(
                                                Date.now() -
                                                  new Date().getTimezoneOffset() *
                                                    60000
                                              )
                                                .toISOString()
                                                .substr(0, 10)
                                            "
                                          ></v-date-picker>
                                        </v-menu>
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col cols="12" sm="12" md="12">
                                        <v-text-field
                                          name="inputEquipamientoNumeroSerie"
                                          label="Número de Serie"
                                          v-model="equipamiento.numero_serie"
                                        ></v-text-field>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-card-text>

                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="closeEquipamiento"
                                  >
                                    Cancelar
                                  </v-btn>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="saveEquipamiento"
                                  >
                                    Aceptar
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                            <v-dialog
                              v-model="dialogDeleteEquipamiento"
                              max-width="500px"
                            >
                              <v-card>
                                <v-card-title class="text-h5"
                                  >¿Borrar Equipamiento?</v-card-title
                                >
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="closeDeleteEquipamiento"
                                    >Cancel</v-btn
                                  >
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="deleteItemConfirmEquipamiento"
                                    >OK</v-btn
                                  >
                                  <v-spacer></v-spacer>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </v-card-title>
                          <v-data-table
                            :headers="equipamientoHeaders"
                            :items="equipamientos"
                            hide-default-footer
                          >
                            <template v-slot:item.actions="{ item }">
                              <v-icon
                                small
                                @click="deleteItemEquipamiento(item)"
                              >
                                mdi-delete
                              </v-icon>
                            </template>
                          </v-data-table>
                          <v-snackbar
                            v-model="snack"
                            :timeout="3000"
                            :color="snackColor"
                          >
                            {{ snackText }}

                            <template v-slot:action="{ attrs }">
                              <v-btn v-bind="attrs" text @click="snack = false">
                                Cerrar
                              </v-btn>
                            </template>
                          </v-snackbar>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item id="tab-13" eager>
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-card>
                          <v-card-title>
                            UNIFORMES
                            <v-spacer></v-spacer>
                            <v-dialog
                              v-model="dialogUniforme"
                              max-width="800px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="primary"
                                  dark
                                  class="mb-2"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  Agregar Uniforme
                                </v-btn>
                              </template>
                              <v-card>
                                <v-card-title>
                                  <span class="text-h5">Agregar Uniforme</span>
                                </v-card-title>

                                <v-card-text>
                                  <v-container>
                                    <v-row>
                                      <v-col cols="12" sm="12" md="12">
                                        <v-select
                                          :items="tipos_uniforme"
                                          name="selectTipoUniforme"
                                          :rules="requerido"
                                          v-model="uniforme.tipo"
                                          item-text="nombre"
                                          item-value="id"
                                          label="Tipo Uniforme"
                                          return-object
                                        ></v-select>
                                      </v-col>
                                      <v-col cols="12" sm="12" md="12">
                                        <v-text-field
                                          name="inputUniformeCantidad"
                                          label="Cantidad"
                                          v-model="uniforme.cantidad"
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="12" sm="12" md="12">
                                        <v-select
                                          :items="tallas"
                                          name="selectTallas"
                                          :rules="requerido"
                                          v-model="uniforme.talla"
                                          item-text="nombre"
                                          item-value="id"
                                          label="Talla"
                                          return-object
                                        ></v-select>
                                      </v-col>
                                      <v-col cols="12" sm="12" md="12">
                                        <v-text-field
                                          name="inputUniformeColor"
                                          label="Color"
                                          v-model="uniforme.color"
                                        ></v-text-field>
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col cols="12" sm="6" md="4">
                                        <v-menu
                                          v-model="menuUniformeFechaEntrega"
                                          :close-on-content-click="false"
                                          :nudge-right="40"
                                          transition="scale-transition"
                                          offset-y
                                          min-width="auto"
                                        >
                                          <template
                                            v-slot:activator="{ on, attrs }"
                                          >
                                            <v-text-field
                                              v-model="dateUniformeFechaEntrega"
                                              label="Fecha de entrega"
                                              prepend-icon="mdi-calendar"
                                              name="equipamiento_fecha_entrega"
                                              :rules="requerido"
                                              readonly
                                              v-bind="attrs"
                                              v-on="on"
                                            ></v-text-field>
                                          </template>
                                          <v-date-picker
                                            v-model="dateUniformeFechaEntrega"
                                            @input="
                                              menuUniformeFechaEntrega = false
                                            "
                                            :max="
                                              new Date(
                                                Date.now() -
                                                  new Date().getTimezoneOffset() *
                                                    60000
                                              )
                                                .toISOString()
                                                .substr(0, 10)
                                            "
                                          ></v-date-picker>
                                        </v-menu>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-card-text>

                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="closeUniforme"
                                  >
                                    Cancelar
                                  </v-btn>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="saveUniforme"
                                  >
                                    Aceptar
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                            <v-dialog
                              v-model="dialogDeleteUniforme"
                              max-width="500px"
                            >
                              <v-card>
                                <v-card-title class="text-h5"
                                  >¿Borrar Uniforme?</v-card-title
                                >
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="closeDeleteUniforme"
                                    >Cancel</v-btn
                                  >
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="deleteItemConfirmUniforme"
                                    >OK</v-btn
                                  >
                                  <v-spacer></v-spacer>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </v-card-title>
                          <v-data-table
                            :headers="uniformeHeaders"
                            :items="uniformes"
                            hide-default-footer
                          >
                            <template v-slot:item.actions="{ item }">
                              <v-icon small @click="deleteItemUniforme(item)">
                                mdi-delete
                              </v-icon>
                            </template>
                          </v-data-table>
                          <v-snackbar
                            v-model="snack"
                            :timeout="3000"
                            :color="snackColor"
                          >
                            {{ snackText }}

                            <template v-slot:action="{ attrs }">
                              <v-btn v-bind="attrs" text @click="snack = false">
                                Cerrar
                              </v-btn>
                            </template>
                          </v-snackbar>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-container>
      </v-card-text>
    </v-container>
  </v-app>
</template>
<script>
export default {
  data: () => ({
    urlPrefix: "https://dev.checkinseguridad.com",
    employee: [],
    dialog: false,
    dialogDocumentosContratacion: false,
    dialogCapacitacion: false,
    tab: null,
    valid: false,
    empresas: [],
    servicios: [],
    serviciosArmas: [],
    lugares: [],
    lugaresArmas: [],
    sexo: [],
    puestos: [],
    estadoCivil: [],
    nacionalidad: [],
    escolaridad: [],
    tipoSangre: [],
    estados: [],
    ciudades: [],
    statusArmas: [],
    tipoArmas: [],
    claseArmas: [],
    marcaArmas: [],
    calibreArmas: [],
    modeloArmas: [],
    modalidadArmas: [],
    diasPago: [],

    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    dateImss: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    dateInicioIncapacidad: new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .substr(0, 10),
    dateFinIncapacidad: new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .substr(0, 10),
    dateRecepcionServicio: new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .substr(0, 10),
    dateComision: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    dateInicioComoArmado: new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .substr(0, 10),
    dateInicioVigencia: new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .substr(0, 10),
    dateTerminoVigencia: new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .substr(0, 10),
    dateEnvioAntecedentesPoliciales: new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .substr(0, 10),
    dateAcuseAntecedentesPoliciales: new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .substr(0, 10),
    dateInscripcion: new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .substr(0, 10),
    dateInicioCurso: new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .substr(0, 10),
    dateFinCurso: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    dateEquipamientoFechaEntrega: new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .substr(0, 10),
    dateUniformeFechaEntrega: new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .substr(0, 10),

    bancos: [],
    ciudadesNacimiento: [],
    tipoIncapacidad: [],
    tipoDocumentos: [],
    incapacidad: {
      tipoIncapacidad: {
        id: 0,
        nombre: "",
      },
      motivo: "",
      dateInicioIncapacidad: "",
      dateFinIncapacidad: "",
      incapacidadFile: "",
    },
    documento: {
      tipoDocumento: {
        id: 0,
        nombre: "",
      },
      comentarios: "",
      urlDocumento: "",
    },
    cursos: [],
    capacitacion: {
      curso: {
        id: 0,
        nombre: "",
      },
      dateInicioCapacitacion: "",
      dateFinCapacitacion: "",
      total_horas: "",
      nombre_capacitador: "",
    },

    lugarSelected: {},
    puestoSelected: {},
    lugar: null,
    tipoServicio: null,
    menuFechaAltaSeguro: false,
    menuIncapacidadFechaInicio: false,
    menuIncapacidadFechaFin: false,
    menuDateInscripcion: false,
    menuFechaIngreso: false,
    menuDateRecepcionServicio: false,
    menuDateComision: false,
    menuDateInicioComoArmado: false,
    menuDateInicioVigencia: false,
    menuDateTerminoVigencia: false,
    menuDateEnvioAntecedentesPoliciales: false,
    menuDateAcuseAntecedentesPoliciales: false,
    menuCursoFechaInicio: false,
    menuCursoFechaFin: false,

    editedIndex: -1,
    editedIncapacidad: {
      tipoIncapacidad: {
        id: 0,
        nombre: "",
      },
      motivo: "",
    },
    incapacidades: [],
    defaultIncapacidad: {
      tipoIncapacidad: {
        id: 0,
        nombre: "",
      },
      motivo: "",
      dateInicioIncapacidad: new Date(Date.now()),
      dateFinIncapacidad: new Date(Date.now()),
      incapacidadFile: "",
    },

    editedIndexDocumento: -1,
    editedDocumento: {
      tipoDocumento: {
        id: 0,
        nombre: "",
      },
      comentarios: "",
      urlDocumento: "",
    },
    documentos: [],
    defaultDocumento: {
      tipoDocumento: {
        id: 0,
        nombre: "",
      },
      comentarios: "",
      urlDocumento: "",
    },
    capacitaciones: [],
    editedIndexCapacitacion: -1,
    editedCapacitacion: {
      curso: {
        id: 0,
        nombre: "",
      },
      dateInicioCapacitacion: new Date(Date.now()),
      dateFinCapacitacion: new Date(Date.now()),
      total_horas: "",
      nombre_capacitador: "",
    },
    defaultCapacitacion: {
      curso: {
        id: 0,
        nombre: "",
      },
      dateInicioCapacitacion: new Date(Date.now()),
      dateFinCapacitacion: new Date(Date.now()),
      total_horas: "",
      nombre_capacitador: "",
    },

    dialogDelete: false,
    dialogDeleteDocumento: false,
    dialogDeleteCapacitacion: false,
    cuentaConCredencial: false,
    snack: false,
    snackColor: "",
    snackText: "",
    requerido: [(v) => !!v || "* requerido"],
    curpRules: [
      (v) => !!v || "* requerido",
      (v) => (v && v.length == 18) || "La CURP es de 18 carácteres",
    ],
    rfcRules: [
      (v) => !!v || "* requerido",
      (v) =>
        (v && v.length >= 12 && v.length <= 13) ||
        "El RFC es de 12 o 13 carácteres",
    ],
    incapacidadHeaders: [
      {
        text: "Incapacidad",
        align: "start",
        value: "tipoIncapacidad.nombre",
      },
      { text: "Fecha Inicio", value: "dateInicioIncapacidad" },
      { text: "Fecha Término", value: "dateFinIncapacidad" },
      { text: "Motivo", value: "motivo" },
      { text: "Archivo", value: "fileIncapacidad.name" },
      { text: "", value: "actions", sortable: false },
    ],
    documentoHeaders: [
      {
        text: "Documento",
        align: "start",
        value: "tipoDocumento.nombre",
      },
      { text: "Comentarios", value: "comentarios" },
      { text: "Archivo", value: "file_url.name" },
      { text: "", value: "actions", sortable: false },
    ],
    capacitacionHeaders: [
      {
        text: "Curso",
        align: "start",
        value: "curso.nombre",
      },
      { text: "Fecha Inicio", value: "dateInicioCurso" },
      { text: "Fecha Término", value: "dateFinCurso" },
      { text: "Total horas", value: "total_horas" },
      { text: "Capacitador", value: "nombre_capacitador" },
      { text: "", value: "actions", sortable: false },
    ],
    tipos_equipamiento: [],
    dialogEquipamiento: false,
    equipamientos: [],
    equipamiento: {
      tipo: {
        id: 0,
        nombre: "",
      },
      dateEntregaEquipamiento: "",
      numero_serie: "",
    },
    menuEquipamientoFechaEntrega: false,
    defaultEquipamiento: {
      tipo: {
        id: 0,
        nombre: "",
      },
      dateEntregaEquipamiento: new Date(Date.now()),
      numero_serie: "",
    },
    editedIndexEquipamiento: -1,
    dialogDeleteEquipamiento: false,
    editedEquipamiento: {
      tipo: {
        id: 0,
        nombre: "",
      },
      dateEntregaEquipamiento: new Date(Date.now()),
      numero_serie: "",
    },
    equipamientoHeaders: [
      {
        text: "Tipo",
        align: "start",
        value: "tipo.nombre",
      },
      { text: "Fecha Entrega", value: "dateEntregaEquipamiento" },
      { text: "Número Serie", value: "numero_serie" },
      { text: "", value: "actions", sortable: false },
    ],
    dialogUniforme: false,
    tipos_uniforme: [],
    tallas: [],
    uniforme: {
      tipo: {
        id: 0,
        nombre: "",
      },
      cantidad: "",
      talla: {
        id: 0,
        nombre: "",
      },
      color: "",
      dateEntregaUniforme: "",
    },
    menuUniformeFechaEntrega: false,
    defaultUniforme: {
      tipo: {
        id: 0,
        nombre: "",
      },
      cantidad: "",
      talla: {
        id: 0,
        nombre: "",
      },
      color: "",
      dateEntregaUniforme: "",
    },
    editedIndexUniforme: -1,
    uniformes: [],
    dialogDeleteUniforme: false,
    uniformeHeaders: [
      {
        text: "Tipo",
        align: "start",
        value: "tipo.nombre",
      },
      { text: "Cantidad", value: "cantidad" },
      {
        text: "Talla",
        align: "start",
        value: "talla.nombre",
      },
      { text: "Color", value: "color" },
      { text: "Fecha Entrega", value: "dateEntregaUniforme" },
      { text: "", value: "actions", sortable: false },
    ],
    json: null,
  }),
  mounted() {
    this.fetchEmpresas();
    this.fetchPuestos();
    this.fetchSexo();
    this.fetchEstadoCivil();
    this.fetchNacionalidad();
    this.fetchEscolaridad();
    this.fetchTipoSangre();
    this.fetchEstados();
    this.fetchBancos();
    this.fetchTipoIncapacidad();
    this.fetchStatusArma();
    this.fetchTipoArma();
    this.fetchClaseArma();
    this.fetchMarcaArma();
    this.fetchCalibreArma();
    this.fetchModeloArma();
    this.fetchModalidadArma();
    this.fetchDiasPago();
    this.fetchTipoDocumento();
    this.fetchCursos();
    this.fetchTipoEquipamientos();
    this.fetchTiposUniforme();
    this.fetchTallas();
  },
  computed: {
    fechaNacimiento: function () {
      if (this.employee.CURP) {
        if (this.employee.CURP.length >= 8) {
          if (
            !isNaN(this.employee.CURP.substr(4, 2)) &&
            !isNaN(this.employee.CURP.substr(6, 2)) &&
            !isNaN(this.employee.CURP.substr(8, 2))
          ) {
            let anio = 0;
            const d = new Date();
            let year = d.getFullYear();
            if (
              this.employee.CURP.substr(4, 2) > year.toString().substr(2, 2)
            ) {
              anio = "19" + this.employee.CURP.substr(4, 2);
            } else {
              anio = "20" + this.employee.CURP.substr(4, 2);
            }
            return new Date(
              anio,
              this.employee.CURP.substr(6, 2) - 1,
              this.employee.CURP.substr(8, 2)
            )
              .toISOString()
              .split("T")[0];
          }
        }
      }
      return null;
    },
    edad: function () {
      let edad = 0;
      const d = new Date();
      if (this.fechaNacimiento) {
        const anioNacimiento = new Date(this.fechaNacimiento).getFullYear();
        edad = anioNacimiento - d.getFullYear();
      }
      const mesNacimiento = new Date(this.fechaNacimiento).getMonth();
      if (mesNacimiento > d.getMonth()) {
        edad += 1;
      }
      return edad * -1;
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogDocumentosContratacion(val) {
      val || this.closeDocumento();
    },
    dialogDeleteDocumento(val) {
      val || this.closeDeleteDocumento();
    },
  },
  methods: {
    guardar(e) {
      this.json = e;
    },

    validate(tipoDocumento) {
      if (this.json != null) {
        return this.enviar(tipoDocumento);
      }
    },

    async enviar(tipoDocumento) {
      let url = `${this.urlPrefix}/api/archivo/store/${tipoDocumento}`;
      let data = new FormData();
      data.append("file", this.json);
      this.$axios
        .post(url, data, {
          headers: {
            accept: "application/pdf",
            "Accept-Language": "en-US,en;q=0.8",
            "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
          },
        })
        .then(() => {
          return true;
        })
        .catch((errors) => {
          console.error(errors.response.data);
          return false;
        });
    },

    fetchEmpresas() {
      this.loading = true;
      this.items = [];

      let url = `${this.urlPrefix}/api/empresas/all`;
      if (this.page > 1) {
        url = `${url}&page=${this.page}`;
      }
      this.$axios
        .get(url)
        .then((response) => {
          this.empresas = response.data.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          console.error("Error fetching empresas.");
        });
    },

    fetchServicios() {
      this.loading = true;
      let url = `${this.urlPrefix}/api/tipoServicio/byEmpresa/${this.employee.empresaId}`;
      if (this.page > 1) {
        url = `${url}&page=${this.page}`;
      }
      this.$axios
        .get(url)
        .then((response) => {
          console.log(response);
          this.servicios = response.data.data;
          this.serviciosArmas = response.data.data;
        })
        .catch(() => {
          console.error("Error fetching servicios.");
          this.loading = false;
        });
    },

    fetchInmuebles() {
      this.lugar = null;
      this.loading = true;

      let url = `${this.urlPrefix}/api/lugares/byServicio/${this.employee.servicioId}`;
      if (this.page > 1) {
        url = `${url}&page=${this.page}`;
      }
      this.$http
        .get(url)
        .then((response) => {
          this.lugares = response.data.data;

          this.meta = {
            current_page: response.data.current_page,
            last_page: response.data.last_page,
            per_page: response.data.per_page,
          };
          this.total_items = response.data.total;
          this.loading = false;
        })
        .catch(() => {
          console.error("Error fetching inmuebles.");
          this.loading = false;
        });
    },

    fetchInmueblesArmas() {
      this.loading = true;

      let url = `${this.urlPrefix}/api/lugares/byServicio/${this.employee.armaServicio}`;
      if (this.page > 1) {
        url = `${url}&page=${this.page}`;
      }
      this.$http
        .get(url)
        .then((response) => {
          this.lugaresArmas = response.data.data;

          this.meta = {
            current_page: response.data.current_page,
            last_page: response.data.last_page,
            per_page: response.data.per_page,
          };
          this.total_items = response.data.total;
          this.loading = false;
        })
        .catch(() => {
          console.error("Error fetching inmuebles.");
          this.loading = false;
        });
    },

    fetchPuestos() {
      let url = `${this.urlPrefix}/api/puestos/all`;
      this.$axios
        .get(url)
        .then((response) => {
          this.puestos = [];
          this.puestos = response.data.data;
        })
        .catch(() => {
          console.error("Error fetching puestos.");
          this.loading = false;
        });
    },

    fetchSexo() {
      let url = `${this.urlPrefix}/api/sexo/all`;
      this.$axios
        .get(url)
        .then((response) => {
          this.sexo = [];
          this.sexo = response.data.data;
        })
        .catch(() => {
          console.error("Error fetching sexo.");
          this.loading = false;
        });
    },

    fetchEstadoCivil() {
      let url = `${this.urlPrefix}/api/estadocivil/all`;
      this.$axios
        .get(url)
        .then((response) => {
          this.estadoCivil = [];
          this.estadoCivil = response.data.data;
        })
        .catch(() => {
          console.error("Error fetching estados civiles.");
          this.loading = false;
        });
    },

    fetchNacionalidad() {
      let url = `${this.urlPrefix}/api/nacionalidad/all`;
      this.$axios
        .get(url)
        .then((response) => {
          this.nacionalidad = [];
          this.nacionalidad = response.data.data;
        })
        .catch(() => {
          console.error("Error fetching nacionalidades.");
          this.loading = false;
        });
    },

    fetchEscolaridad() {
      let url = `${this.urlPrefix}/api/escolaridad/all`;
      this.$axios
        .get(url)
        .then((response) => {
          this.escolaridad = [];
          this.escolaridad = response.data.data;
        })
        .catch(() => {
          console.error("Error fetching escolaridades.");
          this.loading = false;
        });
    },

    fetchTipoSangre() {
      let url = `${this.urlPrefix}/api/tiposangre/all`;
      this.$axios
        .get(url)
        .then((response) => {
          this.tipoSangre = [];
          this.tipoSangre = response.data.data;
        })
        .catch(() => {
          console.error("Error fetching tipos de sangre.");
          this.loading = false;
        });
    },

    fetchEstados() {
      let url = `${this.urlPrefix}/api/estado/all`;
      this.$axios
        .get(url)
        .then((response) => {
          this.estados = [];
          this.estados = response.data.data;
        })
        .catch(() => {
          console.error("Error fetching estados.");
          this.loading = false;
        });
    },

    fetchCiudades() {
      let url = `${this.urlPrefix}/api/ciudad/byestado/${this.employee.estadoId}`;
      this.$axios
        .get(url)
        .then((response) => {
          this.ciudades = [];
          this.ciudades = response.data.data;
        })
        .catch(() => {
          console.error("Error fetching ciudades.");
          this.loading = false;
        });
    },

    fetchCiudadesNacimiento() {
      let url = `${this.urlPrefix}/api/ciudad/byestado/${this.employee.estadoNacimientoId}`;
      this.$axios
        .get(url)
        .then((response) => {
          this.ciudadesNacimiento = [];
          this.ciudadesNacimiento = response.data.data;
        })
        .catch(() => {
          console.error("Error fetching ciudades.");
          this.loading = false;
        });
    },

    fetchBancos() {
      let url = `${this.urlPrefix}/api/bancos/all`;
      this.$axios
        .get(url)
        .then((response) => {
          this.bancos = [];
          this.bancos = response.data.data;
        })
        .catch(() => {
          console.error("Error fetching ciudades.");
          this.loading = false;
        });
    },

    fetchDiasPago() {
      let url = `${this.urlPrefix}/api/diaspago/all`;
      this.$axios
        .get(url)
        .then((response) => {
          this.diasPago = [];
          this.diasPago = response.data.data;
        })
        .catch(() => {
          console.error("Error fetching días de pago.");
          this.loading = false;
        });
    },

    fetchTipoDocumento() {
      let url = `${this.urlPrefix}/api/tipodocumento/all`;
      this.$axios
        .get(url)
        .then((response) => {
          this.tipoDocumentos = [];
          this.tipoDocumentos = response.data.data;
        })
        .catch(() => {
          console.error("Error fetching tipos de documentos.");
          this.loading = false;
        });
    },

    fetchCursos() {
      let url = `${this.urlPrefix}/api/curso/all`;
      this.$axios
        .get(url)
        .then((response) => {
          this.cursos = [];
          this.cursos = response.data.data;
        })
        .catch(() => {
          console.error("Error fetching cursos.");
          this.loading = false;
        });
    },

    fetchTipoEquipamientos() {
      let url = `${this.urlPrefix}/api/tipoequipamiento/all`;
      this.$axios
        .get(url)
        .then((response) => {
          this.tipos_equipamiento = [];
          this.tipos_equipamiento = response.data.data;
        })
        .catch(() => {
          console.error("Error fetching tipo equipamientos.");
          this.loading = false;
        });
    },

    fetchTiposUniforme() {
      let url = `${this.urlPrefix}/api/tipouniforme/all`;
      this.$axios
        .get(url)
        .then((response) => {
          this.tipos_uniforme = [];
          this.tipos_uniforme = response.data.data;
        })
        .catch(() => {
          console.error("Error fetching tipos de uniforme.");
          this.loading = false;
        });
    },

    fetchTallas() {
      let url = `${this.urlPrefix}/api/tallas/all`;
      this.$axios
        .get(url)
        .then((response) => {
          this.tallas = [];
          this.tallas = response.data.data;
        })
        .catch(() => {
          console.error("Error fetching tallas.");
          this.loading = false;
        });
    },

    save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      let url = `${this.urlPrefix}/api/empleados`;
      this.loading = true;
      this.$axios
        .post(url, {
          nombre: this.employee.nombre,
          apellido_paterno: this.employee.apellido_paterno,
          apellido_materno: this.employee.apellido_materno,
          fecha_alta: this.date,
          CURP: this.employee.CURP,
          RFC: this.employee.RFC,
          numero_cartilla: this.employee.numero_cartilla,
          clase_cartilla: this.employee.clase_cartilla,
          fecha_baja: null,
          puesto_id: this.employee.puestoId,
          tel: this.employee.tel,
          lugar_id: this.employee.lugarId, //Inmueble del cliente
          calle: this.employee.calle,
          numero: this.employee.numero,
          colonia: this.employee.colonia,
          ciudad_id: this.employee.ciudad_id,
          cp: this.employee.codigo_postal,
          entre_calle: this.employee.entre_calle,
          y_calle: this.employee.y_calle,
          NSS: this.employee.NSS,
          fecha_alta_imss: this.dateImss,
          incapacidades: this.incapacidades,
          numero_credito_infonavit: this.employee.numero_credito_infonavit, //new
          descuento_infonavit: this.employee.descuento_infonavit, //new
          numero_credito_fonacot: this.employee.numero_credito_fonacot, //new
          descuento_fonacot: this.employee.descuento_fonacot, //new
          banco_id: this.employee.bancoId,
          numero_cuenta: this.employee.numero_cuenta, //new
          clabe: this.employee.clabe, //new
          numero_tarjeta: this.employee.numero_tarjeta, //new
          lugarnacimiento_id: this.employee.ciudadNacimiento_id, //new
          sexo_id: this.employee.sexoId,
          estadocivil_id: this.employee.estadoCivilId,
          nacionalidad_id: this.employee.nacionalidadId,
          escolaridad_id: this.employee.escolaridadId,
          fechaNacimiento: this.employee.fechaNacimiento,
          ciudadNacimientoId: this.employee.ciudadNacimiento_id,
          diaPago: this.employee.diaPago,
          sueldoMensual: this.employee.sueldo_mensual,
          sueldoDiario: this.employee.sueldo_diario,
          diaDescanso: this.employee.dia_descanso,
          descuentoFaltaInjustificada:
            this.employee.descuento_falta_injustificada,
          //TODO: vacaciones
          fechaRecepcionArmaServicio: this.dateRecepcionServicio,
          statusArma: this.employee.estatusArma,
          tipoArma: this.employee.tipoArma,
          claseArma: this.employee.claseArma,
          marcaArma: this.employee.marcaArma,
          calibreArma: this.employee.calibreArma,
          modeloArma: this.employee.modeloArma,
          matriculaArma: this.employee.matriculaArma,
          urlArmaFolioHojaRosa: this.employee.armaFolioHojaRosa.name,
          armaNumeroDeOrden: this.employee.armaNumeroDeOrden,
          responsableDelArma: this.employee.armaResponsable,
          armaNumeroOficioComision: this.employee.armaNumeroOficioComision,
          armaFechaComision: this.dateComision,
          cuentaConCredencialOPortacion: this.cuentaConCredencial,
          fechaInicioComoArmado: this.dateInicioComoArmado,
          fechaInicioVigencia: this.dateInicioVigencia,
          fechaTerminoVigencia: this.dateTerminoVigencia,
          modalidadArma: this.employee.modalidadArmas,
          armaServicioId: this.employee.armaServicio,
          armaLugarId: this.employee.armaLugar,
          armaDireccionResguardo: this.employee.armaDireccionResguardo,
          dateEnvioConsultaAntecedentesPoliciales:
            this.dateEnvioAntecedentesPoliciales,
          dateAcuseConsultaAntecedentesPoliciales:
            this.dateAcuseAntecedentesPoliciales,
          urlNumeroVolanteConsultaAntecedentesPoliciales:
            this.employee.numero_volante_consulta_antecedentes_policiales.name,
          dateInscripcion: this.dateInscripcion,
          urlNumeroVolanteInscripcion:
            this.employee.numero_volante_inscripcion.name,
          cuip: this.employee.cuip,
          urlFolioCipPortable: this.employee.folio_cip_portable.name,
          urlCipFisica: this.employee.cip_fisica.name,
          contactoDeEmergencia: this.employee.nombre_contacto_emergencia,
          telefonoContactoDeEmergencia:
            this.employee.telefono_contacto_emergencia,
          padecimiento: this.employee.padecimiento_grave,
          tiposangre_id: this.employee.tipoSangreId,
          estatura: this.employee.estatura,
          peso: this.employee.peso,
          documentosContratacion: this.documentos,
          cursosCapacitacion: this.capacitaciones,
          equipamiento: this.equipamientos,
          uniformes: this.uniformes,
        })
        .then((response) => {
          if (response.data.success) {
            this.editedItem = [];
            this.$router.push({ name: "empleados" });
          } else {
            alert(JSON.stringify(response.data.data));
          }
        })
        .catch((errors) => {
          alert(errors.message);
        });

      this.loading = false;
    },

    getLugaresParaAgregar: function name() {
      let self = this;
      let url = `/api/lugares/byEmpresa/${this.employee.empresaId}/${this.employee.tipoServicioId}`;
      if (self.page > 1) {
        url = `${url}&page=${self.page}`;
      }
      this.$http
        .get(url)
        .then((response) => {
          self.lugares = response.data.data;
          self.meta = {
            current_page: response.data.current_page,
            last_page: response.data.last_page,
            per_page: response.data.per_page,
          };
          (self.total_items = response.data.total), (self.loading = false);
        })
        .catch(() => {});
      this.asignarTurno();
    },

    verAsistencias(item) {
      this.$router.push({
        name: "record",
        params: { id: item.id, nombre: item.nombre },
      });
    },

    fetchTipoIncapacidad() {
      let url = `${this.urlPrefix}/api/tipoincapacidad/all`;
      this.$axios
        .get(url)
        .then((response) => {
          this.tipoIncapacidad = [];
          this.tipoIncapacidad = response.data.data;
        })
        .catch(() => {
          console.error("Error fetching tipos de incapacidad.");
          this.loading = false;
        });
    },

    fetchStatusArma() {
      let url = `${this.urlPrefix}/api/statusarma/all`;
      this.$axios
        .get(url)
        .then((response) => {
          this.statusArmas = [];
          this.statusArmas = response.data.data;
        })
        .catch(() => {
          console.error("Error fetching status arma.");
          this.loading = false;
        });
    },

    fetchTipoArma() {
      let url = `${this.urlPrefix}/api/tipoarma/all`;
      this.$axios
        .get(url)
        .then((response) => {
          this.tipoArmas = [];
          this.tipoArmas = response.data.data;
        })
        .catch(() => {
          console.error("Error fetching tipos de armas.");
          this.loading = false;
        });
    },

    fetchClaseArma() {
      let url = `${this.urlPrefix}/api/clasearma/all`;
      this.$axios
        .get(url)
        .then((response) => {
          this.claseArmas = [];
          this.claseArmas = response.data.data;
        })
        .catch(() => {
          console.error("Error fetching clases de armas.");
          this.loading = false;
        });
    },

    fetchMarcaArma() {
      let url = `${this.urlPrefix}/api/marcaarma/all`;
      this.$axios
        .get(url)
        .then((response) => {
          this.marcaArmas = [];
          this.marcaArmas = response.data.data;
        })
        .catch(() => {
          console.error("Error fetching marcas de armas.");
          this.loading = false;
        });
    },

    fetchCalibreArma() {
      let url = `${this.urlPrefix}/api/calibrearma/all`;
      this.$axios
        .get(url)
        .then((response) => {
          this.calibreArmas = [];
          this.calibreArmas = response.data.data;
        })
        .catch(() => {
          console.error("Error fetching calibre de armas.");
          this.loading = false;
        });
    },

    fetchModeloArma() {
      let url = `${this.urlPrefix}/api/modeloarma/all`;
      this.$axios
        .get(url)
        .then((response) => {
          this.modeloArmas = [];
          this.modeloArmas = response.data.data;
        })
        .catch(() => {
          console.error("Error fetching modelos de armas.");
          this.loading = false;
        });
    },

    fetchModalidadArma() {
      let url = `${this.urlPrefix}/api/modalidadarma/all`;
      this.$axios
        .get(url)
        .then((response) => {
          this.modalidadArmas = [];
          this.modalidadArmas = response.data.data;
        })
        .catch(() => {
          console.error("Error fetching modalidad de armas.");
          this.loading = false;
        });
    },

    close() {
      this.incapacidad = Object.assign({}, this.defaultIncapacidad);
      this.dateInicioIncapacidad = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);
      this.dateFinIncapacidad = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);
      this.dialog = false;
      this.$nextTick(() => {
        this.editedIndex = -1;
      });
    },

    closeDocumento() {
      this.documento = Object.assign({}, this.defaultDocumento);
      this.dialogDocumentosContratacion = false;
      this.$nextTick(() => {
        this.editedIndexDocumento = -1;
      });
    },

    closeCapacitacion() {
      this.capacitacion = Object.assign({}, this.defaultCapacitacion);
      this.dialogCapacitacion = false;
      this.$nextTick(() => {
        this.editedIndexCapacitacion = -1;
      });
    },

    saveIncapacidad: function (tipoDocumento) {
      const successfulUpload = this.validate(tipoDocumento);
      if (successfulUpload) {
        this.incapacidad.dateInicioIncapacidad = this.dateInicioIncapacidad;
        this.incapacidad.dateFinIncapacidad = this.dateFinIncapacidad;
        this.incapacidad.incapacidadFile =
          this.incapacidad.fileIncapacidad.name;
        this.incapacidades.push(this.incapacidad);
        this.incapacidad = Object.assign({}, this.defaultIncapacidad);
        this.close();

        this.snack = true;
        this.snackColor = "success";
        this.snackText = "¡Incapacidad agregada!";
      } else {
        this.snack = true;
        this.snackColor = "red";
        this.snackText = "¡Error! Intente de nuevo por favor.";
      }
    },

    saveDocumento: function (tipoDocumento) {
      const successfulUpload = this.validate(tipoDocumento);
      if (successfulUpload) {
        this.documento.urlDocumento = this.documento.file_url.name;
        this.documentos.push(this.documento);
        this.documento = Object.assign({}, this.defaultDocumento);
        this.closeDocumento();

        this.snack = true;
        this.snackColor = "success";
        this.snackText = "¡Documento agregado!";
      } else {
        this.snack = true;
        this.snackColor = "red";
        this.snackText = "¡Error! Intente de nuevo por favor.";
      }
    },

    saveCapacitacion() {
      this.capacitacion.dateInicioCapacitacion = this.dateInicioCurso;
      this.capacitacion.dateFinCapacitacion = this.dateFinCurso;
      this.capacitaciones.push(this.capacitacion);
      this.capacitacion = Object.assign({}, this.defaultCapacitacion);
      this.closeCapacitacion();

      this.snack = true;
      this.snackColor = "success";
      this.snackText = "Data saved";
    },

    deleteItem(item) {
      this.editedIndex = this.incapacidades.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.incapacidades.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDeleteDocumento() {
      this.dialogDeleteDocumento = false;
      this.$nextTick(() => {
        this.editedDocumento = Object.assign({}, this.defaultDocumento);
        this.editedIndexDocumento = -1;
      });
    },

    deleteItemDocumento(item) {
      this.editedIndexDocumento = this.documentos.indexOf(item);
      this.deleteItemDocumento = Object.assign({}, item);
      this.dialogDeleteDocumento = true;
    },

    deleteItemConfirmDocumento() {
      this.documentos.splice(this.editedIndexDocumento, 1);
      this.closeDeleteDocumento();
    },

    deleteItemCapacitacion(item) {
      this.editedIndexCapacitacion = this.capacitaciones.indexOf(item);
      this.editedCapacitacion = Object.assign({}, item);
      this.dialogDeleteCapacitacion = true;
    },

    deleteItemConfirmCapacitacion() {
      this.capacitaciones.splice(this.editedCapacitacion, 1);
      this.closeDelete();
    },

    closeDeleteCapacitacion() {
      this.dialogDeleteCapacitacion = false;
      this.$nextTick(() => {
        this.editedCapacitacion = Object.assign({}, this.defaultCapacitacion);
        this.editedIndexCapacitacion = -1;
      });
    },

    closeEquipamiento() {
      this.equipamiento = Object.assign({}, this.defaultEquipamiento);
      this.dialogEquipamiento = false;
      this.$nextTick(() => {
        this.editedIndexEquipamiento = -1;
      });
    },

    closeDeleteEquipamiento() {
      this.dialogDeleteEquipamiento = false;
      this.$nextTick(() => {
        this.editedEquipamiento = Object.assign({}, this.defaultEquipamiento);
        this.editedIndexEquipamiento = -1;
      });
    },

    deleteItemConfirmEquipamiento() {
      this.equipamientos.splice(this.editedEquipamiento, 1);
      this.closeDeleteEquipamiento();
    },

    saveEquipamiento() {
      this.equipamiento.dateEntregaEquipamiento =
        this.dateEquipamientoFechaEntrega;
      this.equipamientos.push(this.equipamiento);
      this.equipamiento = Object.assign({}, this.defaultEquipamiento);
      this.closeEquipamiento();

      this.snack = true;
      this.snackColor = "success";
      this.snackText = "Data saved";
    },

    closeUniforme() {
      this.uniforme = Object.assign({}, this.defaultUniforme);
      this.dialogUniforme = false;
      this.$nextTick(() => {
        this.editedIndexUniforme = -1;
      });
    },

    saveUniforme() {
      this.uniforme.dateEntregaUniforme = this.dateUniformeFechaEntrega;
      this.uniformes.push(this.uniforme);
      this.uniforme = Object.assign({}, this.defaultUniforme);
      this.closeUniforme();

      this.snack = true;
      this.snackColor = "success";
      this.snackText = "Uniforme guardado";
    },

    closeDeleteUniforme() {
      this.dialogDeleteUniforme = false;
      this.$nextTick(() => {
        this.editedUniforme = Object.assign({}, this.defaultUniforme);
        this.editedIndexUniforme = -1;
      });
    },

    deleteItemConfirmUniforme() {
      this.uniformes.splice(this.editedUniforme, 1);
      this.closeDeleteUniforme();
    },

    deleteItemUniforme(item) {
      this.editedIndexUniforme = this.uniformes.indexOf(item);
      this.editedUniforme = Object.assign({}, item);
      this.dialogDeleteUniforme = true;
    },
  },
};
</script>
