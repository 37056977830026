import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
// import { CHECK_AUTH } from "../store/actions.type";
// import { LOGOUT } from "../store/actions.type";

Vue.use(VueRouter)

function route(path, file, name, children, meta) {
    return {
        exact: true,
        path,
        name,
        children,
        meta,
        component: require(`../pages/${file}.vue`).default
    }
}

const router = new VueRouter({
    base: __dirname,
    /* Use Pretty URL */
    mode: "history",
    /* Save The Scroll Position , Useful When Redirecting Back */
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    },
    routes: [
        route('/404', 'NotFound', 'notfound', null),
        route('/403', 'Deny', 'accessdenied', null),
        route('/500', 'Error', 'servererror', null),
        route('/', 'Home', 'home', null),
        route('/politica', 'Politica', 'politica', null),
        route('/login', 'Login', 'login', null),
        route('/register', 'Register', 'register', null),

        route('/agregarCobertura', 'admin/AgregarCobertura', 'agregarCobertura', null, { authorize: ["admin", "supervisor"] }),

        route('/dash', 'admin/Dashboard', 'dash', null, { authorize: ["admin", "ingenieria", "administracion", "supervisor", "seguro", "tarjetas"] }),
        route('/empleados', 'admin/Empleados', 'empleados', null, { authorize: ["admin", "administracion"] }),
        route('/EditEmployee/:id', 'admin/EditEmployee', 'EditEmployee', null, { authorize: ["admin", "administracion"] }),
        route('/NewEmployee', 'admin/NewEmployee', 'NewEmployee', null, { authorize: ["admin", "administracion"] }),
        route('/pagos', 'admin/Pagos', 'pagos', null, { authorize: ["admin", "tarjetas"] }),
        route('/record', 'admin/Record', 'record', null, { authorize: ["admin"] }),
        route('/seguro', 'admin/Seguro', 'seguro', null, { authorize: ["admin", "seguro"] }),
        route('/fatiga', 'admin/Fatiga', 'fatiga', null, { authorize: ["admin", "administracion", "supervisor"] }),
        route('/historial', 'admin/Historial', 'historial', null, { authorize: ["admin"] }),
        route('/usuarios', 'admin/Usuarios', 'usuarios', null, { authorize: ["admin", "ingenieria"] }),
        route('/empresa', 'admin/Empresa', 'empresa', null, { authorize: ["admin", "administracion"] }),
        route('/lugar', 'admin/Lugar', 'lugar', null, { authorize: ["admin", "administracion"] }),
        route('/seed', 'admin/SeedEmpleados', 'seed', null, { authorize: ["admin", "ingenieria"] }),
        route('/chat', 'admin/Chat', 'chat', null, { authorize: ["admin", "ingenieria"] }),
        route('/lugaresChat', 'admin/LugaresChat', 'lugaresChat', null, { authorize: ["admin", "ingenieria"] }),
        route('/asistencias', 'admin/Asistencias', 'asistencias', null, { authorize: ["admin", "administracion"] }),
        route('/failedAsistencias', 'admin/FailedAsistencias', 'failedAsistencias', null, { authorize: ["admin"] }),

        route('/coberturas', 'admin/Coberturas', 'coberturas', null, { authorize: ["admin", "administracion", "supervisor"] }),
        route('/tipoServicio', 'admin/TipoServicio', 'tipoServicio', null, { authorize: ["admin", "administracion"] }),
        route('/seedServicios', 'admin/SeedServicios', 'seedServicios', null, { authorize: ["admin", "ingenieria"] }),
        { path: '*', redirect: '/404' }
    ]
});

router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const { authorize } = to.meta;
    //const currentUser = authenticationService.currentUserValue;
    if (authorize) {
        if (!store.getters.isAuthenticated) {
            // not logged in so redirect to login page with the return url
        }
        // check if route is restricted by role
        if (authorize.length && !authorize.includes(store.getters.currentRole)) {
            // role not authorised so redirect to home page
            if (authorize.length) {
                return next({ path: '/' });
            }
            return next({ path: '/dash' });
        }
    } else {
        if (store.getters.currentUser != null) {
            if (store.getters.currentRole != null) {
                if (to.path == '/') {
                    return next({ path: '/dash' });
                }
                if (to.path == '/login') {
                    return next({ path: '/dash' });
                }
            }
        }
    }

    next();
})

export default router
