<template>
  <v-app>
    <v-container fluid>
      <v-row>
        <v-card tile>
          <v-card-title>
            <span class="headline"
              ><v-icon>mdi-account</v-icon>Modificar Datos del Empleado</span
            >
            <v-col cols="2" sm="2" md="2">
              <v-btn color="#5c677d" class="ma-2 white--text" v-on:click="save">
                Guardar
              </v-btn>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form
                ref="form"
                v-model="valid"
                id="check-login-form"
                lazy-validation
              >
                <v-row>
                  <v-col cols="2" sm="2" md="2">
                    <v-avatar class="profile" size="164" tile>
                      <v-img
                        :src="this.employee.picture"
                        max-height="250"
                        max-width="150"
                      ></v-img>
                    </v-avatar>
                  </v-col>
                  <v-col cols="3" sm="3" md="3">
                    <v-text-field
                      label="Nombre"
                      v-model="employee.nombre"
                      :rules="requerido"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3" sm="3" md="3">
                    <v-text-field
                      :rules="requerido"
                      v-model="employee.apellido_paterno"
                      label="Apellido Paterno"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3" sm="3" md="3">
                    <v-text-field
                      :rules="requerido"
                      v-model="employee.apellido_materno"
                      label="Apellido Materno"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3" sm="3" md="3">
                    <v-dialog v-model="dialogPicture" max-width="800px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          dark
                          class="mb-2"
                          v-bind="attrs"
                          v-on="on"
                        >
                          Actualizar Foto
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="text-h5">Actualizar Foto</span>
                        </v-card-title>

                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="12" sm="12" md="12">
                                <v-file-input
                                  accept="image/jpeg"
                                  label="Foto de perfil"
                                  v-model="profilePicture"
                                  v-on:change="guardar($event)"
                                ></v-file-input>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="closePicture"
                          >
                            Cancelar
                          </v-btn>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="savePicture('picture')"
                          >
                            Aceptar
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-col>
                  <v-col cols="2" sm="2" md="2">
                    <v-menu
                      v-model="menuFechaIngreso"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="employee.fecha_alta"
                          label="Fecha ingreso"
                          prepend-icon="mdi-calendar"
                          name="fecha_alta"
                          :rules="requerido"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="employee.fecha_alta"
                        @input="menuFechaIngreso = false"
                        :max="
                          new Date(
                            Date.now() - new Date().getTimezoneOffset() * 60000
                          )
                            .toISOString()
                            .substr(0, 10)
                        "
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="2" sm="2" md="2">
                    <v-text-field
                      :rules="curpRules"
                      v-model="employee.CURP"
                      label="CURP"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2" sm="2" md="2">
                    <v-text-field
                      :rules="rfcRules"
                      v-model="employee.RFC"
                      label="RFC"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2" sm="2" md="2">
                    <v-select
                      :items="sexo"
                      name="sexo"
                      :rules="requerido"
                      v-model="employee.sexo_id"
                      item-text="nombre"
                      item-value="id"
                      label="Sexo"
                    ></v-select>
                  </v-col>
                  <v-col cols="2" sm="2" md="2">
                    <v-text-field
                      v-if="employee.sexo_id === 1"
                      :rules="requerido"
                      v-model="employee.numero_cartilla"
                      label="Número Cartilla"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2" sm="2" md="2">
                    <v-text-field
                      v-if="employee.sexo_id === 1"
                      :rules="requerido"
                      v-model="employee.clase_cartilla"
                      label="Clase (cartilla)"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <template>
                      <v-select
                        :items="puestos"
                        name="puesto"
                        :rules="requerido"
                        v-model="employee.puesto_id"
                        item-text="cargo"
                        item-value="id"
                        label="Puesto"
                      ></v-select>
                    </template>
                  </v-col>
                  <v-col cols="2" sm="2" md="2  ">
                    <v-text-field
                      :rules="requerido"
                      v-model="employee.tel"
                      label="Teléfono"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
              <v-card>
                <v-tabs
                  v-model="tab"
                  background-color="#001233"
                  centered
                  dark
                  icons-and-text
                >
                  <v-tabs-slider></v-tabs-slider>

                  <v-tab href="#tab-1">
                    Domicilio
                    <v-icon>mdi-city-variant-outline</v-icon>
                  </v-tab>

                  <v-tab href="#tab-2">
                    IMSS
                    <v-icon>mdi-heart</v-icon>
                  </v-tab>

                  <v-tab href="#tab-3">
                    Créditos
                    <v-icon>mdi-cash-100</v-icon>
                  </v-tab>

                  <v-tab href="#tab-4">
                    Datos Bancarios
                    <v-icon>mdi-currency-usd</v-icon>
                  </v-tab>

                  <v-tab href="#tab-5">
                    Datos Generales
                    <v-icon>mdi-account-box</v-icon>
                  </v-tab>

                  <v-tab href="#tab-6">
                    Nómina/Salarios/Sanciones
                    <v-icon>mdi-currency-usd</v-icon>
                  </v-tab>

                  <v-tab href="#tab-7">
                    Armamento
                    <v-icon>mdi-pistol</v-icon>
                  </v-tab>

                  <v-tab href="#tab-8">
                    Estatus de inscripción
                    <v-icon>mdi-book-open-page-variant-outline</v-icon>
                  </v-tab>

                  <v-tab href="#tab-9">
                    Datos de emergencia
                    <v-icon>mdi-card-account-phone-outline</v-icon>
                  </v-tab>

                  <v-tab href="#tab-10">
                    Documentos de contratación
                    <v-icon>mdi-text-box-multiple-outline</v-icon>
                  </v-tab>

                  <v-tab href="#tab-11">
                    Cursos
                    <v-icon>mdi-school-outline</v-icon>
                  </v-tab>

                  <v-tab href="#tab-12">
                    Equipamiento
                    <v-icon>mdi-police-badge-outline</v-icon>
                  </v-tab>

                  <v-tab href="#tab-13">
                    Uniforme
                    <v-icon>mdi-tie</v-icon>
                  </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                  <v-tab-item id="tab-1" eager>
                    <v-card flat>
                      <v-card-text class="text-center">
                        <v-form ref="form1" lazy-validation>
                          <v-row>
                            <v-col cols="4" sm="4" md="4">
                              <v-text-field
                                :rules="requerido"
                                v-model="employee.domicilio.calle"
                                label="Calle"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="4" sm="4" md="4">
                              <v-text-field
                                :rules="requerido"
                                v-model="employee.domicilio.numero"
                                label="Número"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="4" sm="4" md="4">
                              <v-text-field
                                :rules="requerido"
                                v-model="employee.domicilio.colonia"
                                label="Colonia"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="4" sm="4" md="4">
                              <v-text-field
                                :rules="requerido"
                                v-model="employee.domicilio.entre_calle"
                                label="Entre calle"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="4" sm="4" md="4">
                              <v-text-field
                                :rules="requerido"
                                v-model="employee.domicilio.y_calle"
                                label="Y calle"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="4" sm="4" md="4">
                              <v-text-field
                                :rules="requerido"
                                v-model="employee.domicilio.codigo_postal"
                                label="Código Postal"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-select
                                :items="estados"
                                @change="fetchCiudades"
                                name="estado"
                                :rules="requerido"
                                v-model="employee.domicilio.ciudad.estado_id"
                                item-text="name"
                                item-value="id"
                                label="Estado"
                              ></v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-select
                                :items="ciudades"
                                name="ciudad"
                                :rules="requerido"
                                v-model="employee.domicilio.ciudad.id"
                                item-text="nombre"
                                item-value="id"
                                label="Ciudad / Municipio"
                              ></v-select>
                            </v-col>
                          </v-row>
                        </v-form>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item id="tab-2" eager>
                    <v-card flat>
                      <v-card-text>
                        <v-row>
                          <v-col cols="2" sm="2" md="2">
                            <v-text-field
                              :rules="requerido"
                              v-model="employee.seguro.NSS"
                              label="NSS"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="2" sm="2" md="2">
                            <v-menu
                              v-model="menuFechaAltaSeguro"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="employee.seguro.fecha_alta"
                                  label="Fecha Alta en el IMSS"
                                  prepend-icon="mdi-calendar"
                                  name="seguro_fecha_alta"
                                  :rules="requerido"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="employee.seguro.fecha_alta"
                                @input="menuFechaAltaSeguro = false"
                                :max="
                                  new Date(
                                    Date.now() -
                                      new Date().getTimezoneOffset() * 60000
                                  )
                                    .toISOString()
                                    .substr(0, 10)
                                "
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-card>
                              <v-card-title>
                                INCAPACIDADES
                                <v-spacer></v-spacer>
                                <v-dialog v-model="dialog" max-width="800px">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      color="primary"
                                      dark
                                      class="mb-2"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      Agregar Incapacidad
                                    </v-btn>
                                  </template>
                                  <v-card>
                                    <v-card-title>
                                      <span class="text-h5"
                                        >Agregar Incapacidad</span
                                      >
                                    </v-card-title>

                                    <v-card-text>
                                      <v-container>
                                        <v-row>
                                          <v-col cols="12" sm="6" md="4">
                                            <v-select
                                              :items="tipoIncapacidad"
                                              name="selectTipoIncapacidad"
                                              :rules="requerido"
                                              v-model="
                                                incapacidad.tipoIncapacidad
                                              "
                                              item-text="nombre"
                                              item-value="id"
                                              label="Tipo de Incapacidad"
                                              return-object
                                            ></v-select>
                                          </v-col>
                                          <v-col cols="12" sm="6" md="4">
                                            <v-menu
                                              v-model="
                                                menuIncapacidadFechaInicio
                                              "
                                              :close-on-content-click="false"
                                              :nudge-right="40"
                                              transition="scale-transition"
                                              offset-y
                                              min-width="auto"
                                            >
                                              <template
                                                v-slot:activator="{ on, attrs }"
                                              >
                                                <v-text-field
                                                  v-model="
                                                    dateInicioIncapacidad
                                                  "
                                                  label="Fecha inicio incapacidad"
                                                  prepend-icon="mdi-calendar"
                                                  name="fecha_inicio_incapacidad"
                                                  :rules="requerido"
                                                  readonly
                                                  v-bind="attrs"
                                                  v-on="on"
                                                ></v-text-field>
                                              </template>
                                              <v-date-picker
                                                v-model="dateInicioIncapacidad"
                                                @input="
                                                  menuIncapacidadFechaInicio = false
                                                "
                                                :max="
                                                  new Date(
                                                    Date.now() -
                                                      new Date().getTimezoneOffset() *
                                                        60000
                                                  )
                                                    .toISOString()
                                                    .substr(0, 10)
                                                "
                                              ></v-date-picker>
                                            </v-menu>
                                          </v-col>
                                          <v-col cols="12" sm="6" md="4">
                                            <v-menu
                                              v-model="menuIncapacidadFechaFin"
                                              :close-on-content-click="false"
                                              :nudge-right="40"
                                              transition="scale-transition"
                                              offset-y
                                              min-width="auto"
                                            >
                                              <template
                                                v-slot:activator="{ on, attrs }"
                                              >
                                                <v-text-field
                                                  v-model="dateFinIncapacidad"
                                                  label="Fecha fin incapacidad"
                                                  prepend-icon="mdi-calendar"
                                                  name="fecha_fin_incapacidad"
                                                  :rules="requerido"
                                                  readonly
                                                  v-bind="attrs"
                                                  v-on="on"
                                                ></v-text-field>
                                              </template>
                                              <v-date-picker
                                                v-model="dateFinIncapacidad"
                                                @input="
                                                  menuIncapacidadFechaFin = false
                                                "
                                              ></v-date-picker>
                                            </v-menu>
                                          </v-col>
                                        </v-row>
                                        <v-row>
                                          <v-col cols="12" sm="12" md="12">
                                            <v-textarea
                                              name="inputMotivoIncapacidad"
                                              label="Motivo incapacidad"
                                              auto-grow
                                              filled
                                              v-model="incapacidad.motivo"
                                            ></v-textarea>
                                          </v-col>
                                          <v-col cols="12" sm="12" md="12">
                                            <v-file-input
                                              accept="application/pdf"
                                              label="Archivo Incapacidad"
                                              v-model="
                                                incapacidad.fileIncapacidad
                                              "
                                              v-on:change="guardar($event)"
                                            ></v-file-input>
                                          </v-col>
                                        </v-row>
                                      </v-container>
                                    </v-card-text>
                                    <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="close"
                                      >
                                        Cancelar
                                      </v-btn>
                                      <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="saveIncapacidad('seguro')"
                                      >
                                        Aceptar
                                      </v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </v-dialog>
                                <v-dialog
                                  v-model="dialogDelete"
                                  max-width="500px"
                                >
                                  <v-card>
                                    <v-card-title class="text-h5"
                                      >¿Borrar Incapacidad?</v-card-title
                                    >
                                    <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="closeDelete"
                                        >Cancel</v-btn
                                      >
                                      <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="deleteItemConfirm"
                                        >OK</v-btn
                                      >
                                      <v-spacer></v-spacer>
                                    </v-card-actions>
                                  </v-card>
                                </v-dialog>
                              </v-card-title>
                              <v-data-table
                                :headers="incapacidadHeaders"
                                :items="employee.incapacidads"
                                hide-default-footer
                              >
                                <template v-slot:item.actions="{ item }">
                                  <v-icon small @click="deleteItem(item)">
                                    mdi-delete
                                  </v-icon>
                                </template>
                              </v-data-table>
                              <v-snackbar
                                v-model="snack"
                                :timeout="3000"
                                :color="snackColor"
                              >
                                {{ snackText }}

                                <template v-slot:action="{ attrs }">
                                  <v-btn
                                    v-bind="attrs"
                                    text
                                    @click="snack = false"
                                  >
                                    Cerrar
                                  </v-btn>
                                </template>
                              </v-snackbar>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item id="tab-3" eager>
                    <v-card flat>
                      <v-card-text>
                        <!-- <v-row>
                          <v-col cols="2" sm="2" md="2">
                            <v-text-field
                              :rules="requerido"
                              v-model="employee.numero_credito_infonavit"
                              label="Número de crédito INFONAVIT"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="2" sm="2" md="2">
                            <v-text-field
                              prefix="$"
                              :rules="requerido"
                              v-model="employee.descuento_infonavit"
                              label="Descuento INFONAVIT"
                            ></v-text-field>
                          </v-col>
                        </v-row> -->
                        <v-row>
                          <!-- <v-col cols="2" sm="2" md="2">
                            <v-text-field
                              :rules="requerido"
                              v-model="employee.numero_credito_fonacot"
                              label="Número de crédito FONACOT"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="2" sm="2" md="2">
                            <v-text-field
                              prefix="$"
                              :rules="requerido"
                              v-model="employee.descuento_fonacot"
                              label="Descuento FONACOT"
                            ></v-text-field>
                          </v-col> -->
                          <v-col cols="12" md="12" sm="12">
                            <v-data-table
                              :headers="creditoHeaders"
                              :items="employee.creditos"
                              hide-default-footer
                            >
                              <template v-slot:item.actions="{ item }">
                                <v-icon small @click="deleteItem(item)">
                                  mdi-delete
                                </v-icon>
                              </template>
                            </v-data-table>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item id="tab-4" eager>
                    <v-card flat>
                      <v-card-text>
                        <v-row>
                          <v-col cols="2" sm="2" md="2">
                            <v-select
                              :items="bancos"
                              v-model="employee.pagos.banco_id"
                              name="banco"
                              :rules="requerido"
                              item-text="nombre"
                              item-value="id"
                              label="Banco"
                            ></v-select>
                          </v-col>
                          <v-col cols="2" sm="2" md="2">
                            <v-text-field
                              :rules="requerido"
                              v-model="employee.pagos.no_cuenta"
                              label="Número de cuenta"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="2" sm="2" md="2">
                            <v-text-field
                              :rules="requerido"
                              v-model="employee.pagos.clabe"
                              label="CLABE interbancaria"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="2" sm="2" md="2">
                            <v-text-field
                              :rules="requerido"
                              v-model="employee.pagos.no_tarjeta"
                              label="Número de tarjeta"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item id="tab-5" eager>
                    <v-card flat>
                      <v-card-text>
                        <v-row>
                          <v-col cols="2" sm="2" md="2">
                            <v-select
                              :items="estadoCivil"
                              name="estadoCivil"
                              :rules="requerido"
                              v-model="employee.estadocivil_id"
                              item-text="nombre"
                              item-value="id"
                              label="Estado Civil"
                            ></v-select>
                          </v-col>
                          <v-col cols="2" sm="2" md="2">
                            <v-select
                              :items="nacionalidad"
                              name="nacionalidad"
                              :rules="requerido"
                              v-model="employee.nacionalidad_id"
                              item-text="nombre"
                              item-value="id"
                              label="Nacionalidad"
                            ></v-select>
                          </v-col>
                          <v-col cols="2" sm="2" md="2">
                            <v-select
                              :items="escolaridad"
                              name="escolaridad"
                              :rules="requerido"
                              v-model="employee.escolaridad_id"
                              item-text="nombre"
                              item-value="id"
                              label="Escolaridad"
                            ></v-select>
                          </v-col>
                          <v-col cols="2" sm="2" md="2  ">
                            <v-text-field
                              :rules="requerido"
                              v-model="fechaNacimiento"
                              label="Fecha de Nacimiento (aaaa/mm/dd)"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="2" sm="2" md="2  ">
                            <v-text-field
                              :rules="requerido"
                              v-model="edad"
                              label="Edad"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-select
                              :items="estados"
                              @change="fetchCiudadesNacimiento"
                              name="estado"
                              :rules="requerido"
                              v-model="employee.ciudad.estado_id"
                              item-text="name"
                              item-value="id"
                              label="Estado de Nacimiento"
                            ></v-select>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-select
                              :items="ciudadesNacimiento"
                              name="ciudad"
                              :rules="requerido"
                              v-model="employee.ciudad.id"
                              item-text="nombre"
                              item-value="id"
                              label="Ciudad / Municipio de Nacimiento"
                            ></v-select>
                          </v-col>
                        </v-row>
                        <v-row> </v-row>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item id="tab-6" eager>
                    <v-card flat>
                      <v-card-text>
                        <v-row>
                          <v-col cols="2" sm="2" md="2">
                            <v-select
                              :items="diasPago"
                              name="diaPago"
                              :rules="requerido"
                              v-model="employee.pagos.fechapago_id"
                              item-text="nombre"
                              item-value="id"
                              label="Fecha de pago de nómina"
                            ></v-select>
                          </v-col>
                          <v-col cols="2" sm="2" md="2">
                            <v-text-field
                              :rules="requerido"
                              v-model="employee.pagos.sueldo_prometido"
                              label="Sueldo mensual"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="2" sm="2" md="2">
                            <v-text-field
                              :rules="requerido"
                              v-model="employee.pagos.sueldo_diario"
                              label="Sueldo diario"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="2" sm="2" md="2">
                            <v-text-field
                              :rules="requerido"
                              v-model="employee.descanso"
                              label="Día de descanso"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="2" sm="2" md="2">
                            <v-text-field
                              :rules="requerido"
                              v-model="employee.descuento_falta_injustificada"
                              label="Descuento por falta injustificada"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item id="tab-7" eager>
                    <v-card flat>
                      <v-card-text>
                        <v-row>
                          <v-col cols="2" sm="2" md="2">
                            <v-menu
                              v-model="menuDateRecepcionServicio"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="
                                    employee.armamento.fecha_recepcion_servicio
                                  "
                                  label="Fecha cuando llegó el arma a servicio"
                                  prepend-icon="mdi-calendar"
                                  name="fecha_recepcion_servicio"
                                  :rules="requerido"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="
                                  employee.armamento.fecha_recepcion_servicio
                                "
                                @input="menuDateRecepcionServicio = false"
                                :max="
                                  new Date(
                                    Date.now() -
                                      new Date().getTimezoneOffset() * 60000
                                  )
                                    .toISOString()
                                    .substr(0, 10)
                                "
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="2" sm="2" md="2">
                            <v-select
                              :items="statusArmas"
                              name="estatusArma"
                              :rules="requerido"
                              v-model="employee.armamento.status_arma_id"
                              item-text="nombre"
                              item-value="id"
                              label="Estatus Arma"
                            ></v-select>
                          </v-col>
                          <v-col cols="2" sm="2" md="2">
                            <v-select
                              :items="tipoArmas"
                              name="tipoArma"
                              :rules="requerido"
                              v-model="employee.armamento.tipo_arma_id"
                              item-text="nombre"
                              item-value="id"
                              label="Tipo de Arma"
                            ></v-select>
                          </v-col>
                          <v-col cols="2" sm="2" md="2">
                            <v-select
                              :items="claseArmas"
                              name="claseArma"
                              :rules="requerido"
                              v-model="employee.armamento.clase_id"
                              item-text="nombre"
                              item-value="id"
                              label="Clase"
                            ></v-select>
                          </v-col>
                          <v-col cols="2" sm="2" md="2">
                            <v-select
                              :items="marcaArmas"
                              name="marcaArma"
                              :rules="requerido"
                              v-model="employee.armamento.marca_id"
                              item-text="nombre"
                              item-value="id"
                              label="Marca"
                            ></v-select>
                          </v-col>
                          <v-col cols="2" sm="2" md="2">
                            <v-select
                              :items="calibreArmas"
                              name="marcaArma"
                              :rules="requerido"
                              v-model="employee.armamento.calibre_id"
                              item-text="nombre"
                              item-value="id"
                              label="Calibre"
                            ></v-select>
                          </v-col>
                          <v-col cols="2" sm="2" md="2">
                            <v-select
                              :items="modeloArmas"
                              name="modeloArma"
                              :rules="requerido"
                              v-model="employee.armamento.modelo_id"
                              item-text="nombre"
                              item-value="id"
                              label="Modelo"
                            ></v-select>
                          </v-col>
                          <v-col cols="2" sm="2" md="2">
                            <v-text-field
                              :rules="requerido"
                              v-model="employee.armamento.matricula"
                              label="Matrícula"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="8" sm="8" md="8">
                            <v-text-field
                              :rules="requerido"
                              v-model="employee.armamento.url_hoja_rosa"
                              label="Matrícula"
                            ></v-text-field>
                            <!-- <v-file-input
                              accept="application/pdf"
                              label="Folio de Hoja Rosa"
                              v-model="folioHojaRosa"
                              v-on:change="guardar($event)"
                            ></v-file-input>
                            <v-btn
                              class="ma-2 white--text"
                              color="blue darken-1"
                              text
                              @click="saveDocumento('armamento')"
                            >
                              Guardar Documento
                            </v-btn> -->
                          </v-col>
                          <v-col cols="2" sm="2" md="2">
                            <v-text-field
                              :rules="requerido"
                              v-model="employee.armamento.numero_orden"
                              label="Número de Orden"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="2" sm="2" md="2">
                            <v-text-field
                              :rules="requerido"
                              v-model="employee.armamento.responsable"
                              label="Responsable / Usuario"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="2" sm="2" md="2">
                            <v-text-field
                              :rules="requerido"
                              v-model="
                                employee.armamento.numero_oficio_comision
                              "
                              label="Número de oficio de Comisión"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="2" sm="2" md="2">
                            <v-menu
                              v-model="menuDateComision"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="employee.armamento.fecha_comision"
                                  label="Fecha de comisión"
                                  prepend-icon="mdi-calendar"
                                  name="fecha_comision"
                                  :rules="requerido"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="employee.armamento.fecha_comision"
                                @input="menuDateComision = false"
                                :max="
                                  new Date(
                                    Date.now() -
                                      new Date().getTimezoneOffset() * 60000
                                  )
                                    .toISOString()
                                    .substr(0, 10)
                                "
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="3" sm="3" md="3">
                            <v-checkbox
                              v-model="
                                employee.armamento
                                  .cuenta_con_credencial_portacion
                              "
                              label="¿Cuenta con credencial o portación?"
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="2" sm="2" md="2">
                            <v-menu
                              v-model="menuDateInicioComoArmado"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="
                                    employee.armamento
                                      .fecha_inicio_elemento_armado
                                  "
                                  label="Fecha de inicio como elemento armado"
                                  prepend-icon="mdi-calendar"
                                  name="fecha_comision"
                                  :rules="requerido"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="
                                  employee.armamento
                                    .fecha_inicio_elemento_armado
                                "
                                @input="menuDateInicioComoArmado = false"
                                :max="
                                  new Date(
                                    Date.now() -
                                      new Date().getTimezoneOffset() * 60000
                                  )
                                    .toISOString()
                                    .substr(0, 10)
                                "
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="2" sm="2" md="2">
                            <v-menu
                              v-model="menuDateInicioVigencia"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="
                                    employee.armamento.fecha_inicio_vigencia
                                  "
                                  label="Inicio de vigencia"
                                  prepend-icon="mdi-calendar"
                                  name="fecha_comision"
                                  :rules="requerido"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="
                                  employee.armamento.fecha_inicio_vigencia
                                "
                                @input="menuDateInicioVigencia = false"
                                :max="
                                  new Date(
                                    Date.now() -
                                      new Date().getTimezoneOffset() * 60000
                                  )
                                    .toISOString()
                                    .substr(0, 10)
                                "
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="2" sm="2" md="2">
                            <v-menu
                              v-model="menuDateTerminoVigencia"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="
                                    employee.armamento.fecha_termino_vigencia
                                  "
                                  label="Término de vigencia"
                                  prepend-icon="mdi-calendar"
                                  name="fecha_comision"
                                  :rules="requerido"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="
                                  employee.armamento.fecha_termino_vigencia
                                "
                                @input="menuDateTerminoVigencia = false"
                                :max="
                                  new Date(
                                    Date.now() -
                                      new Date().getTimezoneOffset() * 60000
                                  )
                                    .toISOString()
                                    .substr(0, 10)
                                "
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="2" sm="2" md="2">
                            <v-select
                              :items="modalidadArmas"
                              name="modalidadArmas"
                              :rules="requerido"
                              v-model="employee.armamento.modalidad_id"
                              item-text="nombre"
                              item-value="id"
                              label="Modalidad"
                            ></v-select>
                          </v-col>
                          <v-col cols="2" sm="2" md="2">
                            <v-select
                              :items="serviciosArmas"
                              name="armaServicio"
                              :rules="requerido"
                              @change="fetchInmueblesArmas"
                              v-model="
                                employee.armamento.lugar.tipo_servicio_id
                              "
                              item-text="nombre"
                              item-value="id"
                              label="Servicio"
                            ></v-select>
                          </v-col>
                          <v-col cols="2" sm="2" md="2">
                            <v-select
                              :items="lugaresArmas"
                              name="armaLugar"
                              :rules="requerido"
                              v-model="employee.armamento.inmueble_id"
                              item-text="nombre"
                              item-value="id"
                              label="Edificio / Inmueble"
                            ></v-select>
                          </v-col>
                          <v-col cols="6" sm="6" md="6">
                            <v-text-field
                              :rules="requerido"
                              v-model="employee.armamento.direccion_resguardo"
                              label="Dirección de resguardo"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-snackbar
                            v-model="snack"
                            :timeout="3000"
                            :color="snackColor"
                          >
                            {{ snackText }}

                            <template v-slot:action="{ attrs }">
                              <v-btn v-bind="attrs" text @click="snack = false">
                                Cerrar
                              </v-btn>
                            </template>
                          </v-snackbar>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item id="tab-8" eager>
                    <v-card flat>
                      <v-card-text>
                        <v-row>
                          <v-col cols="6" sm="6" md="6">
                            <v-menu
                              v-model="menuDateEnvioAntecedentesPoliciales"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="
                                    employee.status_inscripciones
                                      .fecha_envio_antecedentes_policiales
                                  "
                                  label="Fecha de envio de archivo  de consulta de antecedentes policiales (sábados)"
                                  prepend-icon="mdi-calendar"
                                  name="txtFechaEnvioAntecedentesPoliciales"
                                  :rules="requerido"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="
                                  employee.status_inscripciones
                                    .fecha_envio_antecedentes_policiales
                                "
                                @input="
                                  menuDateEnvioAntecedentesPoliciales = false
                                "
                                :max="
                                  new Date(
                                    Date.now() -
                                      new Date().getTimezoneOffset() * 60000
                                  )
                                    .toISOString()
                                    .substr(0, 10)
                                "
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="6" sm="6" md="6">
                            <v-menu
                              v-model="menuDateAcuseAntecedentesPoliciales"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="
                                    employee.status_inscripciones
                                      .fecha_acuse_antecedentes_policiales
                                  "
                                  label="Fecha de acuse de consulta de antecedentes policiales (sábados)"
                                  prepend-icon="mdi-calendar"
                                  name="fecha_acuse_antecedentes_policiales"
                                  :rules="requerido"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="
                                  employee.status_inscripciones
                                    .fecha_acuse_antecedentes_policiales
                                "
                                @input="
                                  menuDateAcuseAntecedentesPoliciales = false
                                "
                                :max="
                                  new Date(
                                    Date.now() -
                                      new Date().getTimezoneOffset() * 60000
                                  )
                                    .toISOString()
                                    .substr(0, 10)
                                "
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="12" sm="12" md="12">
                            <v-text-field
                              :rules="requerido"
                              v-model="
                                employee.status_inscripciones
                                  .url_volante_antecedentes_policiales
                              "
                              label="Número volante de consulta de antecedentes policiales"
                            ></v-text-field>
                            <!-- <v-file-input
                              accept="application/pdf"
                              label="Número volante de consulta de antecedentes policiales"
                              v-on:change="guardar($event)"
                            ></v-file-input>
                            <v-btn
                              class="ma-2 white--text"
                              color="blue darken-1"
                              text
                              @click="saveDocumento('inscripcion')"
                            >
                              Guardar Documento
                            </v-btn> -->
                          </v-col>
                          <v-col cols="6" sm="6" md="6">
                            <v-menu
                              v-model="menuDateInscripcion"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="
                                    employee.status_inscripciones
                                      .fecha_inscripcion
                                  "
                                  label="Fecha de inscripción"
                                  prepend-icon="mdi-calendar"
                                  name="date_inscripcion"
                                  :rules="requerido"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="
                                  employee.status_inscripciones
                                    .fecha_inscripcion
                                "
                                @input="menuDateInscripcion = false"
                                :max="
                                  new Date(
                                    Date.now() -
                                      new Date().getTimezoneOffset() * 60000
                                  )
                                    .toISOString()
                                    .substr(0, 10)
                                "
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="12" sm="12" md="12">
                            <!-- <v-file-input
                              accept="application/pdf"
                              label="Número de volante de inscripción"
                              v-on:change="guardar($event)"
                            ></v-file-input>
                            <v-btn
                              class="ma-2 white--text"
                              color="blue darken-1"
                              text
                              @click="saveDocumento('inscripcion')"
                            >
                              Guardar Documento
                            </v-btn> -->
                            <v-text-field
                              :rules="requerido"
                              v-model="
                                employee.status_inscripciones
                                  .url_volante_inscripcion
                              "
                              label="Número de volante de inscripción"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="2" sm="2" md="2">
                            <v-text-field
                              :rules="requerido"
                              v-model="employee.status_inscripciones.cuip"
                              label="CUIP"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="12">
                            <!-- <v-file-input
                              accept="application/pdf"
                              label="Folio de la CIP portable"
                              v-on:change="guardar($event)"
                            ></v-file-input>
                            <v-btn
                              class="ma-2 white--text"
                              color="blue darken-1"
                              text
                              @click="saveDocumento('inscripcion')"
                            >
                              Guardar Documento
                            </v-btn> -->
                            <v-text-field
                              :rules="requerido"
                              v-model="
                                employee.status_inscripciones
                                  .url_folio_cip_portable
                              "
                              label="Folio de la CIP portable"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="12">
                            <!-- <v-file-input
                              accept="application/pdf"
                              label="Se cuenta físicamente con la CIP"
                              v-on:change="guardar($event)"
                            ></v-file-input>
                            <v-btn
                              class="ma-2 white--text"
                              color="blue darken-1"
                              text
                              @click="saveDocumento('inscripcion')"
                            >
                              Guardar Documento
                            </v-btn> -->
                            <v-text-field
                              :rules="requerido"
                              v-model="
                                employee.status_inscripciones
                                  .existe_fisicamente_cip
                              "
                              label="Se cuenta físicamente con la CIP"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-snackbar
                          v-model="snack"
                          :timeout="3000"
                          :color="snackColor"
                        >
                          {{ snackText }}

                          <template v-slot:action="{ attrs }">
                            <v-btn v-bind="attrs" text @click="snack = false">
                              Cerrar
                            </v-btn>
                          </template>
                        </v-snackbar>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item id="tab-9" eager>
                    <v-card flat>
                      <v-card-text>
                        <v-row>
                          <v-col cols="6" sm="6" md="6">
                            <v-text-field
                              :rules="requerido"
                              v-model="employee.contacto_emergencias.nombre"
                              label="Nombre del contacto de emergencia"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6" sm="6" md="6">
                            <v-text-field
                              :rules="requerido"
                              v-model="employee.contacto_emergencias.telefono"
                              label="Teléfono del contacto de emergencia"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6" sm="6" md="6">
                            <v-text-field
                              :rules="requerido"
                              v-model="employee.padecimiento"
                              label="Padecimiento grave "
                            ></v-text-field>
                          </v-col>
                          <v-col cols="2" sm="2" md="2">
                            <v-select
                              :items="tipoSangre"
                              name="tipoSangre"
                              :rules="requerido"
                              v-model="employee.tiposangre_id"
                              item-text="nombre"
                              item-value="id"
                              label="Tipo de Sangre"
                            ></v-select>
                          </v-col>
                          <v-col cols="2" sm="2" md="2">
                            <v-text-field
                              :rules="requerido"
                              v-model="employee.estatura"
                              label="Estatura (m)"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="2" sm="2" md="2">
                            <v-text-field
                              :rules="requerido"
                              v-model="employee.peso"
                              label="Peso (Kg)"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item id="tab-10" eager>
                    <v-card flat>
                      <v-card-text>
                        <v-row>
                          <v-col cols="12">
                            <v-card>
                              <v-card-title>
                                DOCUMENTOS DE CONTRATACIÓN
                                <v-spacer></v-spacer>
                                <v-dialog
                                  v-model="dialogDocumentosContratacion"
                                  max-width="800px"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      color="primary"
                                      dark
                                      class="mb-2"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      Agregar Documento
                                    </v-btn>
                                  </template>
                                  <v-card>
                                    <v-card-title>
                                      <span class="text-h5"
                                        >Agregar Documento</span
                                      >
                                    </v-card-title>

                                    <v-card-text>
                                      <v-container>
                                        <v-row>
                                          <v-col cols="12" sm="6" md="4">
                                            <v-select
                                              :items="tipoDocumentos"
                                              name="selectTipoDocumento"
                                              :rules="requerido"
                                              v-model="documento.tipoDocumento"
                                              item-text="nombre"
                                              item-value="id"
                                              label="Tipo de Documento"
                                              return-object
                                            ></v-select>
                                          </v-col>
                                        </v-row>
                                        <v-row>
                                          <v-col cols="12" sm="12" md="12">
                                            <v-textarea
                                              name="inputDocumentoComentarios"
                                              label="Comentarios"
                                              auto-grow
                                              filled
                                              v-model="documento.comentarios"
                                            ></v-textarea>
                                          </v-col>
                                          <v-col cols="12" sm="12" md="12">
                                            <v-file-input
                                              accept="application/pdf"
                                              label="Archivo"
                                              v-model="documento.file_url"
                                              v-on:change="guardar($event)"
                                            ></v-file-input>
                                          </v-col>
                                        </v-row>
                                      </v-container>
                                    </v-card-text>

                                    <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="closeDocumento"
                                      >
                                        Cancelar
                                      </v-btn>
                                      <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="saveDocumento('contratacion')"
                                      >
                                        Aceptar
                                      </v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </v-dialog>
                                <v-dialog
                                  v-model="dialogDeleteDocumento"
                                  max-width="500px"
                                >
                                  <v-card>
                                    <v-card-title class="text-h5"
                                      >¿Borrar Documento?</v-card-title
                                    >
                                    <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="closeDeleteDocumento"
                                        >Cancel</v-btn
                                      >
                                      <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="deleteItemConfirmDocumento"
                                        >OK</v-btn
                                      >
                                      <v-spacer></v-spacer>
                                    </v-card-actions>
                                  </v-card>
                                </v-dialog>
                              </v-card-title>
                              <v-data-table
                                :headers="documentoHeaders"
                                :items="employee.documentos"
                                hide-default-footer
                              >
                                <template v-slot:item.actions="{ item }">
                                  <v-icon
                                    small
                                    @click="deleteItemDocumento(item)"
                                  >
                                    mdi-delete
                                  </v-icon>
                                </template>
                              </v-data-table>
                              <v-snackbar
                                v-model="snack"
                                :timeout="3000"
                                :color="snackColor"
                              >
                                {{ snackText }}

                                <template v-slot:action="{ attrs }">
                                  <v-btn
                                    v-bind="attrs"
                                    text
                                    @click="snack = false"
                                  >
                                    Cerrar
                                  </v-btn>
                                </template>
                              </v-snackbar>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item id="tab-11" eager>
                    <v-card flat>
                      <v-card-text>
                        <v-row>
                          <v-col cols="12">
                            <v-card>
                              <v-card-title>
                                CAPACITACION
                                <v-spacer></v-spacer>
                                <v-dialog
                                  v-model="dialogCapacitacion"
                                  max-width="800px"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      color="primary"
                                      dark
                                      class="mb-2"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      Agregar Curso Capacitación
                                    </v-btn>
                                  </template>
                                  <v-card>
                                    <v-card-title>
                                      <span class="text-h5"
                                        >Agregar Curso Capacitación</span
                                      >
                                    </v-card-title>

                                    <v-card-text>
                                      <v-container>
                                        <v-row>
                                          <v-col cols="12" sm="6" md="4">
                                            <v-select
                                              :items="cursos"
                                              name="selectCurso"
                                              :rules="requerido"
                                              v-model="capacitacion.curso"
                                              item-text="nombre"
                                              item-value="id"
                                              label="Curso"
                                              return-object
                                            ></v-select>
                                          </v-col>
                                        </v-row>
                                        <v-row>
                                          <v-col cols="12" sm="6" md="4">
                                            <v-menu
                                              v-model="menuCursoFechaInicio"
                                              :close-on-content-click="false"
                                              :nudge-right="40"
                                              transition="scale-transition"
                                              offset-y
                                              min-width="auto"
                                            >
                                              <template
                                                v-slot:activator="{ on, attrs }"
                                              >
                                                <v-text-field
                                                  v-model="dateInicioCurso"
                                                  label="Fecha inicio curso"
                                                  prepend-icon="mdi-calendar"
                                                  name="fecha_inicio_curso"
                                                  :rules="requerido"
                                                  readonly
                                                  v-bind="attrs"
                                                  v-on="on"
                                                ></v-text-field>
                                              </template>
                                              <v-date-picker
                                                v-model="dateInicioCurso"
                                                @input="
                                                  menuCursoFechaInicio = false
                                                "
                                                :max="
                                                  new Date(
                                                    Date.now() -
                                                      new Date().getTimezoneOffset() *
                                                        60000
                                                  )
                                                    .toISOString()
                                                    .substr(0, 10)
                                                "
                                              ></v-date-picker>
                                            </v-menu>
                                          </v-col>
                                          <v-col cols="12" sm="6" md="4">
                                            <v-menu
                                              v-model="menuCursoFechaFin"
                                              :close-on-content-click="false"
                                              :nudge-right="40"
                                              transition="scale-transition"
                                              offset-y
                                              min-width="auto"
                                            >
                                              <template
                                                v-slot:activator="{ on, attrs }"
                                              >
                                                <v-text-field
                                                  v-model="dateFinCurso"
                                                  label="Fecha fin curso"
                                                  prepend-icon="mdi-calendar"
                                                  name="fecha_fin_curso"
                                                  :rules="requerido"
                                                  readonly
                                                  v-bind="attrs"
                                                  v-on="on"
                                                ></v-text-field>
                                              </template>
                                              <v-date-picker
                                                v-model="dateFinCurso"
                                                @input="
                                                  menuCursoFechaFin = false
                                                "
                                              ></v-date-picker>
                                            </v-menu>
                                          </v-col>
                                        </v-row>
                                        <v-row>
                                          <v-col cols="12" sm="12" md="12">
                                            <v-text-field
                                              name="inputCursoTotalHoras"
                                              label="Total Horas"
                                              v-model="capacitacion.total_horas"
                                            ></v-text-field>
                                          </v-col>
                                          <v-col cols="12" sm="12" md="12">
                                            <v-text-field
                                              name="inputCursoNombreCapacitador"
                                              label="Nombre Capacitador"
                                              v-model="
                                                capacitacion.nombre_capacitador
                                              "
                                            ></v-text-field>
                                          </v-col>
                                        </v-row>
                                      </v-container>
                                    </v-card-text>

                                    <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="closeCapacitacion"
                                      >
                                        Cancelar
                                      </v-btn>
                                      <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="saveCapacitacion"
                                      >
                                        Aceptar
                                      </v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </v-dialog>
                                <v-dialog
                                  v-model="dialogDeleteCapacitacion"
                                  max-width="500px"
                                >
                                  <v-card>
                                    <v-card-title class="text-h5"
                                      >¿Borrar Capacitacion?</v-card-title
                                    >
                                    <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="closeDeleteCapacitacion"
                                        >Cancel</v-btn
                                      >
                                      <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="deleteItemConfirmCapacitacion"
                                        >OK</v-btn
                                      >
                                      <v-spacer></v-spacer>
                                    </v-card-actions>
                                  </v-card>
                                </v-dialog>
                              </v-card-title>
                              <v-data-table
                                :headers="capacitacionHeaders"
                                :items="employee.capacitaciones"
                                hide-default-footer
                              >
                                <template v-slot:item.actions="{ item }">
                                  <v-icon
                                    small
                                    @click="deleteItemCapacitacion(item)"
                                  >
                                    mdi-delete
                                  </v-icon>
                                </template>
                              </v-data-table>
                              <v-snackbar
                                v-model="snack"
                                :timeout="3000"
                                :color="snackColor"
                              >
                                {{ snackText }}

                                <template v-slot:action="{ attrs }">
                                  <v-btn
                                    v-bind="attrs"
                                    text
                                    @click="snack = false"
                                  >
                                    Cerrar
                                  </v-btn>
                                </template>
                              </v-snackbar>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item id="tab-12" eager>
                    <v-card flat>
                      <v-card-text>
                        <v-row>
                          <v-col cols="12">
                            <v-card>
                              <v-card-title>
                                EQUIPAMIENTO
                                <v-spacer></v-spacer>
                                <v-dialog
                                  v-model="dialogEquipamiento"
                                  max-width="800px"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      color="primary"
                                      dark
                                      class="mb-2"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      Agregar Equipamiento
                                    </v-btn>
                                  </template>
                                  <v-card>
                                    <v-card-title>
                                      <span class="text-h5"
                                        >Agregar Equipamiento</span
                                      >
                                    </v-card-title>

                                    <v-card-text>
                                      <v-container>
                                        <v-row>
                                          <v-col cols="12" sm="6" md="4">
                                            <v-select
                                              :items="tipos_equipamiento"
                                              name="selectTipoEquipamiento"
                                              :rules="requerido"
                                              v-model="equipamiento.tipo"
                                              item-text="nombre"
                                              item-value="id"
                                              label="Tipo Equipamiento"
                                              return-object
                                            ></v-select>
                                          </v-col>
                                        </v-row>
                                        <v-row>
                                          <v-col cols="12" sm="6" md="4">
                                            <v-menu
                                              v-model="
                                                menuEquipamientoFechaEntrega
                                              "
                                              :close-on-content-click="false"
                                              :nudge-right="40"
                                              transition="scale-transition"
                                              offset-y
                                              min-width="auto"
                                            >
                                              <template
                                                v-slot:activator="{ on, attrs }"
                                              >
                                                <v-text-field
                                                  v-model="
                                                    dateEquipamientoFechaEntrega
                                                  "
                                                  label="Fecha de entrega"
                                                  prepend-icon="mdi-calendar"
                                                  name="equipamiento_fecha_entrega"
                                                  :rules="requerido"
                                                  readonly
                                                  v-bind="attrs"
                                                  v-on="on"
                                                ></v-text-field>
                                              </template>
                                              <v-date-picker
                                                v-model="
                                                  dateEquipamientoFechaEntrega
                                                "
                                                @input="
                                                  menuEquipamientoFechaEntrega = false
                                                "
                                                :max="
                                                  new Date(
                                                    Date.now() -
                                                      new Date().getTimezoneOffset() *
                                                        60000
                                                  )
                                                    .toISOString()
                                                    .substr(0, 10)
                                                "
                                              ></v-date-picker>
                                            </v-menu>
                                          </v-col>
                                        </v-row>
                                        <v-row>
                                          <v-col cols="12" sm="12" md="12">
                                            <v-text-field
                                              name="inputEquipamientoNumeroSerie"
                                              label="Número de Serie"
                                              v-model="
                                                equipamiento.numero_serie
                                              "
                                            ></v-text-field>
                                          </v-col>
                                        </v-row>
                                      </v-container>
                                    </v-card-text>

                                    <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="closeEquipamiento"
                                      >
                                        Cancelar
                                      </v-btn>
                                      <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="saveEquipamiento"
                                      >
                                        Aceptar
                                      </v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </v-dialog>
                                <v-dialog
                                  v-model="dialogDeleteEquipamiento"
                                  max-width="500px"
                                >
                                  <v-card>
                                    <v-card-title class="text-h5"
                                      >¿Borrar Equipamiento?</v-card-title
                                    >
                                    <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="closeDeleteEquipamiento"
                                        >Cancel</v-btn
                                      >
                                      <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="deleteItemConfirmEquipamiento"
                                        >OK</v-btn
                                      >
                                      <v-spacer></v-spacer>
                                    </v-card-actions>
                                  </v-card>
                                </v-dialog>
                              </v-card-title>
                              <v-data-table
                                :headers="equipamientoHeaders"
                                :items="employee.equipamientos"
                                hide-default-footer
                              >
                                <template v-slot:item.actions="{ item }">
                                  <v-icon
                                    small
                                    @click="deleteItemEquipamiento(item)"
                                  >
                                    mdi-delete
                                  </v-icon>
                                </template>
                              </v-data-table>
                              <v-snackbar
                                v-model="snack"
                                :timeout="3000"
                                :color="snackColor"
                              >
                                {{ snackText }}

                                <template v-slot:action="{ attrs }">
                                  <v-btn
                                    v-bind="attrs"
                                    text
                                    @click="snack = false"
                                  >
                                    Cerrar
                                  </v-btn>
                                </template>
                              </v-snackbar>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item id="tab-13" eager>
                    <v-card flat>
                      <v-card-text>
                        <v-row>
                          <v-col cols="12">
                            <v-card>
                              <v-card-title>
                                UNIFORMES
                                <v-spacer></v-spacer>
                                <v-dialog
                                  v-model="dialogUniforme"
                                  max-width="800px"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      color="primary"
                                      dark
                                      class="mb-2"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      Agregar Uniforme
                                    </v-btn>
                                  </template>
                                  <v-card>
                                    <v-card-title>
                                      <span class="text-h5"
                                        >Agregar Uniforme</span
                                      >
                                    </v-card-title>

                                    <v-card-text>
                                      <v-container>
                                        <v-row>
                                          <v-col cols="12" sm="12" md="12">
                                            <v-select
                                              :items="tipos_uniforme"
                                              name="selectTipoUniforme"
                                              :rules="requerido"
                                              v-model="uniforme.tipo"
                                              item-text="nombre"
                                              item-value="id"
                                              label="Tipo Uniforme"
                                              return-object
                                            ></v-select>
                                          </v-col>
                                          <v-col cols="12" sm="12" md="12">
                                            <v-text-field
                                              name="inputUniformeCantidad"
                                              label="Cantidad"
                                              v-model="uniforme.cantidad"
                                            ></v-text-field>
                                          </v-col>
                                          <v-col cols="12" sm="12" md="12">
                                            <v-select
                                              :items="tallas"
                                              name="selectTallas"
                                              :rules="requerido"
                                              v-model="uniforme.talla"
                                              item-text="nombre"
                                              item-value="id"
                                              label="Talla"
                                              return-object
                                            ></v-select>
                                          </v-col>
                                          <v-col cols="12" sm="12" md="12">
                                            <v-text-field
                                              name="inputUniformeColor"
                                              label="Color"
                                              v-model="uniforme.color"
                                            ></v-text-field>
                                          </v-col>
                                        </v-row>
                                        <v-row>
                                          <v-col cols="12" sm="6" md="4">
                                            <v-menu
                                              v-model="menuUniformeFechaEntrega"
                                              :close-on-content-click="false"
                                              :nudge-right="40"
                                              transition="scale-transition"
                                              offset-y
                                              min-width="auto"
                                            >
                                              <template
                                                v-slot:activator="{ on, attrs }"
                                              >
                                                <v-text-field
                                                  v-model="
                                                    dateUniformeFechaEntrega
                                                  "
                                                  label="Fecha de entrega"
                                                  prepend-icon="mdi-calendar"
                                                  name="equipamiento_fecha_entrega"
                                                  :rules="requerido"
                                                  readonly
                                                  v-bind="attrs"
                                                  v-on="on"
                                                ></v-text-field>
                                              </template>
                                              <v-date-picker
                                                v-model="
                                                  dateUniformeFechaEntrega
                                                "
                                                @input="
                                                  menuUniformeFechaEntrega = false
                                                "
                                                :max="
                                                  new Date(
                                                    Date.now() -
                                                      new Date().getTimezoneOffset() *
                                                        60000
                                                  )
                                                    .toISOString()
                                                    .substr(0, 10)
                                                "
                                              ></v-date-picker>
                                            </v-menu>
                                          </v-col>
                                        </v-row>
                                      </v-container>
                                    </v-card-text>

                                    <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="closeUniforme"
                                      >
                                        Cancelar
                                      </v-btn>
                                      <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="saveUniforme"
                                      >
                                        Aceptar
                                      </v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </v-dialog>
                                <v-dialog
                                  v-model="dialogDeleteUniforme"
                                  max-width="500px"
                                >
                                  <v-card>
                                    <v-card-title class="text-h5"
                                      >¿Borrar Uniforme?</v-card-title
                                    >
                                    <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="closeDeleteUniforme"
                                        >Cancel</v-btn
                                      >
                                      <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="deleteItemConfirmUniforme"
                                        >OK</v-btn
                                      >
                                      <v-spacer></v-spacer>
                                    </v-card-actions>
                                  </v-card>
                                </v-dialog>
                              </v-card-title>
                              <v-data-table
                                :headers="uniformeHeaders"
                                :items="employee.uniformes"
                                hide-default-footer
                              >
                                <template v-slot:item.actions="{ item }">
                                  <v-icon
                                    small
                                    @click="deleteItemUniforme(item)"
                                  >
                                    mdi-delete
                                  </v-icon>
                                </template>
                              </v-data-table>
                              <v-snackbar
                                v-model="snack"
                                :timeout="3000"
                                :color="snackColor"
                              >
                                {{ snackText }}

                                <template v-slot:action="{ attrs }">
                                  <v-btn
                                    v-bind="attrs"
                                    text
                                    @click="snack = false"
                                  >
                                    Cerrar
                                  </v-btn>
                                </template>
                              </v-snackbar>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
                <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
                  {{ snackText }}
                  <template v-slot:action="{ attrs }">
                    <v-btn v-bind="attrs" text @click="snack = false">
                      Cerrar
                    </v-btn>
                  </template>
                </v-snackbar>
              </v-card>
            </v-container>
          </v-card-text>
        </v-card>
      </v-row>
    </v-container>
  </v-app>
</template>
<script>
export default {
  data() {
    return {
      urlPrefix: "https://dev.checkinseguridad.com",
      picUrlPrefix: "https://dev.checkinseguridad.com",
      userId: this.$route.params.id,
      employee: {
        domicilio: {
          ciudad: [],
        },
        seguro: {},
        incapacidades: [],
        pagos: [],
        ciudad: [],
        armamento: {
          lugar: [],
        },
        status_inscripciones: [],
        contacto_emergencias: [],
        uniformes: [],
      },
      seguro: [],
      defaultSeguro: {
        NSS: "",
        fecha_alta: new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10),
      },

      requerido: [(v) => !!v || "* requerido"],
      curpRules: [
        (v) => !!v || "* requerido",
        (v) => (v && v.length == 18) || "La CURP es de 18 carácteres",
      ],
      rfcRules: [
        (v) => !!v || "* requerido",
        (v) =>
          (v && v.length >= 12 && v.length <= 13) ||
          "El RFC es de 12 o 13 carácteres",
      ],
      menuFechaIngreso: false,
      puestos: [],
      valid: false,
      tab: null,
      estados: [],
      ciudades: [],
      snack: false,
      snackColor: "",
      snackText: "",
      menuFechaAltaSeguro: false,
      menuIncapacidadFechaInicio: false,
      menuIncapacidadFechaFin: false,

      editedIndex: -1,
      editedIncapacidad: {
        tipoIncapacidad: {
          id: 0,
          nombre: "",
        },
        motivo: "",
      },
      tipoIncapacidad: [],
      incapacidades: [],
      incapacidad: {
        tipoIncapacidad: {
          id: 0,
          nombre: "",
        },
        motivo: "",
        dateInicioIncapacidad: "",
        dateFinIncapacidad: "",
        incapacidadFile: "",
      },
      defaultIncapacidad: {
        tipoIncapacidad: {
          id: 0,
          nombre: "",
        },
        motivo: "",
        dateInicioIncapacidad: new Date(Date.now()),
        dateFinIncapacidad: new Date(Date.now()),
        incapacidadFile: "",
      },
      incapacidadHeaders: [
        {
          text: "Incapacidad",
          align: "start",
          value: "tipoIncapacidad.nombre",
        },
        { text: "Fecha Inicio", value: "fecha_inicio" },
        { text: "Fecha Término", value: "fecha_termino" },
        { text: "Motivo", value: "motivo" },
        { text: "Archivo", value: "fileUrl" },
        { text: "", value: "actions", sortable: false },
      ],
      creditoHeaders: [
        {
          text: "Crédito",
          align: "start",
          value: "tipoCredito.nombre",
        },
        { text: "Descuento", value: "descuento" },
        { text: "", value: "actions", sortable: false },
      ],

      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateImss: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateInicioIncapacidad: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      dateFinIncapacidad: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),

      dialog: false,
      dialogDelete: false,
      dialogPicture: false,
      profilePicture: [],

      bancos: [],
      sexo: [],
      estadoCivil: [],
      nacionalidad: [],
      escolaridad: [],

      ciudadesNacimiento: [],
      diasPago: [],
      menuDateRecepcionServicio: false,
      menuDateComision: false,
      menuDateInicioComoArmado: false,
      menuDateInicioVigencia: false,
      menuDateTerminoVigencia: false,
      menuDateEnvioAntecedentesPoliciales: false,
      menuDateAcuseAntecedentesPoliciales: false,
      menuCursoFechaInicio: false,
      menuCursoFechaFin: false,
      menuDateInscripcion: false,

      statusArmas: [],
      tipoArmas: [],
      claseArmas: [],
      marcaArmas: [],
      calibreArmas: [],
      modeloArmas: [],
      modalidadArmas: [],

      lugaresArmas: [],
      serviciosArmas: [],

      tipoSangre: [],

      tipoDocumentos: [],
      documento: {
        tipoDocumento: {
          id: 0,
          nombre: "",
        },
        comentarios: "",
        url_documento: "",
      },
      editedIndexDocumento: -1,
      editedDocumento: {
        tipoDocumento: {
          id: 0,
          nombre: "",
        },
        comentarios: "",
        url_documento: "",
      },
      documentos: [],
      defaultDocumento: {
        tipoDocumento: {
          id: 0,
          nombre: "",
        },
        comentarios: "",
        url_documento: "",
      },
      documentoHeaders: [
        {
          text: "Documento",
          align: "start",
          value: "tipoDocumento.nombre",
        },
        { text: "Comentarios", value: "comentarios" },
        { text: "Archivo", value: "url_documento" },
        { text: "", value: "actions", sortable: false },
      ],

      dialogDeleteDocumento: false,
      dialogDeleteCapacitacion: false,
      dialogDocumentosContratacion: false,
      dialogCapacitacion: false,
      dialogEquipamiento: false,
      dialogDeleteEquipamiento: false,
      dialogUniforme: false,
      dialogDeleteUniforme: false,

      capacitacionHeaders: [
        {
          text: "Curso",
          align: "start",
          value: "curso.nombre",
        },
        { text: "Fecha Inicio", value: "fecha_inicio" },
        { text: "Fecha Término", value: "fecha_fin" },
        { text: "Total horas", value: "total_horas" },
        { text: "Capacitador", value: "nombre_capacitador" },
        { text: "", value: "actions", sortable: false },
      ],

      cursos: [],
      capacitacion: {
        curso: {
          id: 0,
          nombre: "",
        },
        fecha_inicio: "",
        fecha_fin: "",
        total_horas: "",
        nombre_capacitador: "",
      },
      capacitaciones: [],
      editedIndexCapacitacion: -1,
      editedCapacitacion: {
        curso: {
          id: 0,
          nombre: "",
        },
        fecha_inicio: new Date(Date.now()),
        fecha_fin: new Date(Date.now()),
        total_horas: "",
        nombre_capacitador: "",
      },
      defaultCapacitacion: {
        curso: {
          id: 0,
          nombre: "",
        },
        fecha_inicio: new Date(Date.now()),
        fecha_fin: new Date(Date.now()),
        total_horas: "",
        nombre_capacitador: "",
      },
      dateInicioCurso: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      dateFinCurso: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      tipos_equipamiento: [],
      equipamientos: [],
      equipamiento: {
        tipo: {
          id: 0,
          nombre: "",
        },
        dateEntregaEquipamiento: "",
        numero_serie: "",
      },
      menuEquipamientoFechaEntrega: false,
      defaultEquipamiento: {
        tipo: {
          id: 0,
          nombre: "",
        },
        dateEntregaEquipamiento: new Date(Date.now()),
        numero_serie: "",
      },
      editedIndexEquipamiento: -1,
      editedEquipamiento: {
        tipo: {
          id: 0,
          nombre: "",
        },
        dateEntregaEquipamiento: new Date(Date.now()),
        numero_serie: "",
      },
      equipamientoHeaders: [
        {
          text: "Tipo",
          align: "start",
          value: "tipo.nombre",
        },
        { text: "Fecha Entrega", value: "fecha_entrega" },
        { text: "Número Serie", value: "numero_serie" },
        { text: "", value: "actions", sortable: false },
      ],
      tipos_uniforme: [],
      tallas: [],
      uniforme: {
        tipo: {
          id: 0,
          nombre: "",
        },
        cantidad: "",
        talla: {
          id: 0,
          nombre: "",
        },
        color: "",
        fecha_entrega: "",
      },
      menuUniformeFechaEntrega: false,
      defaultUniforme: {
        tipo: {
          id: 0,
          nombre: "",
        },
        cantidad: "",
        talla: {
          id: 0,
          nombre: "",
        },
        color: "",
        fecha_entrega: "",
      },
      editedIndexUniforme: -1,
      uniformes: [],
      uniformeHeaders: [
        {
          text: "Tipo",
          align: "start",
          value: "tipo.nombre",
        },
        { text: "Cantidad", value: "cantidad" },
        {
          text: "Talla",
          align: "start",
          value: "talla_id",
        },
        { text: "Color", value: "color" },
        { text: "Fecha Entrega", value: "fecha_entrega" },
        { text: "", value: "actions", sortable: false },
      ],
      json: null,
      dateEquipamientoFechaEntrega: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      dateUniformeFechaEntrega: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
    };
  },
  mounted() {
    this.fetchPuestos();
    this.fetchEmployee();
    this.fetchEstados();
    this.fetchTipoIncapacidad();
    this.fetchBancos();
    this.fetchSexo();
    this.fetchEstadoCivil();
    this.fetchNacionalidad();
    this.fetchEscolaridad();
    this.fetchEstadoCivil();
    this.fetchNacionalidad();
    this.fetchEscolaridad();
    this.fetchCiudadesNacimiento();
    this.fetchDiasPago();
    this.fetchStatusArma();
    this.fetchTipoArma();
    this.fetchClaseArma();
    this.fetchMarcaArma();
    this.fetchCalibreArma();
    this.fetchModeloArma();
    this.fetchModalidadArma();
    this.fetchTipoSangre();
    this.fetchTipoDocumento();
    this.fetchCursos();
    this.fetchTipoEquipamientos();
    this.fetchTiposUniforme();
    this.fetchTallas();
  },
  computed: {
    fechaNacimiento: function () {
      if (this.employee.CURP) {
        if (this.employee.CURP.length >= 8) {
          if (
            !isNaN(this.employee.CURP.substr(4, 2)) &&
            !isNaN(this.employee.CURP.substr(6, 2)) &&
            !isNaN(this.employee.CURP.substr(8, 2))
          ) {
            let anio = 0;
            const d = new Date();
            let year = d.getFullYear();
            if (
              this.employee.CURP.substr(4, 2) > year.toString().substr(2, 2)
            ) {
              anio = "19" + this.employee.CURP.substr(4, 2);
            } else {
              anio = "20" + this.employee.CURP.substr(4, 2);
            }
            return new Date(
              anio,
              this.employee.CURP.substr(6, 2) - 1,
              this.employee.CURP.substr(8, 2)
            )
              .toISOString()
              .split("T")[0];
          }
        }
      }
      return null;
    },
    edad: function () {
      let edad = 0;
      const d = new Date();
      if (this.fechaNacimiento) {
        const anioNacimiento = new Date(this.fechaNacimiento).getFullYear();
        edad = anioNacimiento - d.getFullYear();
      }
      const mesNacimiento = new Date(this.fechaNacimiento).getMonth();
      if (mesNacimiento > d.getMonth()) {
        edad += 1;
      }
      return edad * -1;
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogDocumentosContratacion(val) {
      val || this.closeDocumento();
    },
    dialogDeleteDocumento(val) {
      val || this.closeDeleteDocumento();
    },
    dialogPicture(val) {
      val || this.closePicture();
    },
  },
  methods: {
    fetchEmployee() {
      let url = `${this.urlPrefix}/api/empleados/${this.userId}`;
      this.$axios
        .get(url)
        .then((response) => {
          this.employee = response.data.data;
          if (!response.data.data.seguro) {
            this.employee.seguro = Object.assign({}, this.defaultSeguro);
          }
          //https://dev.checkinseguridad.com/storage/picture/picture.png
          if (this.employee.picture) {
            this.employee.picture = `${this.picUrlPrefix}/storage/picture/${this.employee.picture}`;
          } else {
            this.employee.picture = `${this.picUrlPrefix}/storage/picture/picture.png`;
          }
          if (!this.employee.domicilio.ciudad) {
            this.employee.domicilio.ciudad = 0;
          }
          if (!this.employee.pagos) {
            this.employee.pagos = {
              banco_id: 0,
            };
          }
          if (!this.employee.ciudad) {
            this.employee.ciudad = {
              estado_id: 0,
            };
          }
          if (!this.employee.status_inscripciones) {
            this.employee.status_inscripciones = {
              fecha_envio_antecedentes_policiales: this.date,
              fecha_acuse_antecedentes_policiales: this.date,
            };
          }
          if (!this.employee.contacto_emergencias) {
            this.employee.contacto_emergencias = {
              nombre: "",
              telefono: "",
            };
          }
          this.fetchCiudades();
          this.fetchCiudadesNacimiento();
          this.fetchServicios();
          this.fetchInmueblesArmas();
          console.info("this.employee: ", this.employee);
        })
        .catch((exception) => {
          console.error("Error fetching employee.", exception);
        });
    },

    fetchPuestos() {
      let url = `${this.urlPrefix}/api/puestos/all`;
      this.$axios
        .get(url)
        .then((response) => {
          this.puestos = [];
          this.puestos = response.data.data;
        })
        .catch(() => {
          console.error("Error fetching puestos.");
          this.loading = false;
        });
    },

    fetchEstados() {
      let url = `${this.urlPrefix}/api/estado/all`;
      this.$axios
        .get(url)
        .then((response) => {
          this.estados = [];
          this.estados = response.data.data;
        })
        .catch(() => {
          console.error("Error fetching estados.");
          this.loading = false;
        });
    },

    fetchCiudades() {
      let url = `${this.urlPrefix}/api/ciudad/byestado/${this.employee.domicilio.ciudad.estado_id}`;
      this.$axios
        .get(url)
        .then((response) => {
          this.ciudades = [];
          this.ciudades = response.data.data;
        })
        .catch(() => {
          console.error("Error fetching ciudades.");
          this.loading = false;
        });
    },

    fetchCiudadesNacimiento() {
      let url = `${this.urlPrefix}/api/ciudad/byestado/${this.employee.ciudad.estado_id}`;
      this.$axios
        .get(url)
        .then((response) => {
          this.ciudadesNacimiento = [];
          this.ciudadesNacimiento = response.data.data;
        })
        .catch(() => {
          console.error("Error fetching ciudades.");
          this.loading = false;
        });
    },

    fetchTipoIncapacidad() {
      let url = `${this.urlPrefix}/api/tipoincapacidad/all`;
      this.$axios
        .get(url)
        .then((response) => {
          this.tipoIncapacidad = [];
          this.tipoIncapacidad = response.data.data;
        })
        .catch(() => {
          console.error("Error fetching tipos de incapacidad.");
          this.loading = false;
        });
    },

    save() {
      let url = `${this.urlPrefix}/api/empleados/${this.userId}`;
      if (!this.$refs.form.validate()) {
        return;
      }

      this.$axios
        .put(url, {
          nombre: this.employee.nombre,
          apellido_paterno: this.employee.apellido_paterno,
          apellido_materno: this.employee.apellido_materno,
          fecha_alta: this.employee.fecha_alta,
          CURP: this.employee.CURP,
          RFC: this.employee.RFC,
          numero_cartilla: this.employee.numero_cartilla,
          clase_cartilla: this.employee.clase_cartilla,
          fecha_baja: null,
          puesto_id: this.employee.puesto_id,
          tel: this.employee.tel,
          lugar_id: this.employee.lugarId, //Inmueble del cliente
          calle: this.employee.calle,
          numero: this.employee.numero,
          colonia: this.employee.colonia,
          ciudad_id: this.employee.ciudad_id,
          cp: this.employee.codigo_postal,
          entre_calle: this.employee.entre_calle,
          y_calle: this.employee.y_calle,
        })
        .then((response) => {
          if (response.data.success) {
            this.snack = true;
            this.snackColor = "success";
            this.snackText = "¡Datos del empleado actualizados!";
            //this.dialog = false;
            this.editedItem = [];
            this.date = "";
          } else {
            this.snack = true;
            this.snackColor = "red";
            this.snackText = JSON.stringify(response.data.data);
            //alert(JSON.stringify(response.data.data));
          }
        })
        .catch((errors) => {
          if (errors.response) {
            alert(JSON.stringify(errors.response.data.data));
            console.log(errors);
            //this.dialog = false;
          }
        });
    },

    close() {
      this.incapacidad = Object.assign({}, this.defaultIncapacidad);
      this.dateInicioIncapacidad = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);
      this.dateFinIncapacidad = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);
      this.dialog = false;
      this.$nextTick(() => {
        this.editedIndex = -1;
      });
    },

    closePicture() {
      this.dialogPicture = false;
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    deleteItemConfirm() {
      this.incapacidades.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    savePicture: function (tipoDocumento) {
      const successfulUpload = this.validate(tipoDocumento);
      if (successfulUpload) {
        this.closePicture();

        this.snack = true;
        this.snackColor = "success";
        this.snackText = "¡Foto actualizada!";
        this.employee.picture = `${this.picUrlPrefix}/storage/picture/${this.profilePicture.name}`;
      } else {
        this.snack = true;
        this.snackColor = "red";
        this.snackText = "¡Error! Intente de nuevo por favor.";
      }
    },

    saveIncapacidad: function (tipoDocumento) {
      const successfulUpload = this.validate(tipoDocumento);
      if (successfulUpload) {
        this.incapacidad.fecha_inicio = this.dateInicioIncapacidad;
        this.incapacidad.fecha_termino = this.dateFinIncapacidad;
        this.incapacidad.fileUrl = this.incapacidad.fileIncapacidad.name;
        this.employee.incapacidads.push(this.incapacidad);
        this.incapacidad = Object.assign({}, this.defaultIncapacidad);
        this.close();

        this.snack = true;
        this.snackColor = "success";
        this.snackText = "¡Incapacidad agregada!";
      } else {
        this.snack = true;
        this.snackColor = "red";
        this.snackText = "¡Error! Intente de nuevo por favor.";
      }
    },

    guardar(e) {
      this.json = e;
    },

    validate(tipoDocumento) {
      if (this.json != null) {
        return this.enviar(tipoDocumento);
      }
    },

    async enviar(tipoDocumento) {
      let url = `${this.urlPrefix}/api/archivo/store/${tipoDocumento}/${this.userId}`;
      let data = new FormData();
      data.append("file", this.json);
      this.$axios
        .post(url, data, {
          headers: {
            accept: "application/pdf",
            "Accept-Language": "en-US,en;q=0.8",
            "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
          },
        })
        .then(() => {
          return true;
        })
        .catch((errors) => {
          console.error(errors.response.data);
          return false;
        });
    },

    fetchBancos() {
      let url = `${this.urlPrefix}/api/bancos/all`;
      this.$axios
        .get(url)
        .then((response) => {
          this.bancos = [];
          this.bancos = response.data.data;
        })
        .catch(() => {
          console.error("Error fetching ciudades.");
          this.loading = false;
        });
    },

    fetchEstadoCivil() {
      let url = `${this.urlPrefix}/api/estadocivil/all`;
      this.$axios
        .get(url)
        .then((response) => {
          this.estadoCivil = [];
          this.estadoCivil = response.data.data;
        })
        .catch(() => {
          console.error("Error fetching estados civiles.");
          this.loading = false;
        });
    },

    fetchNacionalidad() {
      let url = `${this.urlPrefix}/api/nacionalidad/all`;
      this.$axios
        .get(url)
        .then((response) => {
          this.nacionalidad = [];
          this.nacionalidad = response.data.data;
        })
        .catch(() => {
          console.error("Error fetching nacionalidades.");
          this.loading = false;
        });
    },

    fetchEscolaridad() {
      let url = `${this.urlPrefix}/api/escolaridad/all`;
      this.$axios
        .get(url)
        .then((response) => {
          this.escolaridad = [];
          this.escolaridad = response.data.data;
        })
        .catch(() => {
          console.error("Error fetching escolaridades.");
          this.loading = false;
        });
    },

    fetchSexo() {
      let url = `${this.urlPrefix}/api/sexo/all`;
      this.$axios
        .get(url)
        .then((response) => {
          this.sexo = [];
          this.sexo = response.data.data;
        })
        .catch(() => {
          console.error("Error fetching sexo.");
          this.loading = false;
        });
    },

    fetchDiasPago() {
      let url = `${this.urlPrefix}/api/diaspago/all`;
      this.$axios
        .get(url)
        .then((response) => {
          this.diasPago = [];
          this.diasPago = response.data.data;
        })
        .catch(() => {
          console.error("Error fetching días de pago.");
          this.loading = false;
        });
    },

    fetchStatusArma() {
      let url = `${this.urlPrefix}/api/statusarma/all`;
      this.$axios
        .get(url)
        .then((response) => {
          this.statusArmas = [];
          this.statusArmas = response.data.data;
        })
        .catch(() => {
          console.error("Error fetching status arma.");
          this.loading = false;
        });
    },

    fetchTipoArma() {
      let url = `${this.urlPrefix}/api/tipoarma/all`;
      this.$axios
        .get(url)
        .then((response) => {
          this.tipoArmas = [];
          this.tipoArmas = response.data.data;
        })
        .catch(() => {
          console.error("Error fetching tipos de armas.");
          this.loading = false;
        });
    },

    fetchClaseArma() {
      let url = `${this.urlPrefix}/api/clasearma/all`;
      this.$axios
        .get(url)
        .then((response) => {
          this.claseArmas = [];
          this.claseArmas = response.data.data;
        })
        .catch(() => {
          console.error("Error fetching clases de armas.");
          this.loading = false;
        });
    },

    fetchMarcaArma() {
      let url = `${this.urlPrefix}/api/marcaarma/all`;
      this.$axios
        .get(url)
        .then((response) => {
          this.marcaArmas = [];
          this.marcaArmas = response.data.data;
        })
        .catch(() => {
          console.error("Error fetching marcas de armas.");
          this.loading = false;
        });
    },

    fetchCalibreArma() {
      let url = `${this.urlPrefix}/api/calibrearma/all`;
      this.$axios
        .get(url)
        .then((response) => {
          this.calibreArmas = [];
          this.calibreArmas = response.data.data;
        })
        .catch(() => {
          console.error("Error fetching calibre de armas.");
          this.loading = false;
        });
    },

    fetchModeloArma() {
      let url = `${this.urlPrefix}/api/modeloarma/all`;
      this.$axios
        .get(url)
        .then((response) => {
          this.modeloArmas = [];
          this.modeloArmas = response.data.data;
        })
        .catch(() => {
          console.error("Error fetching modelos de armas.");
          this.loading = false;
        });
    },

    fetchModalidadArma() {
      let url = `${this.urlPrefix}/api/modalidadarma/all`;
      this.$axios
        .get(url)
        .then((response) => {
          this.modalidadArmas = [];
          this.modalidadArmas = response.data.data;
        })
        .catch(() => {
          console.error("Error fetching modalidad de armas.");
          this.loading = false;
        });
    },

    fetchInmueblesArmas() {
      this.loading = true;

      let url = `${this.urlPrefix}/api/lugares/byServicio/${this.employee.armamento.lugar.tipo_servicio_id}`;
      if (this.page > 1) {
        url = `${url}&page=${this.page}`;
      }
      this.$http
        .get(url)
        .then((response) => {
          this.lugaresArmas = response.data.data;

          this.meta = {
            current_page: response.data.current_page,
            last_page: response.data.last_page,
            per_page: response.data.per_page,
          };
          this.total_items = response.data.total;
          this.loading = false;
        })
        .catch(() => {
          console.error("Error fetching inmuebles.");
          this.loading = false;
        });
    },

    fetchServicios() {
      this.loading = true;
      let url = `${this.urlPrefix}/api/tipoServicio/byEmpresa/${this.employee.armamento.lugar.empresa_id}`;
      if (this.page > 1) {
        url = `${url}&page=${this.page}`;
      }
      this.$axios
        .get(url)
        .then((response) => {
          console.log(response);
          this.servicios = response.data.data;
          this.serviciosArmas = response.data.data;
        })
        .catch(() => {
          console.error("Error fetching servicios.");
          this.loading = false;
        });
    },

    fetchTipoSangre() {
      let url = `${this.urlPrefix}/api/tiposangre/all`;
      this.$axios
        .get(url)
        .then((response) => {
          this.tipoSangre = [];
          this.tipoSangre = response.data.data;
        })
        .catch(() => {
          console.error("Error fetching tipos de sangre.");
          this.loading = false;
        });
    },

    fetchTipoDocumento() {
      let url = `${this.urlPrefix}/api/tipodocumento/all`;
      this.$axios
        .get(url)
        .then((response) => {
          this.tipoDocumentos = [];
          this.tipoDocumentos = response.data.data;
        })
        .catch(() => {
          console.error("Error fetching tipos de documentos.");
          this.loading = false;
        });
    },

    fetchCursos() {
      let url = `${this.urlPrefix}/api/curso/all`;
      this.$axios
        .get(url)
        .then((response) => {
          this.cursos = [];
          this.cursos = response.data.data;
        })
        .catch(() => {
          console.error("Error fetching cursos.");
          this.loading = false;
        });
    },

    fetchTipoEquipamientos() {
      let url = `${this.urlPrefix}/api/tipoequipamiento/all`;
      this.$axios
        .get(url)
        .then((response) => {
          this.tipos_equipamiento = [];
          this.tipos_equipamiento = response.data.data;
        })
        .catch(() => {
          console.error("Error fetching tipo equipamientos.");
          this.loading = false;
        });
    },

    fetchTiposUniforme() {
      let url = `${this.urlPrefix}/api/tipouniforme/all`;
      this.$axios
        .get(url)
        .then((response) => {
          this.tipos_uniforme = [];
          this.tipos_uniforme = response.data.data;
        })
        .catch(() => {
          console.error("Error fetching tipos de uniforme.");
          this.loading = false;
        });
    },

    fetchTallas() {
      let url = `${this.urlPrefix}/api/tallas/all`;
      this.$axios
        .get(url)
        .then((response) => {
          this.tallas = [];
          this.tallas = response.data.data;
        })
        .catch(() => {
          console.error("Error fetching tallas.");
          this.loading = false;
        });
    },
    closeDeleteDocumento() {
      this.dialogDeleteDocumento = false;
      this.$nextTick(() => {
        this.editedDocumento = Object.assign({}, this.defaultDocumento);
        this.editedIndexDocumento = -1;
      });
    },

    deleteItemDocumento(item) {
      this.editedIndexDocumento = this.documentos.indexOf(item);
      this.deleteItemDocumento = Object.assign({}, item);
      this.dialogDeleteDocumento = true;
    },

    deleteItemConfirmDocumento() {
      this.documentos.splice(this.editedIndexDocumento, 1);
      this.closeDeleteDocumento();
    },

    deleteItemCapacitacion(item) {
      this.editedIndexCapacitacion = this.capacitaciones.indexOf(item);
      this.editedCapacitacion = Object.assign({}, item);
      this.dialogDeleteCapacitacion = true;
    },

    deleteItemConfirmCapacitacion() {
      this.capacitaciones.splice(this.editedCapacitacion, 1);
      this.closeDelete();
    },

    closeDeleteCapacitacion() {
      this.dialogDeleteCapacitacion = false;
      this.$nextTick(() => {
        this.editedCapacitacion = Object.assign({}, this.defaultCapacitacion);
        this.editedIndexCapacitacion = -1;
      });
    },

    closeEquipamiento() {
      this.equipamiento = Object.assign({}, this.defaultEquipamiento);
      this.dialogEquipamiento = false;
      this.$nextTick(() => {
        this.editedIndexEquipamiento = -1;
      });
    },

    closeDeleteEquipamiento() {
      this.dialogDeleteEquipamiento = false;
      this.$nextTick(() => {
        this.editedEquipamiento = Object.assign({}, this.defaultEquipamiento);
        this.editedIndexEquipamiento = -1;
      });
    },

    deleteItemConfirmEquipamiento() {
      this.equipamientos.splice(this.editedEquipamiento, 1);
      this.closeDeleteEquipamiento();
    },

    saveEquipamiento() {
      this.equipamiento.fecha_entrega = this.dateEquipamientoFechaEntrega;
      this.employee.equipamientos.push(this.equipamiento);
      this.equipamiento = Object.assign({}, this.defaultEquipamiento);
      this.closeEquipamiento();

      this.snack = true;
      this.snackColor = "success";
      this.snackText = "Data saved";
    },

    closeUniforme() {
      this.uniforme = Object.assign({}, this.defaultUniforme);
      this.dialogUniforme = false;
      this.$nextTick(() => {
        this.editedIndexUniforme = -1;
      });
    },

    saveUniforme() {
      this.uniforme.fecha_entrega = this.dateUniformeFechaEntrega;
      this.employee.uniformes.push(this.uniforme);
      this.uniforme = Object.assign({}, this.defaultUniforme);
      this.closeUniforme();

      this.snack = true;
      this.snackColor = "success";
      this.snackText = "Uniforme guardado";
    },

    closeDeleteUniforme() {
      this.dialogDeleteUniforme = false;
      this.$nextTick(() => {
        this.editedUniforme = Object.assign({}, this.defaultUniforme);
        this.editedIndexUniforme = -1;
      });
    },

    deleteItemConfirmUniforme() {
      this.uniformes.splice(this.editedUniforme, 1);
      this.closeDeleteUniforme();
    },

    deleteItemUniforme(item) {
      this.editedIndexUniforme = this.uniformes.indexOf(item);
      this.editedUniforme = Object.assign({}, item);
      this.dialogDeleteUniforme = true;
    },

    saveCapacitacion() {
      this.capacitacion.fecha_inicio = this.dateInicioCurso;
      this.capacitacion.fecha_fin = this.dateFinCurso;
      this.employee.capacitaciones.push(this.capacitacion);
      this.capacitacion = Object.assign({}, this.defaultCapacitacion);
      this.closeCapacitacion();

      this.snack = true;
      this.snackColor = "success";
      this.snackText = "Data saved";
    },

    saveDocumento: function (tipoDocumento) {
      const successfulUpload = this.validate(tipoDocumento);
      if (successfulUpload) {
        this.documento.url_documento = this.documento.file_url.name;
        this.employee.documentos.push(this.documento);
        this.documento = Object.assign({}, this.defaultDocumento);
        this.closeDocumento();

        this.snack = true;
        this.snackColor = "success";
        this.snackText = "¡Documento agregado!";
      } else {
        this.snack = true;
        this.snackColor = "red";
        this.snackText = "¡Error! Intente de nuevo por favor.";
      }
    },

    closeDocumento() {
      this.documento = Object.assign({}, this.defaultDocumento);
      this.dialogDocumentosContratacion = false;
      this.$nextTick(() => {
        this.editedIndexDocumento = -1;
      });
    },

    closeCapacitacion() {
      this.capacitacion = Object.assign({}, this.defaultCapacitacion);
      this.dialogCapacitacion = false;
      this.$nextTick(() => {
        this.editedIndexCapacitacion = -1;
      });
    },
  },
};
</script>
