<template>
    <v-app>
        
        <app-toolbar />
            
            <v-content>
               <slot /> 
            </v-content>
             
        <app-footer />
    </v-app>
</template>

<script>
import AppToolbar from '../components/HeadAdmin';
import AppFooter from '../components/Footer';

export default {
  components: {
    AppToolbar,
    AppFooter
  },
  data: () => ({
      img_src: '/img/dfacture_logo.png',
  }),
  computed: {
  },
  created () {
  },
  methods: {
  },
};
</script>