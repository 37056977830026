<template>
  <v-app>
    <v-container fluid>
      <v-card-title>
        <span class="headline">Lista de Asistencias</span>
        <v-row>
          <v-col>
            <v-dialog
              ref="dialog3"
              v-model="modal3"
              :return-value.sync="fechaInicio"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="fechaInicio"
                  label="Fecha de inicio de búsqueda"
                  prepend-icon="event"
                  name="fecha_inicio"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fechaInicio"
                :value="fechaInicio"
                scrollable
              >
                <div class="flex-grow-1"></div>
                <v-btn text color="primary" @click="cerrarFechaInicio()"
                  >Cancel</v-btn
                >
                <v-btn text color="primary" @click="saveFechaInicio">OK</v-btn>
              </v-date-picker>
            </v-dialog>
            <v-col cols="12" sm="6" md="4">
              <v-autocomplete
                :items="horas"
                name="horaInicio"
                v-model="horaInicio"
                label="Hora de Inicio"
              ></v-autocomplete>
            </v-col>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-dialog
              ref="dialog4"
              v-model="modal4"
              :return-value.sync="fechaFin"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="fechaFin"
                  label="Fecha de fin de búsqueda"
                  prepend-icon="event"
                  name="fecha_fin"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="fechaFin" :value="fechaFin" scrollable>
                <div class="flex-grow-1"></div>
                <v-btn text color="primary" @click="cerrarFechaFin()"
                  >Cancel</v-btn
                >
                <v-btn text color="primary" @click="saveFechaFin">OK</v-btn>
              </v-date-picker>
            </v-dialog>
            <v-col cols="12" sm="6" md="4">
              <v-autocomplete
                :items="horas"
                name="horaInicio"
                v-model="horaFin"
                label="Hora de Fin"
              ></v-autocomplete>
            </v-col>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-select
              :items="empresas"
              @change="getLugares"
              name="empresa"
              append-icon="search"
              v-model="empresa"
              item-text="nombre"
              item-value="id"
              label="Empresa"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-select
              :items="tipoServicios"
              @change="getLugares('tipoServicio')"
              name="tipoServicio"
              append-icon="search"
              v-model="tipoServicio"
              item-text="nombre"
              item-value="id"
              label="Tipo de Servicio"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-autocomplete
              :items="lugaresBusqueda"
              name="lugar"
              append-icon="search"
              @change="fetchFatiga"
              v-model="lugar"
              item-text="nombre"
              item-value="id"
              label="Lugar"
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Filtrar/Buscar Empleados"
              single-line
              hide-details
            />
          </v-col>
          <v-col>
            <v-btn color="pink" dark @click="buscar()"
              >Buscar
              <v-icon right>search</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-btn color="teal" dark @click="exportar"
            >Exportar
            <v-icon right>import_export</v-icon>
          </v-btn>
          <v-spacer />
          <v-btn color="blue" dark @click="reset"
            >Limpiar Filtros
            <v-icon right>filter_list</v-icon>
          </v-btn>
          <v-spacer />
          <v-spacer />
          <v-btn color="red" dark @click="revisar"
            >Revisar Consistencia
            <v-icon right>filter_list</v-icon>
          </v-btn>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        :options.sync="options"
        :sort-by="'name'"
        :loading="loading"
        :footer-props="{
          itemsPerPageOptions: rows_per_page_items, // -> Add this example
          itemsPerPageText: rows_per_page_text,
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'keyboard_arrow_left',
          nextIcon: 'keyboard_arrow_right',
        }"
        light
        item-key="id"
      >
        <v-progress-linear slot="progress" color="blue" indeterminate />
        <template v-slot:item.foto="{ item }">
          <span v-if="$vuetify.breakpoint.smAndDown">
            <v-img max-height="40px" max-width="40px" :src="item.foto"></v-img>
          </span>
          <span v-if="$vuetify.breakpoint.mdAndUp">
            <v-img
              max-height="100px"
              max-width="100px"
              :src="item.foto"
            ></v-img>
          </span>
        </template>
        <template v-slot:item.invert="{ item }">
          <v-btn @click="invertirTipo(item)">Invertir Tipo</v-btn>
        </template>
        <template v-slot:item.ent="{ item }">
          <v-btn @click="agregarContrario(item)">Agregar Entrada/Salida</v-btn>
        </template>
        <template v-slot:item.action="{ item }">
          <v-icon small @click="deleteItem(item)"> delete </v-icon>
        </template>
      </v-data-table>
      <v-dialog
        ref="dialog5"
        v-model="modal5"
        :return-value.sync="fechaInicio"
        persistent
        max-width="500px"
      >
        <v-card class="mx-auto" outlined>
          <v-card-title> Revisión </v-card-title>
          <v-card-text>
            {{ message }}
            <span v-if="case217">. Seleccione cual asistencia eliminar: </span>
            <br /><br />
            <div v-if="case217">
              <v-radio-group v-model="radioGroup">
                <v-radio
                  v-for="n in repetidos"
                  :key="n.id"
                  :label="` ${n.timestamp}`"
                  :value="n"
                ></v-radio>
              </v-radio-group>
              <v-btn
                v-if="case217"
                color="red"
                :disabled="radioGroup == null"
                text
                @click="deleteItem(radioGroup)"
                >Eliminar registro</v-btn
              >
            </div>
            <v-btn
              v-if="case218 || case220"
              color="blue darken-1"
              text
              @click="addSalidaCorregida()"
              >Agregar Salida</v-btn
            >
            <v-btn
              v-if="case219"
              color="blue darken-1"
              text
              @click="addEntradaCorregida()"
              >Agregar Entrada</v-btn
            >
            <v-btn
              v-if="case218 || case219 || case220"
              color="red"
              text
              @click="deleteItem(item)"
              >Eliminar este registro</v-btn
            >
            <v-btn
              v-if="case219"
              color="blue darken-1"
              text
              @click="invertirTipo(item)"
              >Convertir en entrada</v-btn
            >
          </v-card-text>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn color="blue darken-1" text @click="modal5 = false"
              >Cerrar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-snackbar v-model="snackbar" :timeout="10000" :top="true" color="green">
        {{ mensajeSnack }}
        <v-btn color="black" text @click="snackbar = false"> Cerrar </v-btn>
      </v-snackbar>
    </v-container>
  </v-app>
</template>
<script>
export default {
  data: () => ({
    //urlPrefix: "",
    urlPrefix: "https://dev.checkinseguridad.com",
    snackbar: false,
    mensajeSnack: null,
    dialog: false,
    editedItem: [],
    tipoServicios: [],
    horas: [
      "00",
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
    ],
    horaInicio: "00",
    horaFin: "00",
    tipoServicio: null,
    modal: false,
    modal5: false,
    message: null,
    radioGroup: null,
    bancos: [],
    bancoSelected: {},
    empleado: [],
    pago_id: [],
    modal2: false,
    case218: false,
    case219: false,
    case220: false,
    case217: false,
    repetidos: [],
    modal3: false,
    modal4: false,
    item: null,
    date: null,
    tipoFechas: ["fecha_alta", "fecha_baja"],
    tipoFecha: null,
    date2: null,
    seguro_id: [],
    fechaInicio: null,
    fechaFin: null,
    empresas: [],
    lugaresBusqueda: [],
    empresa: null,
    lugar: null,
    headers: [
      { text: "Foto", value: "foto", align: "center" },
      { text: "Nombre", value: "nombre", align: "center" },
      { text: "Status", value: "empleado.status", align: "center" },
      { text: "Tipo", value: "tipo", align: "center" },
      { text: "Fecha y hora", value: "timestamp", align: "center" },
      { text: "Ubicacion", value: "ubicacion", align: "center" },
      {
        text: "Invertir Tipo",
        value: "invert",
        align: "center",
        sortable: false,
      },
      { text: "Agregar", value: "ent", align: "center", sortable: false },
      { text: "Acciones", value: "action", align: "center", sortable: false },
    ],
    filters: [{ text: "Filtrar por nombre", value: "nombre" }],
    filterBy: {
      text: "Filtrar por nombre",
      value: "nombre",
    },
    orderBy: "ASC",
    orderColor: "teal",
    items: [],
    total_items: 0,
    options: {
      descending: false,
      rowsPerPage: 25,
      page: 1,
    },
    search: "",
    rows_per_page_items: [5, 10, 25, 50],
    rows_per_page_text: "Filas por pagina",
    meta: {
      current_page: 1,
      last_page: 1,
      per_page: 50,
    },
    page: 1,
    loading: false,
  }),
  watch: {
    page: {
      /*handler() {
        this.fetchUsers();
      }*/
    },
  },
  computed: {
    sortIcon() {
      if (this.orderBy === "ASC") {
        return "keyboard_arrow_up";
      }
      return "keyboard_arrow_down";
    },
  },
  mounted() {
    this.fetchEmpresas();
    this.fetchTipoServicios();
    //this.fetchFatiga()
  },
  methods: {
    fetchTipoServicios() {
      let self = this;
      self.loading = true;
      let url = `${this.urlPrefix}/api/tipoServicio/all`;
      if (self.page > 1) {
        url = `${url}&page=${self.page}`;
      }
      this.$http
        .get(url)
        .then((response) => {
          this.tipoServicios = response.data.data;

          self.meta = {
            current_page: response.data.current_page,
            last_page: response.data.last_page,
            per_page: response.data.per_page,
          };
          (self.total_items = response.data.total), (self.loading = false);
        })
        .catch((errors) => {
          console.log(errors);
          alert(errors.response.data.message);
          self.loading = false;
        });
    },
    reset() {
      this.empresa = null;
      this.items = [];
      this.cerrarFechaInicio();
      this.cerrarFechaFin();
      this.lugar = null;
      this.lugaresBusqueda = [];
      this.search = null;
      this.tipoFecha = null;
      this.tipoServicio = null;
    },
    fetchEmpresas() {
      let self = this;
      self.loading = true;
      self.items = [];
      let url = `${this.urlPrefix}/api/empresas/all`;
      if (self.page > 1) {
        url = `${url}&page=${self.page}`;
      }
      this.$axios
        .get(url)
        .then((response) => {
          if (this.$store.getters.currentEmpresa === null) {
            self.empresas = response.data.data;
          }
          self.meta = {
            current_page: response.data.current_page,
            last_page: response.data.last_page,
            per_page: response.data.per_page,
          };
          self.total_items = response.data.total;
          self.loading = false;
        })
        .catch((errors) => {
          console.log(errors);
          self.loading = false;
        });
    },
    fetchFatiga() {
      let url = "";
      if (this.fechaFin != null || this.fechaInicio != null) {
        url = `${this.urlPrefix}/api/asistencias/byFechaC/${this.fechaInicio}/${this.fechaFin}`;
      }
      if (
        this.empresa == null &&
        this.lugar == null &&
        this.fechaFin == null &&
        this.fechaInicio == null
      ) {
        return;
      }
      if (this.empresa != null && this.lugar == null) {
        if (this.fechaInicio != null) {
          this.fechaInicio += " " + this.horaInicio;
        }
        if (this.fechaFin != null) {
          this.fechaFin += " " + this.horaInicio;
        }
        url = `${this.urlPrefix}/api/asistencias/byEmpresaC/${this.empresa}/${this.fechaInicio}/${this.fechaFin}`;
      }
      if (this.empresa != null && this.lugar != null) {
        if (this.fechaInicio != null) {
          let res = this.fechaInicio.split(" ");
          this.fechaInicio = res[0] + " " + this.horaInicio + ":";
        }
        if (this.fechaFin != null) {
          let res = this.fechaFin.split(" ");
          this.fechaFin = res[0] + " " + this.horaFin + ":";
        }
        url = `${this.urlPrefix}/api/asistencias/byLugarC/${this.lugar}/${this.fechaInicio}/${this.fechaFin}`;
      }
      let self = this;
      self.loading = true;
      self.items = [];
      this.$axios
        .get(url)
        .then((response) => {
          self.items = response.data.data;
          self.items.forEach((element) => {
            element.foto = "storage/" + element.foto;
            element.nombre =
              element.empleado.nombre +
              " " +
              element.empleado.apellido_paterno +
              " " +
              element.empleado.apellido_materno;
          });

          self.meta = {
            current_page: response.data.current_page,
            last_page: response.data.last_page,
            per_page: response.data.per_page,
          };
          self.total_items = response.data.total;
          self.loading = false;
        })
        .catch((errors) => {
          console.log(errors);
          self.loading = false;
        });
    },
    getLugares: function name(event) {
      let self = this;
      self.loading = true;
      let url = `${this.urlPrefix}/api/lugares/byEmpresa/${this.empresa}/${this.tipoServicio}`;
      if (self.page > 1) {
        url = `${url}&page=${self.page}`;
      }
      this.$http
        .get(url)
        .then((response) => {
          self.lugaresBusqueda = response.data.data;

          self.meta = {
            current_page: response.data.current_page,
            last_page: response.data.last_page,
            per_page: response.data.per_page,
          };
          self.total_items = response.data.total;
          self.loading = false;
        })
        .catch((errors) => {
          console.log(errors);
          self.loading = false;
        });
      if (event == "empresa") {
        this.fetchSeguro();
      } else {
        this.items = [];
        this.lugar = null;
      }
    },
    saveFechaInicio() {
      this.$refs.dialog3.save(this.fechaInicio);
    },
    saveFechaFin() {
      this.$refs.dialog4.save(this.fechaFin);
    },
    cerrarFecha2() {
      this.date2 = null;
      this.$refs.dialog2.save(this.date2);
      this.modal2 = false;
    },
    cerrarFechaInicio() {
      this.fechaInicio = null;
      this.$refs.dialog3.save(this.fechaInicio);
      this.modal3 = false;
    },
    cerrarFechaFin() {
      this.fechaFin = null;
      this.$refs.dialog4.save(this.fechaFin);
      this.modal4 = false;
    },
    asignarDate() {
      this.date2 = new Date().toISOString().substr(0, 10);
    },
    sleep(time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    },
    toggleOrderBy() {
      if (this.orderBy === "ASC") {
        this.orderBy = "DESC";
        this.orderColor = "orange";
      } else {
        this.orderBy = "ASC";
        this.orderColor = "teal";
      }
    },
    close() {},
    toProperCase(key) {
      let newStr = key.replace(/_/g, " ");
      return newStr.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    changeSort(column) {
      if (this.options.sortBy === column) {
        this.options.descending = !this.options.descending;
      } else {
        this.options.sortBy = column;
        this.options.descending = false;
      }
    },
    exportar() {},
    verFatiga() {
      this.modal5 = true;
    },
    deleteItem(item) {
      let band = confirm(
        `Deseas borrar la asistencia al empleado ${item.nombre}`
      );
      if (!band) {
        return false;
      }
      console.log(item);
      let self = this;
      self.loading = true;
      let url = `${this.urlPrefix}/api/asistencias/${item.id}`;
      this.$axios
        .delete(url)
        .then(() => {
          this.fetchFatiga();
        })
        .catch((errors) => {
          console.log(errors);
        });
      this.loading = false;
      if (this.case218 || this.case217 || this.case219 || this.case220) {
        this.case218 = false;
        this.case217 = false;
        this.case219 = false;
        this.case220 = false;
        this.revisar();
      }
    },
    revisar() {
      if (this.fechaInicio == null) {
        this.mensajeSnack = "falta fecha de inicio";
        this.snackbar = true;
        return;
      }
      if (this.fechaFin == null) {
        this.mensajeSnack = "falta fecha de fin ";
        this.snackbar = true;
        return;
      }
      if (this.lugar == null) {
        this.mensajeSnack = "Coloca el lugar";
        this.snackbar = true;
        return;
      }
      let url = `${this.urlPrefix}/api/asistencias/consistencia/${this.fechaInicio}/${this.fechaFin}/${this.lugar}`;
      this.$axios
        .get(url)
        .then((response) => {
          this.message = response.data.message;
          this.modal5 = true;
          console.log(response.data.data);
          switch (response.data.code) {
            // solo marco entrada
            case 218:
              this.item = response.data.data[0];
              console.log(this.item);
              this.case218 = true;
              break; // solo marcó salida
            case 219:
              this.item = response.data.data[0];
              this.case219 = true;
              break;
            case 217: //entrada repetida
              this.repetidos = response.data.data;
              this.repetidos.forEach((element) => {
                element.nombre = element.empleado.nombre;
              });
              this.case217 = true;
              break;
            case 220: //falta salida
              this.item = response.data.data[0];
              this.case220 = true;
              break;
            default:
              break;
          }
        })
        .catch((errors) => {
          console.log(errors);
          this.loading = false;
        });
      this.buscar();
    },
    addEntradaCorregida() {
      let url = `${this.urlPrefix}/api/asistenciasRevision`;
      let self = this;
      self.loading = true;
      this.$http
        .post(url, {
          tipo: "Entrada",
          timestamp: this.item.timestamp,
          empleado_id: this.item.empleado_id,
          ubicacion: this.item.ubicacion,
          foto: this.item.foto,
        })
        .then(() => {
          this.fetchFatiga();
          self.loading = false;
          this.revisar();
          this.case218 = false;
          this.case219 = false;
          this.case217 = false;
          this.case220 = false;
        })
        .catch((errors) => {
          alert(JSON.stringify(errors.response.data.data));
        });
    },
    addSalidaCorregida() {
      let url = `${this.urlPrefix}/api/asistenciasRevision`;
      let self = this;
      self.loading = true;
      console.log(this.editedItem);
      this.$http
        .post(url, {
          tipo: "Salida",
          timestamp: this.item.timestamp,
          empleado_id: this.item.empleado_id,
          ubicacion: this.item.ubicacion,
          foto: this.item.foto,
        })
        .then(() => {
          this.fetchFatiga();
          self.loading = false;
          this.revisar();
          this.case218 = false;
          this.case217 = false;
          this.case219 = false;
          this.case220 = false;
        })
        .catch((errors) => {
          alert(JSON.stringify(errors.response.data.data));
        });
    },
    buscar() {
      this.fetchFatiga();
    },
    invertirTipo(item) {
      let url = `${this.urlPrefix}/api/asistencias/${item.id}`;
      let self = this;
      self.loading = true;
      let tipo = null;
      if (item.tipo === "Entrada") {
        tipo = "Salida";
      } else {
        tipo = "Entrada";
      }
      console.log(this.editedItem);
      this.$http
        .put(url, { tipo: tipo, timestamp: item.timestamp })
        .then(() => {
          this.fetchFatiga();
          this.loading = false;
          this.revisar();
          this.case218 = false;
          this.case217 = false;
          this.case219 = false;
          this.case220 = false;
        })
        .catch((errors) => {
          alert(JSON.stringify(errors.response.data.data));
        });
    },
    agregarContrario(item) {
      this.item = item;
      if (this.item.tipo === "Entrada") {
        this.addSalidaCorregida();
        return;
      }
      if (this.item.tipo === "Salida") {
        this.addEntradaCorregida();
        return;
      }
    },
  },
};
</script>