import ApiService from "../api/api.service";
import JwtService from "../common/jwt.service";

import {CHECK_AUTH} from "./actions.type"

import {SET_AUTH, SET_ERROR, RESET_AUTH, CLEAR_ERRORS} from "../store/mutations.type";
import {LOGIN, LOGOUT, REGISTER} from "./actions.type";

const getDefaultState = () => {
    return {
        token: null,
        errors: {
            login: [],
            register: []
        },
        vigencia: null,
        userId: null,
        role:null,
        empresa_id:null,
        isAuthenticated: !!JwtService.getToken()
    }
};

const state = getDefaultState();

const getters = {
    getErrors(state){
        return state.errors;
    },
    currentUser(state){
        return state.userId;
    },
    currentRole(state){
        return state.role;
    },
    currentEmpresa(state){
        return state.empresa_id;
    },
    isAuthenticated(state){
        return state.isAuthenticated;
    }
};

const mutations = {
    [CLEAR_ERRORS](state) {
        state.errors = [];
    },
    [SET_ERROR](state, {target, message}) {
        state.errors[target] = [];
        state.errors[target].push({message: message});
    },
    [SET_AUTH](state, data) {
        state.isAuthenticated = true;
        state.role=data.role;
        state.empresa_id=data.empresa_id
        state.userId = data.userId;
        state.token = data.token;
        state.vigencia = data.vigencia;
        state.errors = getDefaultState().errors;
        JwtService.setToken(data.token);
    },
    [RESET_AUTH](state) {
        JwtService.unsetToken();
        Object.assign(state, getDefaultState());
    },
};

const actions = {
    [LOGIN](context, credentials) {
        return new Promise((resolve, reject) => {
            let urlPrefix = 'https://www.checkinseguridad.com'
            let url =  '/api/login';
            ApiService.post(`${urlPrefix}${url}`, credentials)
                .then(({data}) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(
                        SET_AUTH, {userId: data.data.user.id, token: data.data.token, vigencia: data.data.expiration_time
                        , role:data.data.role, empresa_id:data.data.empresa_id}
                    );
                    resolve(data);
                    if(data.data.role=="trabajador"){
                        return new Promise((resolve, reject) => {
                            ApiService.setHeader();
                            ApiService.get('/api/logout')
                                .then(({data}) => {
                                    context.commit(RESET_AUTH);
                                    resolve(data);
                                })
                                .catch(({response}) => {
                                    context.commit(
                                        SET_ERROR,
                                        {target: 'logout', message: "rol erróneo"}
                                    );
                                    reject(response);
                                });
                        });
                    }
                })
                .catch(({response}) => {
                    context.commit(
                        SET_ERROR,
                        {target: 'login', message: response.message}
                    );
                    reject(response);
                });
        });
    },
    [LOGOUT](context) {
        //context.commit(RESET_AUTH);
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get("api/logout")
                .then(({data}) => {
                    context.commit(RESET_AUTH);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(
                        SET_ERROR,
                        {}
                    );
                    context.commit(RESET_AUTH);
                    location.reload(true);
                    
                    reject(response);
                });
        });
    },
    [REGISTER](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.post("api/users", {user: credentials})
                .then(({data}) => {
                    //console.log(data);
                    context.commit(SET_AUTH, {userId: data.userId, token: data.token});
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(
                        SET_ERROR,
                        {target: 'register', message: response.data.error}
                    );
                    reject(response);
                });
        });
    },
    [CHECK_AUTH](context) {
        if (JwtService.getToken()) {
            ApiService.get("api/check")
                .then(({data}) =>{
                    if (data.data.authenticated == false) {
                        context.commit(RESET_AUTH);
                    }
                })
                .catch(() => {
                    context.commit(RESET_AUTH);
            });
        } else {
            context.commit(RESET_AUTH);
        }
    },
};

export default {
    getters,
    actions,
    mutations,
    state
}