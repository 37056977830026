<template>
  <v-app>
    <v-container fluid>
      <v-card-title>
        <span class="headline">Modificar Datos de Seguridad Social</span>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-select
              :items="tipoFechas"
              name="tipoFechas"
              append-icon="search"
              v-model="tipoFecha"
              @change="fetchSeguro()"
              label="Buscar por Fecha Ingreso/Baja"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-dialog
              ref="dialog3"
              v-model="modal3"
              :return-value.sync="fechaInicio"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="fechaInicio"
                  label="Fecha de inicio de búsqueda"
                  prepend-icon="event"
                  name="fecha_inicio"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fechaInicio"
                :value="fechaInicio"
                scrollable
              >
                <div class="flex-grow-1"></div>
                <v-btn text color="primary" @click="cerrarFechaInicio()"
                  >Cancel</v-btn
                >
                <v-btn text color="primary" @click="saveFechaInicio">OK</v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-dialog
              ref="dialog4"
              v-model="modal4"
              :return-value.sync="fechaFin"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="fechaFin"
                  label="Fecha de fin de búsqueda"
                  prepend-icon="event"
                  name="fecha_fin"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="fechaFin" :value="fechaFin" scrollable>
                <div class="flex-grow-1"></div>
                <v-btn text color="primary" @click="cerrarFechaFin()"
                  >Cancel</v-btn
                >
                <v-btn text color="primary" @click="saveFechaFin">OK</v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-autocomplete
              :items="empresas"
              @change="getLugares()"
              name="empresa"
              append-icon="search"
              v-model="empresa"
              item-text="nombre"
              item-value="id"
              label="Empresa"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-autocomplete
              :items="tipoServicios"
              @change="getLugares('tipoServicio')"
              name="tipoServicio"
              append-icon="search"
              v-model="tipoServicio"
              item-text="nombre"
              item-value="id"
              label="Tipo de Servicio"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-autocomplete
              :items="lugaresBusqueda"
              name="lugar"
              append-icon="search"
              @change="fetchSeguro"
              v-model="lugar"
              item-text="nombre"
              item-value="id"
              label="Lugar"
            ></v-autocomplete>
          </v-col>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Filtrar/Buscar Empleados"
            single-line
            hide-details
          />
          <v-dialog v-model="dialog" max-width="500px">
            <v-card>
              <v-card-title>
                <span class="headline">Seguro</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-form
                    ref="form"
                    v-model="valid"
                    id="check-login-form"
                    lazy-validation
                  >
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          type="text"
                          :rules="nssRules"
                          v-model="editedItem.NSS"
                          label="NSS"
                        ></v-text-field>
                      </v-col>
                      <v-dialog
                        ref="dialog2"
                        v-model="modal2"
                        :return-value.sync="date2"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="date2"
                            label="Fecha de Alta en el IMSS"
                            prepend-icon="event"
                            name="fecha_movimiento"
                            readonly
                            @click="asignarDate"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="date2"
                          :value="editedItem.fecha_movimiento"
                          scrollable
                        >
                          <div class="flex-grow-1"></div>
                          <v-btn text color="primary" @click="cerrarFecha2()"
                            >Cancel</v-btn
                          >
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog2.save(date2)"
                            >OK</v-btn
                          >
                        </v-date-picker>
                      </v-dialog>

                      <v-dialog
                        ref="dialog3"
                        v-model="modal3"
                        :return-value.sync="date3"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="date3"
                            label="Fecha de Baja en el IMSS"
                            prepend-icon="event"
                            name="fecha_baja"
                            readonly
                            @click="asignarFechaBaja"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="date3"
                          :value="date3"
                          scrollable
                        >
                          <div class="flex-grow-1"></div>
                          <v-btn text color="primary" @click="cerrarFecha3()"
                            >Cancel</v-btn
                          >
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog3.save(date3)"
                            >OK</v-btn
                          >
                        </v-date-picker>
                      </v-dialog>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          readonly=""
                          v-model="editedItem.no_credito"
                          label="No. de Crédito"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          readonly=""
                          type="number"
                          min="0"
                          v-model="editedItem.descuento_quincenal"
                          label="Descuento quincenal"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          readonly=""
                          v-model="editedItem.monto_descuento"
                          label="Monto de Descuento"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          readonly=""
                          v-model="editedItem.cuota_fija"
                          label="Cuota Fija"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          readonly=""
                          v-model="editedItem.SDI"
                          label="SDI"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <div class="flex-grow-1"></div>
                <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="save">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-spacer />
          <v-spacer />
          <v-btn color="teal" dark @click="exportar"
            >Exportar
            <v-icon right>import_export</v-icon>
          </v-btn>
          <v-spacer />
          <v-btn color="blue" dark @click="reset"
            >Limpiar Filtros
            <v-icon right>filter_list</v-icon>
          </v-btn>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        :options.sync="options"
        :sort-by="'name'"
        :loading="loading"
        :footer-props="{
          itemsPerPageOptions: rows_per_page_items, // -> Add this example
          itemsPerPageText: rows_per_page_text,
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'keyboard_arrow_left',
          nextIcon: 'keyboard_arrow_right',
        }"
        light
        item-key="id"
      >
        <v-progress-linear slot="progress" color="blue" indeterminate />
        <template v-slot:item.action="{ item }">
          <v-icon small @click="editItem(item)"> edit </v-icon>
        </template>
      </v-data-table>
    </v-container>
  </v-app>
</template>
<script>
export default {
  data: () => ({
    //urlPrefix: "",
    urlPrefix: 
    "https://dev.checkinseguridad.com",
    valid: false,
    dialog: false,
    dialog3: false,
    date3: null,
    editedItem: [],
    tipoServicios: [],
    tipoServicio: null,
    modal: false,
    modal2: false,
    modal3: false,
    modal4: false,
    date: null,
    bancos: [],
    tipoFechas: ["fecha_alta", "fecha_baja"],
    tipoFecha: null,
    bancoSelected: {},
    date2: null,
    empleado: [],
    seguro_id: [],
    fechaInicio: null,
    fechaFin: null,
    empresas: [],
    lugaresBusqueda: [],
    empresa: null,
    lugar: null,
    movimientos: [
      "",
      "Baja",
      "Alta",
      "Pensionado",
      "Falta Información",
      "No requiere IMSS",
      "Reingreso",
    ],
    headers: [
      { text: "Unidad o Servicio", value: "lugar.nombre", align: "center" },
      { text: "Nombre", value: "nombreCompleto", align: "center" },
      {
        text: "Fecha de nacimiento",
        value: "fecha_nacimiento",
        align: "center",
      },
      { text: "CURP", value: "CURP", align: "center" },
      { text: "RFC", value: "RFC", align: "center" },
      { text: "NSS", value: "seguro.NSS", align: "center" },
      { text: "Fecha de ingreso", value: "fecha_alta", align: "center" },
      {
        text: "Fecha de alta IMSS",
        value: "seguro.fecha_alta",
        align: "center",
      },
      { text: "Fecha de baja", value: "fecha_baja", align: "center" },
      {
        text: "Fecha de baja IMSS",
        value: "seguro.fecha_baja",
        align: "center",
      },
      { text: "Observaciones", value: "seguro.observaciones", align: "center" },
      { text: "Acciones", value: "action", align: "center", sortable: false },
    ],
    nssRules: [(v) => !!v || "NSS requerido"],
    fechaMovimientoRules: [(v) => !!v || "Movimiento requerido"],
    movimientoRules: [(v) => !!v || "Movimiento requerido"],
    sustituyeRules: [(v) => !!v || "Sustitución requerida"],
    filters: [{ text: "Filtrar por nombre", value: "nombre" }],
    filterBy: {
      text: "Filtrar por nombre",
      value: "nombre",
    },
    orderBy: "ASC",
    orderColor: "teal",
    items: [],
    total_items: 0,
    options: {
      descending: false,
      rowsPerPage: 50,
      page: 1,
    },
    search: "",
    rows_per_page_items: [5, 10, 25, 50],
    rows_per_page_text: "Filas por pagina",
    meta: {
      current_page: 1,
      last_page: 1,
      per_page: 50,
    },
    page: 1,
    loading: false,
  }),
  watch: {
    page: {
      handler() {
        this.fetchUsers();
      },
    },
  },
  computed: {
    sortIcon() {
      if (this.orderBy === "ASC") {
        return "keyboard_arrow_up";
      }
      return "keyboard_arrow_down";
    },
  },

  mounted() {
    let self = this;
    self.setSeguroId(this.$route.params.seguro_id);
    self.fetchEmpresas();
    self.fetchSeguro();
    self.fetchTipoServicios();
  },
  methods: {
    fetchTipoServicios() {
      let self = this;
      self.loading = true;
      let url = `${this.urlPrefix}/api/tipoServicio/all`;
      if (self.page > 1) {
        url = `${url}&page=${self.page}`;
      }
      this.$http
        .get(url)
        .then((response) => {
          this.tipoServicios = response.data.data;

          self.meta = {
            current_page: response.data.current_page,
            last_page: response.data.last_page,
            per_page: response.data.per_page,
          };
          self.total_items = response.data.total;
          self.loading = false;
        })
        .catch((errors) => {
          console.log(errors);
          alert(errors.response.data.message);
          self.loading = false;
        });
    },

    reset() {
      this.empresa = null;
      this.cerrarFechaInicio();
      this.cerrarFechaFin();
      this.tipoServicio = null;
      this.lugar = null;
      this.lugaresBusqueda = [];
      this.search = null;
      this.tipoServicio = null;
      this.fetchSeguro();
      this.tipoFecha = null;
    },

    fetchEmpresas() {
      let self = this;
      self.loading = true;
      self.items = [];
      let url = `${this.urlPrefix}/api/empresas/all`;
      if (self.page > 1) {
        url = `${url}&page=${self.page}`;
      }
      this.$axios
        .get(url)
        .then((response) => {
          self.empresas = response.data.data;
          self.empresasAgregar = response.data.data;

          self.meta = {
            current_page: response.data.current_page,
            last_page: response.data.last_page,
            per_page: response.data.per_page,
          };
          self.total_items = response.data.total;
          self.loading = false;
        })
        .catch((errors) => {
          console.log(errors);
          self.loading = false;
        });
    },

    getLugares: function name(event) {
      let self = this;
      self.loading = true;
      let url = `${this.urlPrefix}/api/lugares/byEmpresa/${this.empresa}/${this.tipoServicio}`;
      if (self.page > 1) {
        url = `${url}&page=${self.page}`;
      }
      this.$http
        .get(url)
        .then((response) => {
          self.lugaresBusqueda = response.data.data;

          self.meta = {
            current_page: response.data.current_page,
            last_page: response.data.last_page,
            per_page: response.data.per_page,
          };
          self.total_items = response.data.total;
          self.loading = false;
        })
        .catch((errors) => {
          console.log(errors);
          self.loading = false;
        });
      if (event == "tipoServicio") {
        this.items = [];
        this.lugar = null;
      } else {
        this.fetchSeguro();
      }
    },

    saveFechaInicio() {
      this.$refs.dialog3.save(this.fechaInicio);
      this.fetchSeguro();
    },

    saveFechaFin() {
      this.$refs.dialog4.save(this.fechaFin);
      this.fetchSeguro();
    },

    cerrarFecha2() {
      this.date2 = null;
      this.$refs.dialog2.save(this.date2);
      this.modal2 = false;
    },

    cerrarFecha3() {
      this.date3 = null;
      this.$refs.dialog3.save(this.date3);
      this.modal3 = false;
    },

    cerrarFechaInicio() {
      this.fechaInicio = null;
      this.$refs.dialog3.save(this.fechaInicio);
      this.modal3 = false;
    },

    cerrarFechaFin() {
      this.fechaFin = null;
      this.$refs.dialog4.save(this.fechaFin);
      this.modal4 = false;
    },

    setSeguroId(value) {
      this.seguro_id = value;
    },

    asignarDate() {
      this.date2 = new Date().toISOString().substr(0, 10);
    },

    asignarFechaBaja() {
      this.date3 = new Date().toISOString().substr(0, 10);
    },

    exportar() {
      let url = "";
      if (
        this.fechaInicio == null &&
        this.fechaFin == null &&
        this.empresa == null &&
        this.tipoServicio == null
      ) {
        url = `${this.urlPrefix}/api/seguros/exportar/all`;
      } else {
        if (
          this.fechaInicio != null &&
          this.fechaFin == null &&
          this.empresa == null &&
          this.lugar == null
        ) {
          url = `${this.urlPrefix}/api/seguros/exportar/byFechaInicio/${this.fechaInicio}/${this.tipoFecha}`;
        } else {
          if (
            this.fechaInicio == null &&
            this.fechaFin != null &&
            this.empresa == null &&
            this.lugar == null
          ) {
            url = `${this.urlPrefix}/api/seguros/exportar/byFechaFin/${this.fechaFin}/${this.tipoFecha}`;
          } else {
            if (
              this.fechaInicio != null &&
              this.fechaFin != null &&
              this.empresa == null &&
              this.lugar == null
            ) {
              url = `${this.urlPrefix}/api/seguros/exportar/byFecha/${this.fechaInicio}/${this.fechaFin}/${this.tipoFecha}`;
            } else {
              if (this.empresa != null && this.lugar == null) {
                url = `${this.urlPrefix}/api/seguros/exportar/byEmpresa/${this.empresa}/${this.fechaInicio}/${this.fechaFin}/${this.tipoFecha}`;
              } else {
                if (
                  (this.empresa != null || this.tipoServicio != null) &&
                  this.lugar != null
                ) {
                  url = `${this.urlPrefix}/api/seguros/exportar/byLugar/${this.lugar}/${this.fechaInicio}/${this.fechaFin}/${this.tipoFecha}`;
                }
              }
            }
          }
        }
      }
      this.$axios
        .get(url)
        .then((response) => {
          const blob = new Blob([response.data], {
            type: "text/csv;charset=utf-8;",
          });
          if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(blob, "seguros.csv");
          } else {
            const link = document.createElement("a");
            if (link.download !== undefined) {
              // feature detection
              // Browsers that support HTML5 download attribute
              const url1 = URL.createObjectURL(blob);
              link.setAttribute("href", url1);
              link.setAttribute("download", "seguros.csv");
              link.style.visibility = "hidden";
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
          }
        })
        .catch((errors) => {
          console.log(errors);
          self.loading = false;
        });
    },

    save() {
      if (!this.$refs.form.validate()) {
        return;
      }
      let self = this;
      self.loading = true;

      this.updateSeguro();
      this.editedItem = [];
      this.date2 = "";
      self.loading = false;
      this.dialog = false;
    },

    editItem(item) {
      if (item.seguro.fecha_alta == undefined) {
        this.date2 = "";
      } else {
        this.date2 = this.formatFecha(item.seguro.fecha_alta);
      }
      if (item.seguro.fecha_baja == undefined) {
        this.date3 = "";
      } else {
        this.date3 = this.formatFecha(item.seguro.fecha_baja);
      }
      this.editedItem.no_credito = item.no_credito;
      this.editedItem.descuento_quincenal = item.descuento_quincenal;
      this.editedItem.monto_descuento = item.monto_descuento;
      this.editedItem.cuota_fija = item.cuota_fija;
      this.editedItem.SDI = item.SDI;
      this.editedItem.NSS = item.seguro.NSS;
      if (item.seguro.sustituye != undefined) {
        this.editedItem.sustituye = item.seguro.movimiento;
      }
      this.editedItem.seguro_id = item.seguro_id;
      this.dialog = true;
    },

    updateSeguro() {
      let url = `${this.urlPrefix}/api/seguros/${this.editedItem.seguro_id}`;
      this.$axios
        .put(url, {
          NSS: this.editedItem.NSS,
          fecha_alta: this.date2,
          fecha_baja: this.date3,
        })
        .then(() => {
          this.fetchSeguro();
          this.dialog = false;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    toggleOrderBy() {
      if (this.orderBy === "ASC") {
        this.orderBy = "DESC";
        this.orderColor = "orange";
      } else {
        this.orderBy = "ASC";
        this.orderColor = "teal";
      }
    },

    close() {
      this.editedItem = [];
      this.date = null;
      this.date2 = null;
      this.dialog = false;
    },

    fetchSeguro() {
      let url = "";
      if (
        this.fechaInicio == null &&
        this.fechaFin == null &&
        this.empresa == null &&
        this.tipoServicio == null
      ) {
        url = `${this.urlPrefix}/api/empleados/all`;
      } else {
        if (
          this.fechaInicio != null &&
          this.fechaFin == null &&
          this.empresa == null &&
          this.lugar == null
        ) {
          url = `${this.urlPrefix}/api/empleados/byFechaInicio/${this.fechaInicio}/${this.tipoFecha}`;
        } else {
          if (
            this.fechaInicio == null &&
            this.fechaFin != null &&
            this.empresa == null &&
            this.lugar == null
          ) {
            url = `${this.urlPrefix}/api/empleados/byFechaFin/${this.fechaFin}/${this.tipoFecha}`;
          } else {
            if (
              this.fechaInicio != null &&
              this.fechaFin != null &&
              this.empresa == null &&
              this.lugar == null
            ) {
              url = `${this.urlPrefix}/api/empleados/byFecha/${this.fechaInicio}/${this.fechaFin}/${this.tipoFecha}`;
            } else {
              if (this.empresa != null && this.lugar == null) {
                url = `${this.urlPrefix}/api/empleados/byEmpresa/${this.empresa}/${this.fechaInicio}/${this.fechaFin}/${this.tipoFecha}`;
              } else {
                if (
                  (this.empresa != null || this.tipoServicio != null) &&
                  this.lugar != null
                ) {
                  url = `${this.urlPrefix}/api/empleados/byLugar/${this.lugar}/${this.fechaInicio}/${this.fechaFin}/${this.tipoFecha}`;
                }
              }
            }
          }
        }
      }

      let self = this;
      self.loading = true;
      self.items = [];
      this.$axios
        .get(url)
        .then((response) => {
          self.items = response.data.data;
          this.items.forEach((element) => {
            element.nombreCompleto =
              element.nombre +
              " " +
              element.apellido_paterno +
              " " +
              element.apellido_materno;
          });
          self.meta = {
            current_page: response.data.current_page,
            last_page: response.data.last_page,
            per_page: response.data.per_page,
          };
          self.total_items = response.data.total;
          self.loading = false;
        })
        .catch((errors) => {
          console.log(errors);
          self.loading = false;
        });
    },

    toProperCase(key) {
      let newStr = key.replace(/_/g, " ");
      return newStr.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },

    changeSort(column) {
      if (this.options.sortBy === column) {
        this.options.descending = !this.options.descending;
      } else {
        this.options.sortBy = column;
        this.options.descending = false;
      }
    },

    formatFecha(fecha) {
      let str = fecha;
      let darr = str.split("-"); // ["29", "1", "2016"]
      var dobj = new Date(
        parseInt(darr[0]),
        parseInt(darr[1]) - 1,
        parseInt(darr[2])
      );
      // Date {Fri Jan 29 2016 00:00:00 GMT+0530(utopia standard time)
      //2016-01-28T18:30:00.000Z
      return dobj.toISOString().substr(0, 10);
    },
  },
};
</script>