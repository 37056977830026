import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import store from '../store'

import JwtService from "../common/jwt.service";

const ApiService = {
    init() {
        Vue.use(VueAxios, axios);
        Vue.axios.interceptors.request.use(config => {
            if (store.getters.isAuthenticated) {
                config.headers['Authorization'] = [
                    'Bearer',
                    JwtService.getToken()
                ].join(" ");
            } else {
                delete config.headers['Authorization'];
            }
            return config;
        }
        );
    },

    setHeader() {
        Vue.axios.defaults.headers.common = {
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + JwtService.getToken(),
            'Access-Control-Allow-Origin': '*'
        };
        //Vue.axios.defaults.headers.common['Authorization'] = 'bearer ' + JwtService.getToken();
    },

    async get(resource, slug = "") {
        try {
            Vue.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest',
                'Authorization': 'Bearer ' + JwtService.getToken(),
                'Access-Control-Allow-Origin': '*'
            };
            return await Vue.axios.get(`${resource}/${slug}`);
        } catch (error) {
            throw new Error(`[RWV] ApiService ${error}`);
        }
    },

    post(resource, params) {
        //this.setHeader();
        return Vue.axios.post(`${resource}`, params);
    },
};

export default ApiService;