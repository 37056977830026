<template>
  <v-app>
    <v-container fluid>
      <v-card-title>
        <v-col cols="12" sm="6" md="4">
          <v-dialog
            ref="dialog3"
            v-model="modal3"
            :return-value.sync="fechaInicio"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="fechaInicio"
                label="Fecha de inicio de búsqueda"
                prepend-icon="event"
                name="fecha_inicio"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="fechaInicio"
              :value="fechaInicio"
              scrollable
            >
              <div class="flex-grow-1"></div>
              <v-btn text color="primary" @click="cerrarFechaInicio()"
                >Cancel</v-btn
              >
              <v-btn text color="primary" @click="saveFechaInicio">OK</v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-dialog
            ref="dialog4"
            v-model="modal4"
            :return-value.sync="fechaFin"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="fechaFin"
                label="Fecha de fin de búsqueda"
                prepend-icon="event"
                name="fecha_fin"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="fechaFin" :value="fechaFin" scrollable>
              <div class="flex-grow-1"></div>
              <v-btn text color="primary" @click="cerrarFechaFin()"
                >Cancel</v-btn
              >
              <v-btn text color="primary" @click="saveFechaFin">OK</v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Filtrar/Buscar Empleados"
          single-line
          hide-details
        />
        <v-spacer />
        <v-btn color="teal" dark @click="exportar"
          >Exportar
          <v-icon right>import_export</v-icon>
        </v-btn>
        <v-spacer />
        <v-btn color="blue" dark @click="reset"
          >Limpiar Filtros
          <v-icon right>filter_list</v-icon>
        </v-btn>
        <v-spacer />
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        :options.sync="options"
        :sort-by="'name'"
        :loading="loading"
        :footer-props="{
          itemsPerPageOptions: rows_per_page_items, // -> Add this example
          itemsPerPageText: rows_per_page_text,
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'keyboard_arrow_left',
          nextIcon: 'keyboard_arrow_right',
        }"
        light
        item-key="id"
      >
        <v-progress-linear slot="progress" color="blue" indeterminate />
        <template v-slot:item.foto="{ item }">
          <v-img max-height="100px" max-width="100px" :src="item.foto"></v-img>
        </template>
        <template v-slot:item.action="{ item }">
          <v-icon small @click="abrir(item)"> edit </v-icon>
        </template>
        <template v-slot:item.delete="{ item }">
          <v-icon small @click="deleteItem(item)"> delete </v-icon>
        </template>
      </v-data-table>
      <v-dialog ref="dialog5" v-model="modal5" max-width="500px">
        <v-card class="mx-auto" outlined>
          <v-card-title> Reasignar </v-card-title>
          <v-card-text>
            <v-form
              ref="form"
              v-model="valid"
              id="check-login-form"
              lazy-validation
            >
              <v-select
                :items="empresasAgregar"
                @change="getLugaresParaAgregar"
                v-model="empresaId"
                name="empresa"
                item-text="nombre"
                item-value="id"
                label="Empresa"
              ></v-select>
              <v-select
                :items="tipoServicios"
                @change="getLugaresParaAgregar"
                v-model="tipoServicioId"
                name="tipoServicio"
                item-text="nombre"
                item-value="id"
                label="Tipo de Servicio"
              ></v-select>
              <v-autocomplete
                :items="lugares"
                name="lugar"
                @change="fetchEmpleados()"
                v-model="lugarId"
                item-text="nombre"
                item-value="id"
                label="Lugar"
              ></v-autocomplete>
              <v-autocomplete
                :items="empleados"
                name="empleado"
                :rules="empleadoRules"
                v-model="empleadoId"
                item-text="nombreCompleto"
                item-value="empleado_id"
                label="Empleado"
              ></v-autocomplete>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn color="blue darken-1" text @click="close">Cerrar</v-btn>
            <v-btn color="blue darken-1" text @click="reasignar">Asignar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-app>
</template>
<script>
export default {
  data: () => ({
    urlPrefix: '',//"https://dev.checkinseguridad.com",
    dialog: false,
    editedItem: [],
    tipoServicios: [],
    tipoServicioId: null,
    tipoServicio: null,
    empresaId: null,
    lugarId: null,
    modal: false,
    modal5: false,
    bancos: [],
    lugares: [],
    empleadoId: null,
    empresasAgregar: [],
    bancoSelected: {},
    empleado: [],
    empleados: [],
    pago_id: [],
    modal2: false,
    modal3: false,
    modal4: false,
    date: null,
    valid: false,
    tipoFechas: ["fecha_alta", "fecha_baja"],
    tipoFecha: null,
    date2: null,
    seguro_id: [],
    fechaInicio: null,
    fechaFin: null,
    empresas: [],
    lugaresBusqueda: [],
    empresa: null,
    lugar: null,
    headers: [
      { text: "ID", value: "id", align: "center" },
      { text: "Foto", value: "foto", align: "center" },
      { text: "Empleado_id", value: "empleado_id", align: "center" },
      { text: "Nombre", value: "nombre", align: "center" },
      { text: "Ubicación", value: "ubicacion", align: "center" },
      { text: "Tipo", value: "tipo", align: "center" },
      { text: "Fecha y hora", value: "timestamp", align: "center" },
      { text: "Reasignar", value: "action", align: "center", sortable: false },
      { text: "Borrar", value: "delete", align: "center", sortable: false },
    ],
    filters: [{ text: "Filtrar por nombre", value: "nombre" }],
    filterBy: {
      text: "Filtrar por nombre",
      value: "nombre",
    },
    empresaRules: [(v) => !!v || "Empresa requerida"],
    empleadoRules: [(v) => !!v || "Empleado requerida"],
    tipoServicioRules: [(v) => !!v || "Tipo de Servicio requerido"],
    lugarRules: [(v) => !!v || "Lugar requerido"],
    orderBy: "ASC",
    orderColor: "teal",
    items: [],
    total_items: 0,
    options: {
      descending: false,
      rowsPerPage: 50,
      page: 1,
    },
    search: "",
    rows_per_page_items: [5, 10, 25, 50],
    rows_per_page_text: "Filas por pagina",
    meta: {
      current_page: 1,
      last_page: 1,
      per_page: 50,
    },
    page: 1,
    loading: false,
  }),
  watch: {
    page: {
      /*handler() {
        this.fetchUsers();
      }*/
    },
  },
  computed: {
    sortIcon() {
      if (this.orderBy === "ASC") {
        return "keyboard_arrow_up";
      }
      return "keyboard_arrow_down";
    },
  },
  mounted() {
    this.fetchEmpresas();
    this.fetchEmpleados();
    this.fetchTipoServicios();
    this.fetchFatiga();
  },
  methods: {
    fetchTipoServicios() {
      let self = this;
      self.loading = true;
      let url = `${this.urlPrefix}/api/tipoServicio/all`;
      if (self.page > 1) {
        url = `${url}&page=${self.page}`;
      }
      this.$http
        .get(url)
        .then((response) => {
          this.tipoServicios = response.data.data;

          self.meta = {
            current_page: response.data.current_page,
            last_page: response.data.last_page,
            per_page: response.data.per_page,
          };
          self.total_items = response.data.total;
          self.loading = false;
        })
        .catch((errors) => {
          console.log(errors);
          alert(errors.response.data.message);
          self.loading = false;
        });
    },

    reset() {
      this.empresa = null;
      this.cerrarFechaInicio();
      this.cerrarFechaFin();
      this.lugar = null;
      this.lugaresBusqueda = [];
      this.search = null;
      this.fetchFatiga();
      this.tipoFecha = null;
      this.tipoServicio = null;
    },

    getLugaresParaAgregar: function name() {
      let self = this;
      let url = `${this.urlPrefix}/api/lugares/byEmpresa/${this.empresaId}/${this.tipoServicioId}`;
      if (self.page > 1) {
        url = `${url}&page=${self.page}`;
      }
      this.$http
        .get(url)
        .then((response) => {
          self.lugares = response.data.data;
          self.loading = false;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    fetchEmpresas() {
      let self = this;
      self.loading = true;
      self.items = [];
      let url = `${this.urlPrefix}/api/empresas/all`;
      if (self.page > 1) {
        url = `${url}&page=${self.page}`;
      }
      this.$axios
        .get(url)
        .then((response) => {
          self.empresasAgregar = response.data.data;

          self.meta = {
            current_page: response.data.current_page,
            last_page: response.data.last_page,
            per_page: response.data.per_page,
          };
          self.total_items = response.data.total;
          self.loading = false;
        })
        .catch((errors) => {
          console.log(errors);
          self.loading = false;
        });
    },

    fetchFatiga() {
      let url = "";
      if (this.fechaFin != null || this.fechaInicio != null) {
        url = `${this.urlPrefix}/api/failedAsistencia/byFecha/${this.fechaInicio}/${this.fechaFin}`;
      }
      if (this.fechaFin == null && this.fechaInicio == null) {
        url = `${this.urlPrefix}/api/failedAsistencia/all`;
      }
      let self = this;
      self.loading = true;
      self.items = [];
      this.$axios
        .get(url)
        .then((response) => {
          self.items = response.data.data;
          self.items.forEach((element) => {
            element.foto = "storage/" + element.foto;
          });

          self.meta = {
            current_page: response.data.current_page,
            last_page: response.data.last_page,
            per_page: response.data.per_page,
          };
          self.total_items = response.data.total;
          self.loading = false;
        })
        .catch((errors) => {
          console.log(errors);
          self.loading = false;
        });
    },

    getLugares: function name(event) {
      let self = this;
      self.loading = true;
      let url = `${this.urlPrefix}/api/lugares/byEmpresa/${this.empresa}/${this.tipoServicio}`;
      if (self.page > 1) {
        url = `${url}&page=${self.page}`;
      }
      this.$http
        .get(url)
        .then((response) => {
          self.lugaresBusqueda = response.data.data;

          self.meta = {
            current_page: response.data.current_page,
            last_page: response.data.last_page,
            per_page: response.data.per_page,
          };
          self.total_items = response.data.total;
          self.loading = false;
        })
        .catch((errors) => {
          console.log(errors);
          self.loading = false;
        });
      if (event == "empresa") {
        this.fetchSeguro();
      } else {
        this.items = [];
        this.lugar = null;
      }
    },

    saveFechaInicio() {
      this.$refs.dialog3.save(this.fechaInicio);
      this.fetchFatiga();
    },

    saveFechaFin() {
      this.$refs.dialog4.save(this.fechaFin);
      this.fetchFatiga();
    },

    cerrarFecha2() {
      this.date2 = null;
      this.$refs.dialog2.save(this.date2);
      this.modal2 = false;
    },

    cerrarFechaInicio() {
      this.fechaInicio = null;
      this.$refs.dialog3.save(this.fechaInicio);
      this.modal3 = false;
    },

    cerrarFechaFin() {
      this.fechaFin = null;
      this.$refs.dialog4.save(this.fechaFin);
      this.modal4 = false;
    },

    asignarDate() {
      this.date2 = new Date().toISOString().substr(0, 10);
    },

    deleteItem(item) {
      let band = confirm(
        `Deseas borrar la asistencia al empleado ${item.nombre}`
      );
      if (!band) {
        return false;
      }
      this.onlyDelete(item);
    },

    onlyDelete(item) {
      let url = `${this.urlPrefix}/api/failedAsistencia/${item.id}`;
      this.$axios
        .delete(url)
        .then(() => {
          this.fetchFatiga();
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    sleep(time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    },

    toggleOrderBy() {
      if (this.orderBy === "ASC") {
        this.orderBy = "DESC";
        this.orderColor = "orange";
      } else {
        this.orderBy = "ASC";
        this.orderColor = "teal";
      }
    },

    close() {
      this.modal5 = false;
      this.empresaId = null;
      this.tipoServicioId = null;
      this.lugarId = null;
      this.empleadoId = null;
    },

    toProperCase(key) {
      let newStr = key.replace(/_/g, " ");
      return newStr.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },

    changeSort(column) {
      if (this.options.sortBy === column) {
        this.options.descending = !this.options.descending;
      } else {
        this.options.sortBy = column;
        this.options.descending = false;
      }
    },

    exportar() {},

    reasignar() {
      if (!this.$refs.form.validate()) {
        return;
      }
      let url = `${this.urlPrefix}/api/asistencias`;
      this.$axios
        .post(url, {
          tipo: this.editedItem.tipo,
          empleado_id: this.empleadoId,
          ubicacion: this.editedItem.ubicacion,
          timestamp: this.editedItem.timestamp,
          foto: this.editedItem.foto,
        })
        .then(() => {
          this.onlyDelete(this.editedItem);
          this.close();
        })
        .catch((errors) => {
          alert(errors.response.data.message);
        });
    },

    fetchEmpleados() {
      let url = "";
      if (
        this.empresaId == null &&
        this.tipoServicioId == null &&
        this.lugarId == null
      ) {
        url = `${this.urlPrefix}/api/empleados/all`;
      } else {
        if (this.lugarId != null) {
          url = `${this.urlPrefix}/api/empleados/byLugar/${this.lugarId}/null/null/null`;
        }
      }
      let self = this;
      this.$axios
        .get(url)
        .then((response) => {
          self.empleados = response.data.data;
          this.empleados.forEach((element) => {
            element.nombreCompleto =
              element.nombre +
              " " +
              element.apellido_paterno +
              " " +
              element.apellido_materno;
          });
        })
        .catch((errors) => {
          console.log(errors);
          self.loading = false;
        });
    },

    abrir(item) {
      this.modal5 = true;
      this.editedItem = Object.assign({}, item);
    },
  },
};
</script>