<template>
  <v-app>
    <v-container fluid>
      <v-card-title>
        <v-col
          cols="12"
          sm="6"
          md="4"
          v-if="$store.getters.currentEmpresa == null"
        >
          <v-select
            :items="empresas"
            @change="getLugares"
            name="empresa"
            append-icon="search"
            v-model="empresa"
            item-text="nombre"
            item-value="id"
            label="Empresa"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-select
            :items="tipoServicios"
            @change="fetchLugares"
            name="tipoServicio"
            append-icon="search"
            v-model="tipoServicio"
            item-text="nombre"
            item-value="id"
            label="Tipo de Servicio"
          ></v-select>
        </v-col>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Filtrar/Buscar Servicio"
          single-line
          hide-details
        />
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              @click="asignarValoresDefault()"
              v-on="on"
              >Agregar Servicio</v-btn
            >
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Servicio</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form
                  ref="form"
                  v-model="valid"
                  id="check-login-form"
                  lazy-validation
                >
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                      v-if="$store.getters.currentEmpresa == null"
                    >
                      <v-select
                        :items="empresas"
                        v-model="editedItem.empresaId"
                        name="empresa"
                        item-text="nombre"
                        :rules="empresaRules"
                        item-value="id"
                        label="Empresa"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-select
                        :items="tipoServicios"
                        :rules="tipoServicioRules"
                        v-model="editedItem.tipoServicioId"
                        name="tipoServicio"
                        item-text="nombre"
                        item-value="id"
                        label="Tipo de Servicio"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        type="number"
                        min="0"
                        v-model="editedItem.jurisdiccion"
                        label="Jurisdicción"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        :rules="nombreRules"
                        v-model="editedItem.nombre"
                        label="Nombre"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.direccion"
                        label="Direccion"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        type="number"
                        min="0"
                        :rules="elementosRules"
                        v-model="editedItem.elementos"
                        label="Elementos"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        type="number"
                        min="0"
                        :rules="doblete1Rules"
                        step="1.00"
                        v-model="editedItem.doblete_1"
                        label="Doblete x1"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        type="number"
                        min="0"
                        step="1.00"
                        v-model="editedItem.doblete_2"
                        label="Doblete x2"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        type="number"
                        min="0"
                        :rules="faltaRules"
                        v-model="editedItem.falta"
                        label="Falta Justificada"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        :rules="faltaInRules"
                        v-model="editedItem.falta_injustificada"
                        label="Falta Injustificada"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <div class="flex-grow-1"></div>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="save">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-spacer />
        <v-btn color="teal" dark @click="exportar"
          >Exportar
          <v-icon right>import_export</v-icon>
        </v-btn>
        <v-spacer />
        <v-btn color="blue" dark @click="reset"
          >Limpiar Filtros
          <v-icon right>filter_list</v-icon>
        </v-btn>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        :options.sync="options"
        :sort-by="'name'"
        :loading="loading"
        :footer-props="{
          itemsPerPageOptions: rows_per_page_items, // -> Add this example
          itemsPerPageText: rows_per_page_text,
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'keyboard_arrow_left',
          nextIcon: 'keyboard_arrow_right',
        }"
        light
        item-key="id"
      >
        <v-progress-linear slot="progress" color="blue" indeterminate />
        <template v-slot:item.action="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)"> edit </v-icon>
          <v-icon small @click="deleteItem(item)"> delete </v-icon>
        </template>
      </v-data-table>
    </v-container>
  </v-app>
</template>
<script>
export default {
  data: () => ({
    urlPrefix: '',//"https://dev.checkinseguridad.com",
    dialog: false,
    editedItem: [],
    valid: false,
    empresas: [],
    empresa: null,
    tipoServicios: [],
    tipoServicio: null,
    headers: [
      { text: "ID", value: "id", align: "center" },
      { text: "Nombre", value: "nombre", align: "center" },
      { text: "Empresa", value: "empresa.nombre", align: "center" },
      {
        text: "Tipo de Servicio",
        value: "tipo_servicio.nombre",
        align: "center",
      },
      { text: "Jurisdicción", value: "jurisdiccion", align: "center" },
      { text: "Dirección", value: "direccion", align: "center" },
      { text: "Elementos", value: "elementos", align: "center" },
      { text: "Doblete x1", value: "doblete_1", align: "center" },
      { text: "Doblete x2", value: "doblete_2", align: "center" },
      { text: "Falta Justificada", value: "falta", align: "center" },
      {
        text: "Falta Injustificada",
        value: "falta_injustificada",
        align: "center",
      },
      { text: "Acciones", value: "action", align: "center", sortable: false },
    ],
    nombreRules: [(v) => !!v || "Nombre requerido"],
    empresaRules: [(v) => !!v || "Empresa requerida"],
    tipoServicioRules: [(v) => !!v || "Tipo de Servicio requerido"],
    elementosRules: [(v) => !!v || "Elementos requerido"],
    doblete1Rules: [(v) => !!v || "Doblete x1 requerido"],
    faltaRules: [(v) => !!v || "Falta Justificada requerida"],
    faltaInRules: [(v) => !!v || "Falta Injustificada requerida"],
    filters: [{ text: "Filtrar por nombre", value: "nombre" }],
    filterBy: {
      text: "Filtrar por nombre",
      value: "nombre",
    },
    orderBy: "ASC",
    orderColor: "teal",
    items: [],
    total_items: 0,
    options: {
      descending: false,
      rowsPerPage: 50,
      page: 1,
    },
    search: "",
    rows_per_page_items: [5, 10, 25, 50],
    rows_per_page_text: "Filas por pagina",
    meta: {
      current_page: 1,
      last_page: 1,
      per_page: 50,
    },
    page: 1,
    loading: false,
  }),
  watch: {
    page: {
      handler() {},
    },
  },
  computed: {
    sortIcon() {
      if (this.orderBy === "ASC") {
        return "keyboard_arrow_up";
      }
      return "keyboard_arrow_down";
    },
  },
  mounted() {
    let self = this;
    self.fetchEmpresas();
    self.fetchLugares();
    self.fetchTipoServicios();
  },
  methods: {
    getLugares: function name() {
      let self = this;
      self.loading = true;
      let url = `${this.urlPrefix}/api/lugares/byEmpresa/${this.empresa}/${this.tipoServicio}`;
      if (self.page > 1) {
        url = `${url}&page=${self.page}`;
      }
      this.$http
        .get(url)
        .then((response) => {
          self.lugaresBusqueda = response.data.data;
          self.meta = {
            current_page: response.data.current_page,
            last_page: response.data.last_page,
            per_page: response.data.per_page,
          };
          self.total_items = response.data.total;
          self.loading = false;
        })
        .catch((errors) => {
          console.log(errors);
          self.loading = false;
        });
      this.fetchLugares();
    },

    reset() {
      this.empresa = null;
      this.tipoServicio = null;
      this.search = null;
    },

    fetchTipoServicios() {
      let self = this;
      self.loading = true;
      let url = `${this.urlPrefix}/api/tipoServicio/all`;
      if (self.page > 1) {
        url = `${url}&page=${self.page}`;
      }
      this.$http
        .get(url)
        .then((response) => {
          this.tipoServicios = response.data.data;
          self.meta = {
            current_page: response.data.current_page,
            last_page: response.data.last_page,
            per_page: response.data.per_page,
          };
          self.total_items = response.data.total;
          self.loading = false;
        })
        .catch((errors) => {
          console.log(errors);
          alert(errors.response.data.message);
          self.loading = false;
        });
      this.fetchLugares();
    },

    exportar() {
      let url = `${this.urlPrefix}/api/personasCsv`;
      this.$axios
        .get(url)
        .then((response) => {
          let blob = new Blob([response.data], { type: "application/csv" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "competidores.csv";
          link.click();
        })
        .catch((errors) => {
          console.log(errors);
          self.loading = false;
        });
    },

    fetchEmpresas() {
      let self = this;
      self.loading = true;
      self.empresas = [];
      let url = `${this.urlPrefix}/api/empresas/all`;
      if (self.page > 1) {
        url = `${url}&page=${self.page}`;
      }
      this.$http
        .get(url)
        .then((response) => {
          self.empresas = response.data.data;
          self.meta = {
            current_page: response.data.current_page,
            last_page: response.data.last_page,
            per_page: response.data.per_page,
          };
          self.total_items = response.data.total;
          self.loading = false;
        })
        .catch((errors) => {
          console.log(errors);
          self.loading = false;
        });
    },

    save() {
      if (!this.$refs.form.validate()) {
        return;
      }
      let self = this;
      self.loading = true;
      //agregar
      if (this.editedItem.id == undefined) {
        this.agregarLugar();
      } else {
        //editar
        this.update = true;
        let url = `${this.urlPrefix}/api/lugares/${this.editedItem.id}`;
        this.$http
          .put(url, {
            empresa_id: this.editedItem.empresaId,
            jurisdiccion: this.editedItem.jurisdiccion,
            nombre: this.editedItem.nombre,
            direccion: this.editedItem.direccion,
            tipo_servicio_id: this.editedItem.tipoServicioId,
            elementos: this.editedItem.elementos,
            doblete_1: this.editedItem.doblete_1,
            doblete_2: this.editedItem.doblete_2,
            falta: this.editedItem.falta,
            falta_injustificada: this.editedItem.falta_injustificada,
          })
          .then(() => {
            this.fetchLugares();
            this.dialog = false;
            this.editedItem = [];
            this.date = "";
            self.loading = false;
          })
          .catch((errors) => {
            this.dialog = false;
            alert(errors.response.data.message);
            console.log(errors);
            this.editedItem = [];
            this.date = "";
            self.loading = false;
          });
        this.update = true;
      }
    },

    editItem(item) {
      this.editedItem = Object.assign({}, item);
      this.editedItem.empresaId = item.empresa_id;
      this.editedItem.tipoServicioId = item.tipo_servicio_id;
      this.dialog = true;
    },

    deleteItem(item) {
      let band = confirm(`Deseas borrar el lugar ${item.nombre}`);
      if (!band) {
        return false;
      }
      let self = this;
      self.loading = true;
      let url = `${this.urlPrefix}/api/lugares/${item.id}`;
      this.$http
        .delete(url)
        .then(() => {
          this.fetchLugares();
        })
        .catch((errors) => {
          console.log(errors);
        });
      self.loading = false;
    },

    agregarLugar() {
      let url = `${this.urlPrefix}/api/lugares`;
      let self = this;
      self.loading = true;
      this.$http
        .post(url, {
          empresa_id: this.editedItem.empresaId,
          jurisdiccion: this.editedItem.jurisdiccion,
          nombre: this.editedItem.nombre,
          direccion: this.editedItem.direccion,
          tipo_servicio_id: this.editedItem.tipoServicioId,
          elementos: this.editedItem.elementos,
          doblete_1: this.editedItem.doblete_1,
          doblete_2: this.editedItem.doblete_2,
          falta: this.editedItem.falta,
          falta_injustificada: this.editedItem.falta_injustificada,
        })
        .then(() => {
          this.fetchLugares();
          this.dialog = false;
        })
        .catch((errors) => {
          alert(errors.response.data.message);
          console.log(errors);
        });
      this.editedItem = [];
      self.loading = false;
    },

    toggleOrderBy() {
      if (this.orderBy === "ASC") {
        this.orderBy = "DESC";
        this.orderColor = "orange";
      } else {
        this.orderBy = "ASC";
        this.orderColor = "teal";
      }
    },

    close() {
      this.editedItem = [];
      this.date = new Date().toISOString().substr(0, 10);
      this.date2 = null;
      this.update = true;
      this.dialog = false;
    },

    fetchLugares() {
      let self = this;
      let url = "";
      self.loading = true;
      self.items = [];
      if (this.$store.getters.currentEmpresa !== null) {
        this.empresa = this.$store.getters.currentEmpresa;
      }
      url = `${this.urlPrefix}/api/lugares/byEmpresa/${this.empresa}/${this.tipoServicio}`;
      if (self.page > 1) {
        url = `${url}&page=${self.page}`;
      }
      this.$http
        .get(url)
        .then((response) => {
          self.items = response.data.data;
          self.meta = {
            current_page: response.data.current_page,
            last_page: response.data.last_page,
            per_page: response.data.per_page,
          };
          self.total_items = response.data.total;
          self.loading = false;
        })
        .catch((errors) => {
          console.log(errors);
          self.loading = false;
        });
    },

    toProperCase(key) {
      let newStr = key.replace(/_/g, " ");
      return newStr.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },

    changeSort(column) {
      if (this.options.sortBy === column) {
        this.options.descending = !this.options.descending;
      } else {
        this.options.sortBy = column;
        this.options.descending = false;
      }
    },

    asignarValoresDefault() {
      if (this.$store.getters.currentEmpresa != null) {
        this.editedItem.empresaId = this.$store.getters.currentEmpresa;
      }
    },
  },
};
</script>