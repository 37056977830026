<template>
  <v-app id="inspire">
      <v-container
        class="fill-height"
        fluid
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            sm="8"
            md="4"
          >
            <v-card class="elevation-12">
              <v-toolbar
                color="primary"
                dark
                flat
              >
                <v-toolbar-title>Registrarse</v-toolbar-title>
                <div class="flex-grow-1"></div>
              </v-toolbar>
              <v-card-text>
                <v-form  ref="form"  v-model="valid" id="check-login-form" lazy-validation>
                    <v-text-field  prepend-icon="person"  v-model="nombre" label="Nombre Completo" :rules="nombreRules"></v-text-field>
                  <v-text-field
                    label="Correo"
                    name="email"
                    v-model="email"
                    prepend-icon="email"
                    type="text"
                     required
                     :rules="emailRules"
                  ></v-text-field>
                   <v-text-field
                    id="password"
                    label="Contraseña"
                    name="password"
                     v-model="password"
                    prepend-icon="lock"
                    type="password"
                    required
                    :rules="passwordRules"
                  ></v-text-field>
                  <v-text-field
                    id="password_confirmation"
                    label="Confirmar Contraseña"
                    name="password_confirmation"
                     v-model="password_confirmation"
                    prepend-icon="lock"
                    type="password"
                    required
                    :rules="passwordRules"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions  class="justify-center">
                <v-btn
                  @click="register"
                  :loading="loading"
                  :disabled="loading"
                  color="primary">Registrarse</v-btn>
                <v-btn to="/">Cancelar</v-btn>
              </v-card-actions>
              <v-alert  
                dense
                outlined
                v-model=errorSesion
                :dismissible="true"
                type=error
                class="mb-4 outlined">
                {{error}}
              </v-alert>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
  </v-app>
</template>
<script>

  export default {
    data() {
      return {
        loading: false,
        nombre:null,
        email: null,
        password: null,
        password_confirmation:null,
        has_error: false,
         errorSesion:false,
         error:null,
        valid:false,
         emailRules: [
        v => !!v || 'Correo Requerido',
        v => /.+@.+\..+/.test(v) || 'Correo inválido',
      ],
      passwordRules: [
        v => !!v || 'Contraseña Requerida',
        v => (v && v.length >=4) || 'La contraseña tiene que tener al menos 4 caractéres',
      ],
       confirmationRules: [
        v => !!v || 'Confirmación Requerida',
        v => (v && v.length >=4) || 'La contraseña tiene que tener al menos 4 caractéres',
      ],
       nombreRules: [
        v => !!v || 'Nombre requerido',
      ],
      }
    },
    mounted() {
      //
    },
    methods: {
      register(){
         if (!this.$refs.form.validate()) {
          return;
        }
       let url = `/api/register`;
       let self = this;
       self.loading = true;
       console.log(this.editedItem);
        this.$http
        .post(url, {name:this.nombre, email: this.email, password:this.password,
                   password_confirmation: this.password_confirmation
        })
        .then(response => {
          console.log(response.data);
           alert('Registrado con éxito espera confirmación del admnistrador para poder usar la app');
           this.$router.push({ name: 'home', params:{}});
            //this.$router.push({ name: 'competenciasPorCompetidor', params: { id: response.data.id, nombre:response.data.nombre } });
        })
        .catch(errors => {
          alert(errors.response.data.message)
          console.log(errors.response);
        });
        self.loading=false;
    },
   }
  }
</script>