<template>
     <v-footer :fixed="false" color="#0353a4!important" class="white--text" :app="$vuetify.breakpoint.smAndUp">
                <v-row align="center" justify="center">
          <v-col align="left">
             <a href="/politica" style="color:white;  text-decoration: none;">Política de privacidad</a>
          </v-col>
          <v-col align="center">
                {{ siteName }} <br /> &copy; {{ currentYear }}
          </v-col>
          <v-col align="right">
          </v-col>
       </v-row>     
             
     </v-footer>
</template>
<script>  
  export default {
    data: () => ({
      size:true,
      siteName: "Checkin Admin",
      currentYear: new Date().getFullYear()
    }),
  }
</script>
