<template>
  <v-app>
    <v-container fluid>
      <v-card-title>
        <span class="headline">Empleados Activos</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="4"
            v-if="$store.getters.currentEmpresa == null"
          >
            <v-select
              :items="empresas"
              @change="getLugares('empresa')"
              name="empresa"
              append-icon="search"
              v-model="empresa"
              item-text="nombre"
              item-value="id"
              label="Empresa"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-select
              :items="tipoServicios"
              @change="getLugares('tipoServicio')"
              name="tipoServicio"
              append-icon="search"
              v-model="tipoServicio"
              item-text="nombre"
              item-value="id"
              label="Tipo de Servicio"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-autocomplete
              :items="lugaresBusqueda"
              name="lugar"
              append-icon="search"
              @change="fetchEmpleados"
              v-model="lugar"
              item-text="nombre"
              item-value="id"
              label="Lugar"
            ></v-autocomplete>
          </v-col>
          <v-col cols="6" sm="6" md="6">
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Filtrar/Buscar Empleados"
              single-line
              hide-details
            />
          </v-col>
          <v-col cols="2" sm="2" md="2">
            <v-btn color="blue" dark @click="reset"
              >Nueva búsqueda
              <v-icon right>filter_list</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="2" sm="2" md="2">
            <v-btn color="teal" dark @click="exportar"
              >Exportar
              <v-icon right>import_export</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="2" sm="2" md="2">
            <v-btn text to="/NewEmployee"
              ><v-icon>mdi-account-plus</v-icon> Agregar Empleado</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
      <v-card>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="items"
            :search="search"
            :options.sync="options"
            :sort-by="'name'"
            :loading="loading"
            :footer-props="{
              itemsPerPageOptions: rows_per_page_items, // -> Add this example
              itemsPerPageText: rows_per_page_text,
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'keyboard_arrow_left',
              nextIcon: 'keyboard_arrow_right',
            }"
            light
            item-key="id"
          >
            <v-progress-linear slot="progress" color="blue" indeterminate />
            <template v-slot:item.action="{ item }">
              <router-link :to="{ path: '/EditEmployee/' + item.id }">
                <v-icon small class="mr-2">mdi-pencil</v-icon></router-link
              >
              <v-icon small @click="deleteItem(item)"> delete </v-icon>
            </template>
            <template v-slot:item.comp="{ item }">
              <v-icon small class="mr-2" @click="agregarPagos(item)">
                edit
              </v-icon>
            </template>
            <template v-slot:item.seguro="{ item }">
              <v-icon small class="mr-2" @click="seguro(item)"> edit </v-icon>
            </template>
            <template v-slot:item.asistencias="{ item }">
              <v-icon small class="mr-2" @click="verAsistencias(item)">
                edit
              </v-icon>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </v-app>
</template>
<script>
export default {
  data: () => ({
    //urlPrefix: "",
    urlPrefix: "https://dev.checkinseguridad.com",
    dialog: false,
    valid: false,
    tarjetas: false,
    bancos: [],
    tipoServicios: [],
    tipoServicio: null,
    editedItem: [],
    domicilio: false,
    infonavit: false,
    status: ["Activo", "Inactivo"],
    turno: [],
    nacionalidad: ["MEXICANA", "EXTRANJERO"],
    sexo: ["Masculino", "Femenino"],
    empresasAgregar: [],
    empresa: null,
    lugares: [],
    tipoFechas: ["fecha_alta", "fecha_baja"],
    tipoFecha: null,
    lugaresBusqueda: [],
    lugar: null,
    modal: false,
    modal2: false,
    modal3: false,
    modal4: false,
    date: new Date().toISOString().substr(0, 10),
    date2: null,
    fechaInicio: null,
    fechaFin: null,
    empresas: [],
    puestos: [],
    lugarSelected: {},
    puestoSelected: {},
    headers: [
      { text: "ID", value: "id", align: "center" },
      { text: "Nombre", value: "empleado_id", align: "center" },
      { text: "Turno", value: "turno", align: "center" },
      { text: "CURP", value: "CURP", align: "center" },
      { text: "fecha de ingreso", value: "fecha_alta", align: "center" },
      //{ text: "RFC", value: "RFC", align: "center"},
      { text: "Fecha de Baja", value: "fecha_baja", align: "center" },
      { text: "Status", value: "status", align: "center" },
      { text: "Lugar de trabajo", value: "lugar.nombre", align: "center" },
      { text: "Empresa", value: "lugar.empresa.nombre", align: "center" },
      { text: "Puesto", value: "puesto.cargo", align: "center" },
      //{ text: "Seguro", value: "seguro", align: "center", sortable: false },
      //{ text: "Pagos", value: "comp", align: "center", sortable: false },
      //{ text: "Record", value: "asistencias", align: "center", sortable: false },
      { text: "Acciones", value: "action", align: "center", sortable: false },
    ],
    IdRules: [(v) => !!v || "ID requerido"],
    nombreRules: [(v) => !!v || "Nombre requerido"],
    paternoRules: [(v) => !!v || "Apellido paterno requerido"],
    sueldoRules: [(v) => !!v || "Sueldo prometido requerido"],
    maternoRules: [(v) => !!v || "Apellido Materno requerido"],
    pensionadoRules: [(v) => !!v || "Estatus de pensión requerido"],
    curpRules: [
      (v) => !!v || "CURP requerido",
      (v) => (v && v.length == 18) || "La CURP es de 18 carácteres",
    ],
    rfcRules: [
      (v) => !!v || "RFC requerido",
      (v) =>
        (v && v.length >= 12 && v.length <= 13) ||
        "El RFC es de 12 o 13 carácteres",
    ],
    telefonoRules: [(v) => !!v || "Teléfono requerido"],
    statusRules: [(v) => !!v || "Status requerido"],
    NSSRules: [(v) => !!v || "NSS requerido"],
    turnoRules: [(v) => !!v || "Turno requerido"],
    nacionalidadRules: [(v) => !!v || "Nacionalidad requerida"],
    sueldoDiarioRules: [(v) => !!v || "Sueldo Diario requerido requerida"],
    empresaRules: [(v) => !!v || "Empresa requerida"],
    lugarRules: [(v) => !!v || "Lugar requerido"],
    puestoRules: [(v) => !!v || "Puesto requerido"],
    fechaAltaRules: [(v) => !!v || "Fecha Alta requerida"],
    sexoRules: [(v) => !!v || "Sexo requerido"],
    tipoServicioRules: [(v) => !!v || "Tipo de Servicio requerido"],
    descansoRules: [(v) => !!v || "Descanso requerido"],
    menorRules: [(v) => !!v || "Si es menor requerido"],
    incapacidadRules: [(v) => !!v || "Incapacidad requerida"],
    vacacionesRules: [(v) => !!v || "Vacaciones requerido"],
    filters: [{ text: "Filtrar por nombre", value: "nombre" }],
    filterBy: {
      text: "Filtrar por nombre",
      value: "nombre",
    },
    orderBy: "ASC",
    orderColor: "teal",
    items: [],
    total_items: 0,
    options: {
      descending: false,
      rowsPerPage: 50,
      page: 1,
    },
    search: "",
    rows_per_page_items: [5, 10, 25, 50],
    rows_per_page_text: "Filas por pagina",
    meta: {
      current_page: 1,
      last_page: 1,
      per_page: 50,
    },
    page: 1,
    loading: false,
  }),
  watch: {
    page: {
      handler() {
        this.fetchUsers();
      },
    },
  },
  computed: {
    sortIcon() {
      if (this.orderBy === "ASC") {
        return "keyboard_arrow_up";
      }
      return "keyboard_arrow_down";
    },
  },
  mounted() {
    let self = this;

    self.fetchEmpresas();
    //self.fetchLugares();
    self.fetchTipoServicios();

    // self.fetchPuestos();
    // self.fetchBancos();
    //self.fetchEmpleados();
  },
  methods: {
    fetchTipoServicios() {
      let self = this;
      self.loading = true;
      let url = `${this.urlPrefix}/api/tipoServicio/all`;
      if (self.page > 1) {
        url = `${url}&page=${self.page}`;
      }
      this.$axios
        .get(url)
        .then((response) => {
          this.tipoServicios = response.data.data;

          self.meta = {
            current_page: response.data.current_page,
            last_page: response.data.last_page,
            per_page: response.data.per_page,
          };
          (self.total_items = response.data.total), (self.loading = false);
        })
        .catch((errors) => {
          alert(errors.response.data.message);
          self.loading = false;
        });
    },

    asignarActivo() {
      this.editedItem.status = "Activo";
      if (this.$store.getters.currentEmpresa != null) {
        this.editedItem.empresaId = this.$store.getters.currentEmpresa;
        this.asignarTurno();
      }
    },

    reset() {
      this.empresa = null;
      this.tipoServicio = null;
      this.lugar = null;
      this.lugaresBusqueda = [];
      this.search = null;
      this.items = [];

      //this.cerrarFechaInicio();
      //this.cerrarFechaFin();
      //this.tipoFecha = null;
      //this.fetchEmpleados();
    },

    getLugares: function name(event) {
      let self = this;
      this.lugar = null;
      self.loading = true;
      let url = `${this.urlPrefix}/api/lugares/byEmpresa/${this.empresa}/${this.tipoServicio}`;
      if (self.page > 1) {
        url = `${url}&page=${self.page}`;
      }
      this.$http
        .get(url)
        .then((response) => {
          self.lugaresBusqueda = response.data.data;
          self.meta = {
            current_page: response.data.current_page,
            last_page: response.data.last_page,
            per_page: response.data.per_page,
          };
          self.total_items = response.data.total;
          self.loading = false;
        })
        .catch((errors) => {
          console.log(errors);
          self.loading = false;
        });
      this.fetchEmpleados();
      if (event == "tipoServicio") {
        this.items = [];
        this.lugar = null;
      }
    },

    getLugaresParaAgregar: function name() {
      let self = this;
      let url = `${this.urlPrefix}/api/lugares/byEmpresa/${this.editedItem.empresaId}/${this.editedItem.tipoServicioId}`;
      if (self.page > 1) {
        url = `${url}&page=${self.page}`;
      }
      this.$http
        .get(url)
        .then((response) => {
          self.lugares = response.data.data;

          self.meta = {
            current_page: response.data.current_page,
            last_page: response.data.last_page,
            per_page: response.data.per_page,
          };
          self.total_items = response.data.total;
          self.loading = false;
        })
        .catch((errors) => {
          console.log(errors);
        });
      this.asignarTurno();
    },

    fetchLugaresPorEmpresa() {
      let self = this;
      self.loading = true;

      let url = `${this.urlPrefix}/api/lugares/byEmpresa/${this.editedItem.empresaId}/`;
      if (self.page > 1) {
        url = `${url}&page=${self.page}`;
      }
      this.$axios
        .get(url)
        .then((response) => {
          self.lugares = response.data.data;

          self.meta = {
            current_page: response.data.current_page,
            last_page: response.data.last_page,
            per_page: response.data.per_page,
          };
          self.total_items = response.data.total;
          self.loading = false;
        })
        .catch((errors) => {
          console.log(errors);
          self.loading = false;
        });
    },

    saveFechaInicio() {
      this.$refs.dialog3.save(this.fechaInicio);
      this.fetchEmpleados();
    },

    saveFechaFin() {
      this.$refs.dialog4.save(this.fechaFin);
      this.fetchEmpleados();
    },

    cerrarFecha() {
      this.date = null;
      this.$refs.dialog.save(this.date);
      this.modal = false;
    },

    cerrarFecha2() {
      this.date2 = null;
      this.editedItem.status = "Activo";
      this.$refs.dialog2.save(this.date2);
      this.modal2 = false;
    },

    cerrarFechaInicio() {
      this.fechaInicio = null;
      this.$refs.dialog3.save(this.fechaInicio);
      this.modal3 = false;
    },

    cerrarFechaFin() {
      this.fechaFin = null;
      this.$refs.dialog4.save(this.fechaFin);
      this.modal4 = false;
    },

    asignarDate() {
      this.date2 = new Date().toISOString().substr(0, 10);
    },

    exportar() {
      const empresaId = this.empresa !== null ? this.empresa : null;
      const servicioId = this.tipoServicio !== null ? this.tipoServicio : null;
      const lugarId = this.lugar !== null ? this.lugar : null;
      const url = `${this.urlPrefix}/api/empleados/exportar/${empresaId}/${servicioId}/${lugarId}`;
      this.$axios
        .get(url)
        .then((response) => {
          const blob = new Blob([response.data], {
            type: "text/csv;charset=utf-8;",
          });
          const link = document.createElement("a");
          if (link.download !== undefined) {
            // feature detection
            // Browsers that support HTML5 download attribute
            const url1 = URL.createObjectURL(blob);
            link.setAttribute("href", url1);
            link.setAttribute("download", "empleados.csv");
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        })
        .catch((errors) => {
          console.log(errors);
          self.loading = false;
        });
    },

    formatFecha(fecha) {
      let str = fecha;
      let darr = str.split("-"); // ["29", "1", "2016"]
      var dobj = new Date(
        parseInt(darr[0]),
        parseInt(darr[1]) - 1,
        parseInt(darr[2])
      );
      return dobj.toISOString().substr(0, 10);
    },

    save() {
      if (!this.$refs.form.validate()) {
        return;
      }
      let self = this;
      self.loading = true;
      if (this.editedItem.id == undefined) {
        this.agregarEmpleado();
      } else {
        let url = `${this.urlPrefix}/api/empleados/${this.editedItem.id}`;
        this.$axios
          .put(url, {
            nombre: this.editedItem.nombre,
            CURP: this.editedItem.CURP,
            RFC: this.editedItem.RFC,
            fecha_alta: this.date,
            fecha_baja: this.date2,
            apellido_paterno: this.editedItem.apellido_paterno,
            apellido_materno: this.editedItem.apellido_materno,
            status: this.editedItem.status,
            lugar_id: this.editedItem.lugarId,
            puesto_id: this.editedItem.puestoId,
            sexo: this.editedItem.sexo,
            calle: this.editedItem.calle,
            numero: this.editedItem.numero,
            colonia: this.editedItem.colonia,
            municipio: this.editedItem.municipio,
            estado: this.editedItem.estado,
            cp: this.editedItem.cp,
            turno: this.editedItem.turno,
            nacionalidad: this.editedItem.nacionalidad,
            tel: this.editedItem.tel,
            no_credito: this.editedItem.no_credito,
            descuento_quincenal: this.editedItem.descuento_quincenal,
            monto_descuento: this.editedItem.monto_descuento,
            cuota_fija: this.editedItem.cuota_fija,
            descanso: this.editedItem.descanso,
            menor_edad: this.editedItem.menor_edad,
            incapacidad: this.editedItem.incapacidad,
            vacaciones: this.editedItem.vacaciones,
            pensionado: this.editedItem.pensionado,
            NSS: this.editedItem.NSS,
            sueldo_diario: this.editedItem.sueldo_diario,
            sueldo_prometido: this.editedItem.sueldo_prometido,
            banco_id: this.editedItem.bancoId,
            no_cuenta: this.editedItem.no_cuenta,
            SDI: this.editedItem.SDI,
            no_tarjeta: this.editedItem.no_tarjeta,
            clabe: this.editedItem.clabe,
          })
          .then(() => {
            this.fetchEmpleados();
            this.dialog = false;
            this.editedItem = [];
            this.date = "";
          })
          .catch((errors) => {
            alert(JSON.stringify(errors.response.data.data));
            console.log(errors);
            this.dialog = false;
          });
        self.loading = false;
      }
    },

    editItem(item) {
      this.editedItem = Object.assign({}, item);
      this.editedItem.pensionado = `${item.pensionado}`;
      this.editedItem.NSS = item.seguro.NSS;
      this.editedItem.clabe = item.pago.clabe;
      this.editedItem.no_tarjeta = item.pago.no_tarjeta;
      if (this.editedItem.fecha_alta == undefined) {
        this.date = "";
      } else {
        this.date = this.formatFecha(this.editedItem.fecha_alta);
      }
      if (this.editedItem.fecha_baja == undefined) {
        this.date2 = "";
      } else {
        this.date2 = this.formatFecha(this.editedItem.fecha_baja);
      }

      this.editedItem.lugarNombre = this.editedItem.lugar.nombre;
      this.editedItem.sueldo_diario = this.editedItem.pago.sueldo_diario;
      this.editedItem.sueldo_prometido = this.editedItem.pago.sueldo_prometido;
      this.editedItem.tipoServicioId = this.editedItem.lugar.tipo_servicio_id;
      this.editedItem.bancoId = this.editedItem.pago.banco_id;
      this.editedItem.no_cuenta = this.editedItem.pago.no_cuenta;
      this.editedItem.lugarId = this.editedItem.lugar.id;
      this.editedItem.puestoNombre = this.editedItem.puesto.cargo;
      this.editedItem.empresaId = this.editedItem.lugar.empresa.id;
      this.editedItem.puestoId = this.editedItem.puesto.id;
      this.asignarTurno();
      this.fetchLugares();
      this.dialog = true;
    },

    deleteItem(item) {
      let band = confirm(`Deseas borrar el empleado ${item.nombre}`);
      if (!band) {
        return false;
      }
      let self = this;
      self.loading = true;
      let url = `${this.urlPrefix}/api/empleados/${item.id}`;
      this.$axios
        .delete(url)
        .then(() => {
          this.fetchEmpleados();
        })
        .catch((errors) => {
          console.log(errors);
        });
      self.loading = false;
    },

    fetchLugares() {
      let url = `${this.urlPrefix}/api/lugares/all`;
      this.$axios
        .get(url)
        .then((response) => {
          this.lugares = [];
          this.lugares = response.data.data;
          this.lugarSelected.selected_id = this.editedItem.lugarId;
          response.data.data.forEach((element) => {
            this.lugares.push({ nombre: element.nombre, id: element.id });
          });
        })
        .catch((errors) => {
          console.log(errors);
          self.loading = false;
        });
    },

    fetchPuestos() {
      let url = `${this.urlPrefix}/api/puestos/all`;
      this.$axios
        .get(url)
        .then((response) => {
          this.puestos = [];
          this.puestos = response.data.data;
          this.puestoSelected.selected_id = this.editedItem.puestoId;
          response.data.data.forEach((element) => {
            this.puestos.push({ nombre: element.nombre, id: element.id });
          });
        })
        .catch((errors) => {
          console.log(errors);
          self.loading = false;
        });
    },

    agregarEmpleado() {
      let url = `${this.urlPrefix}/api/empleados`;
      let self = this;
      self.loading = true;
      this.$axios
        .post(url, {
          nombre: this.editedItem.nombre,
          CURP: this.editedItem.CURP,
          apellido_paterno: this.editedItem.apellido_paterno,
          apellido_materno: this.editedItem.apellido_materno,
          RFC: this.editedItem.RFC,
          fecha_alta: this.date,
          fecha_baja: this.date2,
          status: this.editedItem.status,
          lugar_id: this.editedItem.lugarId,
          puesto_id: this.editedItem.puestoId,
          sexo: this.editedItem.sexo,
          calle: this.editedItem.calle,
          numero: this.editedItem.numero,
          colonia: this.editedItem.colonia,
          municipio: this.editedItem.municipio,
          estado: this.editedItem.estado,
          cp: this.editedItem.cp,
          turno: this.editedItem.turno,
          nacionalidad: this.editedItem.nacionalidad,
          tel: this.editedItem.tel,
          no_credito: this.editedItem.no_credito,
          descuento_quincenal: this.editedItem.descuento_quincenal,
          monto_descuento: this.editedItem.monto_descuento,
          cuota_fija: this.editedItem.cuota_fija,
          descanso: this.editedItem.descanso,
          menor_edad: this.editedItem.menor_edad,
          incapacidad: this.editedItem.incapacidad,
          vacaciones: this.editedItem.vacaciones,
          pensionado: this.editedItem.pensionado,
          NSS: this.editedItem.NSS,
          sueldo_diario: this.editedItem.sueldo_diario,
          sueldo_prometido: this.editedItem.sueldo_prometido,
          banco_id: this.editedItem.bancoId,
          no_cuenta: this.editedItem.no_cuenta,
          SDI: this.editedItem.SDI,
          no_tarjeta: this.editedItem.no_tarjeta,
          clabe: this.editedItem.clabe,
        })
        .then(() => {
          this.fetchEmpleados();
          this.dialog = false;
          this.editedItem = [];
        })
        .catch((errors) => {
          alert(JSON.stringify(errors.response.data.data));
        });

      self.loading = false;
    },

    toggleOrderBy() {
      if (this.orderBy === "ASC") {
        this.orderBy = "DESC";
        this.orderColor = "orange";
      } else {
        this.orderBy = "ASC";
        this.orderColor = "teal";
      }
    },

    close() {
      this.editedItem = [];
      this.date = new Date().toISOString().substr(0, 10);
      this.date2 = null;
      this.dialog = false;
    },

    fetchEmpresas() {
      let self = this;
      self.loading = true;
      self.items = [];
      let url = `${this.urlPrefix}/api/empresas/all`;
      if (self.page > 1) {
        url = `${url}&page=${self.page}`;
      }
      this.$axios
        .get(url)
        .then((response) => {
          self.empresas = response.data.data;
          self.empresasAgregar = response.data.data;

          self.meta = {
            current_page: response.data.current_page,
            last_page: response.data.last_page,
            per_page: response.data.per_page,
          };
          (self.total_items = response.data.total), (self.loading = false);
        })
        .catch((errors) => {
          console.log(errors);
          self.loading = false;
        });
    },

    fetchEmpleados() {
      //   if (this.$store.getters.currentEmpresa !== null) {
      //     this.empresa = this.$store.getters.currentEmpresa;
      //   }

      //   let url = "/api/empleados/all";
      //   if (
      //     this.fechaInicio == null &&
      //     this.fechaFin == null &&
      //     this.empresa == null &&
      //     this.tipoServicio == null
      //   ) {
      //     url = "/api/empleados/all";
      //   } else {
      //     if (
      //       this.fechaInicio != null &&
      //       this.fechaFin == null &&
      //       this.empresa == null &&
      //       this.lugar == null
      //     ) {
      //       url = `/api/empleados/byFechaInicio/${this.fechaInicio}/${this.tipoFecha}`;
      //     } else {
      //       if (
      //         this.fechaInicio == null &&
      //         this.fechaFin != null &&
      //         this.empresa == null &&
      //         this.lugar == null
      //       ) {
      //         url = `/api/empleados/byFechaFin/${this.fechaFin}/${this.tipoFecha}`;
      //       } else {
      //         if (
      //           this.fechaInicio != null &&
      //           this.fechaFin != null &&
      //           this.empresa == null &&
      //           this.lugar == null
      //         ) {
      //           url = `/api/empleados/byFecha/${this.fechaInicio}/${this.fechaFin}/${this.tipoFecha}`;
      //         } else {
      //           if (this.empresa != null && this.lugar == null) {
      //             url = `/api/empleados/byEmpresa/${this.empresa}/${this.fechaInicio}/${this.fechaFin}/${this.tipoFecha}`;
      //           } else {
      //             if (
      //               (this.empresa != null || this.tipoServicio != null) &&
      //               this.lugar != null
      //             ) {
      //               url = `/api/empleados/byLugar/${this.lugar}/${this.fechaInicio}/${this.fechaFin}/${this.tipoFecha}`;
      //             }
      //           }
      //         }
      //       }
      //     }
      //   }
      const empresaId = this.empresa !== null ? this.empresa : null;
      const servicioId = this.tipoServicio !== null ? this.tipoServicio : null;
      const lugarId = this.lugar !== null ? this.lugar : null;
      let url = `${this.urlPrefix}/api/empleados/${empresaId}/${servicioId}/${lugarId}`;
      let self = this;
      self.loading = true;
      self.items = [];
      if (self.page > 1) {
        url = `${url}&page=${self.page}`;
      }
      //V1
      this.$axios
        .get(url)
        .then((response) => {
          self.loading = false;
          self.items = response.data.data;

          self.meta = {
            current_page: response.data.current_page,
            last_page: response.data.last_page,
            per_page: response.data.per_page,
          };
          self.total_items = response.data.total;
          self.loading = false;
        })
        .catch((errors) => {
          console.log(errors);
          self.loading = false;
        });
    },

    toProperCase(key) {
      let newStr = key.replace(/_/g, " ");
      return newStr.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },

    changeSort(column) {
      if (this.options.sortBy === column) {
        this.options.descending = !this.options.descending;
      } else {
        this.options.sortBy = column;
        this.options.descending = false;
      }
    },

    agregarPagos(item) {
      this.$router.push({
        name: "pagos",
        params: {
          pago_id: item.pago_id,
          id: item.id,
          nombre: item.nombre,
          apellido_paterno: item.apellido_paterno,
          apellido_materno: item.apellido_materno,
          lugar_id: item.lugar_id,
          CURP: item.CURP,
          seguro_id: item.seguro_id,
          RFC: item.RFC,
          fecha_alta: item.fecha_alta,
          status: item.status,
          lugarId: item.lugar_id,
          puestoId: item.puesto_id,
        },
      });
    },

    verAsistencias(item) {
      this.$router.push({
        name: "record",
        params: { id: item.id, nombre: item.nombre },
      });
    },

    seguro(item) {
      this.$router.push({
        name: "seguro",
        params: {
          pago_id: item.pago_id,
          id: item.id,
          nombre: item.nombre,
          apellido_paterno: item.apellido_paterno,
          apellido_materno: item.apellido_materno,
          lugar_id: item.lugar_id,
          CURP: item.CURP,
          seguro_id: item.seguro_id,
          RFC: item.RFC,
          fecha_alta: item.fecha_alta,
          status: item.status,
          lugarId: item.lugar_id,
          puestoId: item.puesto_id,
        },
      });
    },

    asignarTurno() {
      console.log(this.editedItem.empresaId);
      if (this.editedItem.empresaId == 2 || this.editedItem.empresaId == 3) {
        this.turno = ["12x12", "24x24", "NORMAL", "MEDIO TIEMPO"];
        return;
      }
      if (this.editedItem.empresaId == 1) {
        this.turno = [
          "MATUTINO",
          "VESPERTINO",
          "NOCTURNO",
          "TURNO NORMAL",
          "MEDIO TIEMPO",
        ];
      }
    },

    asignarFechaBaja() {
      this.$refs.dialog2.save(this.date2);
      this.editedItem.status = "Inactivo";
    },

    asignarFechaAlta() {
      let fecha = this.editedItem.fecha_alta;
      this.$refs.dialog.save(this.date);
      if (this.date !== fecha) {
        this.editedItem.status = "Activo";
      }
    },

    fetchBancos() {
      let url = `${this.urlPrefix}/api/bancos/all`;
      this.$axios
        .get(url)
        .then((response) => {
          this.bancos = [];
          this.bancos = response.data.data;
        })
        .catch((errors) => {
          console.log(errors);
          self.loading = false;
        });
    },
  },
};
</script>
